import { connect } from 'react-redux';
import { RootState } from '../../../../../../../redux/store';
import {
  setAutoLogin,
  setWebRDPAdapter,
  setSSHAdapter,
  virtualMachinesSelectors,
} from '../../../../../../../redux/configuration/virtual-machines/slice';
import { RemoteAccessForm } from '.';

const mapStateToProps = (state: RootState) => ({
  validAdapters: virtualMachinesSelectors.getValidAdapters(state),
});

const mapDispatchToProps = {
  setAutoLogin,
  setSSHAdapter,
  setWebRDPAdapter,
};

export const RemoteAccessFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RemoteAccessForm);
