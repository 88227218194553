import { useTranslation } from 'react-i18next';
import { useErrorToast } from '../../../../../../hooks/toasts/use-error-toast';
import { useGetVMTemplatesInventoryQuery } from '../../../../../../redux/configuration/virtual-machines/api';

interface UseFetchInventoryTemplatesVMs {
  inventoryVirtualMachinesTemplates: InventoryVirtualMachine[];
  isFetching: boolean;
}

export function useFetchInventoryTemplatesVMs(
  topologyUid: string,
): UseFetchInventoryTemplatesVMs {
  const { t } = useTranslation();
  const { showErrorToast } = useErrorToast();

  const {
    data: inventoryVirtualMachinesTemplates = [],
    isFetching,
    isError,
  } = useGetVMTemplatesInventoryQuery(topologyUid);

  isError && showErrorToast(t('inventoryVirtualMachines.fetch.errors.many'));

  return {
    inventoryVirtualMachinesTemplates,
    isFetching,
  };
}
