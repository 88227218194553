import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { getVirtualMachines } from '../../../../../../../redux/configuration/virtual-machines/slice';
import { RootState } from '../../../../../../../redux/store';
import { getOperatingSystems } from '../../../../../../../redux/entities/operating-systems/slice';
import { useUpdateVmMutation } from '../../../../../../../redux/configuration/virtual-machines/api';
import { Form } from '.';

interface FormContainerProps {
  networkingToEdit?: VmNetworkingToEdit;
  operatingSystems?: OperatingSystem[];
  rdpEnabledNic?: VMNetworkInterface;
  sshEnabledNic?: VMNetworkInterface;
  vmNames: string[];
  vmToEdit: VirtualMachine;
}

export function FormContainer({
  networkingToEdit,
  operatingSystems = [],
  rdpEnabledNic,
  sshEnabledNic,
  vmNames,
  vmToEdit,
}: FormContainerProps): ReactElement {
  const [updateVM, { isLoading: isUpdating }] = useUpdateVmMutation();

  return (
    <Form
      isUpdating={isUpdating}
      vmToEdit={vmToEdit}
      updateVM={updateVM}
      vmNetworkingToEdit={networkingToEdit}
      operatingSystems={operatingSystems}
      rdpEnabledNic={rdpEnabledNic}
      sshEnabledNic={sshEnabledNic}
      vmNames={vmNames}
    />
  );
}

const mapStateToProps = (state: RootState) => ({
  networkingToEdit: getVirtualMachines(state).networkingToEdit,
  operatingSystems: getOperatingSystems(state).operatingSystems,
});

export const ConnectedVirtualMachinesEditForm = connect(mapStateToProps)(
  FormContainer,
);
