import React, { ReactElement, useEffect } from 'react';
import { useParams } from 'react-router';
import { SelectOption } from 'dcloud-shared-ui';
import { connect } from 'react-redux';
import {
  useCreateInboundProxyRuleMutation,
  useGetInboundProxyRulesQuery,
} from '../../../../../redux/configuration/traffic/inbound-proxy-rules/api';
import { useGetVmsAxiosQuery } from '../../../../../redux/configuration/virtual-machines/api';
import {
  getInboundProxyRules,
  setSelectedVM,
  setIpAddressOptionsByVm,
  inboundProxyRulesSelectors,
  setInboundProxyRuleToDelete,
} from '../../../../../redux/configuration/traffic/inbound-proxy-rules/slice';
import { RootState } from '../../../../../redux/store';
import { buildTargetOptions, buildIPAddressOptionsByVM } from './utils';
import { InboundProxyRulesLoader } from './components/InboundProxyRulesLoader';
import { InboundProxyRules } from '.';

export function InboundProxyRulesContainer({
  inboundProxyRuleToDelete,
  ipAddressOptions,
  ipAddressOptionsByVM,
  setInboundProxyRuleToDelete,
  setIpAddressOptionsByVm,
  setSelectedVM,
}: InboundProxyRulesContainerProps): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();

  const {
    data: inboundProxyRules,
    isError: isErrorFetchingInboundProxyRules,
    isLoading: isLoadingInboundProxyRules,
  } = useGetInboundProxyRulesQuery(topologyUid);

  const [
    createInboundProxyRule,
    { isLoading: isCreating },
  ] = useCreateInboundProxyRuleMutation();

  const {
    data: vms,
    isError: isErrorFetchingVms,
    isLoading: isLoadingVms,
    isFetching: isFetchingVms,
  } = useGetVmsAxiosQuery(topologyUid, {
    refetchOnFocus: false,
  });

  useEffect(() => {
    if (vms && !isFetchingVms && !ipAddressOptionsByVM) {
      const IPAddressOptionsByVM = buildIPAddressOptionsByVM(vms);
      setIpAddressOptionsByVm(IPAddressOptionsByVM);
    }
  }, [ipAddressOptionsByVM, isFetchingVms, setIpAddressOptionsByVm, vms]);

  useEffect(() => {
    return () => {
      setIpAddressOptionsByVm(undefined);
      setSelectedVM(undefined);
    };
  }, [setIpAddressOptionsByVm, setSelectedVM]);

  let targetOptions: SelectOption<string>[] = [];
  if (ipAddressOptionsByVM && vms) {
    targetOptions = buildTargetOptions(ipAddressOptionsByVM, vms);
  }

  if (
    isLoadingInboundProxyRules ||
    isLoadingVms ||
    !targetOptions ||
    !ipAddressOptions
  ) {
    return <InboundProxyRulesLoader />;
  }

  return (
    <InboundProxyRules
      inboundProxyRules={inboundProxyRules?.inboundProxyRules}
      inboundProxyRuleToDelete={inboundProxyRuleToDelete}
      isError={isErrorFetchingInboundProxyRules || isErrorFetchingVms}
      isLoading={false}
      targetOptions={targetOptions}
      setSelectedVM={setSelectedVM}
      setInboundProxyRuleToDelete={setInboundProxyRuleToDelete}
      ipAddressOptions={ipAddressOptions}
      createInboundProxyRule={createInboundProxyRule}
      isCreating={isCreating}
    />
  );
}

const mapStateToProps = (state: RootState) => ({
  inboundProxyRuleToDelete: getInboundProxyRules(state)
    .inboundProxyRuleToDelete,
  ipAddressOptions: inboundProxyRulesSelectors.getIpAddressOptionsForVM(state),
  ipAddressOptionsByVM: getInboundProxyRules(state).ipAddressOptionsByVM,
});

const mapDispatchToProps = {
  setInboundProxyRuleToDelete,
  setIpAddressOptionsByVm,
  setSelectedVM,
};

interface InboundProxyRulesRTKProps {
  inboundProxyRules?: InboundProxyRule[];
  isError: boolean;
  isLoading: boolean;
  targetOptions: SelectOption<string>[];
}

export type InboundProxyRulesContainerProps = ReturnType<
  typeof mapStateToProps
> &
  typeof mapDispatchToProps;

export type InboundProxyRulesProps = InboundProxyRulesRTKProps &
  Omit<
    InboundProxyRulesContainerProps,
    'setIpAddressOptionsByVm' | 'ipAddressOptionsByVM'
  > & {
    createInboundProxyRule: RTKMutation<
      InboundProxyRulePostPayload,
      InboundProxyRule
    >;
    isCreating: boolean;
  };

export const ConnectedInboundProxyRules = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InboundProxyRulesContainer);
