import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { stripGzipFromEtag } from '../api/utils';
import { getEtag } from './configuration/etag/selectors';
import { setEtag } from './configuration/etag/slice';
import { RootState } from './store';
import { addErrorToast, addSuccessToast } from './toast/slice';

export const createHeaders = (
  method: AxiosRequestConfig['method'],
  api: BaseQueryApi,
): AxiosRequestConfig['headers'] => {
  if (method === 'put' || method === 'PUT') {
    return { 'If-Match': getEtag(api.getState() as RootState) };
  }
};

export const stripAndsetEtag = (
  result: AxiosResponse<unknown>,
  api: BaseQueryApi,
): void => {
  if (result.headers['etag']) {
    api.dispatch(setEtag(stripGzipFromEtag(result.headers['etag'])));
  }
};

export const dispatchSuccessToast = (
  successMessage: string,
  api: BaseQueryApi,
): void => {
  api.dispatch(addSuccessToast({ message: successMessage }));
};

export const dispatchErrorToast = (
  error: AxiosError,
  api: BaseQueryApi,
  errorMessage?: string,
): void => {
  const errorToastMessage = error.response?.data[0].message || errorMessage;
  if (errorToastMessage) {
    api.dispatch(addErrorToast({ message: errorToastMessage }));
  }
};
