import { DNS_ASSETS, TOPOLOGIES_PATH, VM_TARGETS } from '../../../api/paths';
import i18n from '../../../i18n';
import { dcloudApiAxios, TAGS } from '../../api';

export const trafficApi = dcloudApiAxios.injectEndpoints({
  endpoints: (build) => ({
    getInventoryDnsAssets: build.query<InventoryDnsAssetResponse, string>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.DNS_ASSETS],
      query: (topologyUid) => ({
        errorMessage: i18n.t('traffic.dnsAssets.error'),
        method: 'get',
        url: `${TOPOLOGIES_PATH}/${topologyUid}${DNS_ASSETS}`,
      }),
    }),
    getVmNicTargets: build.query<FetchVmTargetsResponse, string>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.VIRTUAL_MACHINE_TARGETS],
      query: (topologyUid) => ({
        errorMessage: i18n.t('traffic.vmNicTargets.fetch.error'),
        method: 'get',
        url: `${TOPOLOGIES_PATH}/${topologyUid}${VM_TARGETS}`,
      }),
    }),
  }),
});

export const {
  useGetVmNicTargetsQuery,
  useGetInventoryDnsAssetsQuery,
} = trafficApi;
