import { Alert, LinkButton } from 'dcloud-shared-ui';
import React, { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { PageHeading } from '../../../../../components/PageHeading';
import { AllHardwareProps } from './container';
import { DeleteModal } from './DeleteModal';
import { Table } from './Table';

export function All({
  hardware,
  hardwareToDelete,
  isError,
  isLoading,
  setHardwareToDelete,
}: AllHardwareProps): ReactElement {
  const { t } = useTranslation();
  const match = useRouteMatch();

  const onDeleteClick = useCallback(
    (hardware: Hardware) => {
      setHardwareToDelete(hardware);
    },
    [setHardwareToDelete],
  );

  return (
    <>
      <PageHeading
        pageTitle="Hardware"
        ctaBtn={
          <LinkButton
            size="wide"
            to={`${match.url}/add`}
            testId="hardware-all-new-button"
          >
            {t('buttons.new')}
          </LinkButton>
        }
      />
      {!isLoading && !hardware?.length ? (
        <Alert colour="info">{t('hardware.empty.alertText')}</Alert>
      ) : (
        <Table
          hardware={hardware}
          loading={isLoading}
          isError={isError}
          isLoading={isLoading}
          onDeleteClick={onDeleteClick}
        />
      )}
      {hardwareToDelete && (
        <DeleteModal
          hardware={hardwareToDelete}
          onClose={() => setHardwareToDelete(undefined)}
        />
      )}
    </>
  );
}
