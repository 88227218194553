import { connect } from 'react-redux';
import {
  topologiesSelectors,
  reset,
  fetchTopologies,
} from '../../redux/topologies/slice';
import { RootState } from '../../redux/store';
import { Topologies } from '.';

export type PropsFromRedux = ReturnType<typeof mapStateToProps>;

const mapStateToProps = (state: RootState) => ({
  isLoading: topologiesSelectors.getIsLoading(state),
  topologies: topologiesSelectors.selectAll(state),
});

const mapDispatchToProps = {
  fetchTopologies,
  reset,
};

export const ConnectedTopologies = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Topologies);
