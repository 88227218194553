import React, { ReactElement } from 'react';
import { CellProps } from 'react-table';
import { useTranslation } from 'react-i18next';
import { determinePushAction, isTopologyInSyncWithDCloud } from '../../utils';

export type StatusCellProps = CellProps<TopologyWithOnPushActions>;

export function StatusCell({
  row: { original },
}: StatusCellProps): ReactElement | string {
  const { t } = useTranslation();

  return isTopologyInSyncWithDCloud(original) ? (
    t('topologies.push.inSyncWithDcloud')
  ) : (
    <span className="flex">
      {determinePushAction(original, original.onPush, original.onUpdate)}
    </span>
  );
}
