import { Header, NavLinks, ThemeSwitch } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { MainRoutes } from '../../../constants/routes';
import { NavigationButtons } from './components/NavigationButtons';

export function EditHeader(): ReactElement {
  const { t } = useTranslation();

  return (
    <Header
      logoLinkTo={MainRoutes.Main}
      title={t('siteTitle')}
      center={<NavigationButtons />}
      right={
        <>
          <NavLinks
            links={[
              {
                label: t('configuration.header.links.home'),
                to: MainRoutes.Main,
              },
              {
                label: t('configuration.header.links.topologies'),
                to: MainRoutes.Topologies,
              },
            ]}
          />
          <ThemeSwitch />
        </>
      }
    />
  );
}
