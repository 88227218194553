import * as d3 from 'd3';
import { useEffect, RefObject } from 'react';
import { useUpdateNodeMutation } from '../../../../../redux/diagram/api';
import { x, y } from '../../../d3';
import { UseConnection } from '../../Connections/hooks/use-connection';

interface UseDrag extends BaseNodeProps {
  nodeRef: RefObject<HTMLDivElement>;
  redrawNodeConnections?: UseConnection['redrawNodeConnections'];
  defaultPosition: DiagramDisplay;
}

export function useDrag({
  defaultPosition,
  display,
  isReadOnly,
  nodeRef,
  redrawNodeConnections,
  uid,
}: UseDrag): void {
  const [updateNode] = useUpdateNodeMutation();

  useEffect(() => {
    if (isReadOnly) return;

    const currentRef = nodeRef.current;

    const drag = d3
      .drag<HTMLDivElement, DiagramDisplay>()
      .subject(function (_, d) {
        const transform = d3.zoomTransform(this);

        return {
          x: transform.rescaleX(x)(d?.x1 || defaultPosition.x1!),
          y: transform.rescaleY(y)(d?.y1 || defaultPosition.y1!),
        };
      })
      .on('drag', function (event) {
        const component = d3.select(this);
        const transform = d3.zoomTransform(this);

        component.attr(
          'style',
          `transform: translate(${event.x}px, ${event.y}px) scale(${transform.k}); z-index: 1`,
        );

        redrawNodeConnections && redrawNodeConnections(uid);
      })
      .on('end', function (event) {
        const transform = d3.zoomTransform(this);
        const invertedX = x.invert(transform.invertX(event.x));
        const invertedY = y.invert(transform.invertY(event.y));

        updateNode({
          display: { x1: invertedX, y1: invertedY },
          nodeUid: uid,
        });

        if (currentRef) {
          currentRef.style.zIndex = '0';
        }
      });

    if (currentRef) {
      d3.select<HTMLDivElement, DiagramDisplay>(currentRef)
        .datum(display || ({} as DiagramDisplay))
        .call(drag);
    }

    return () => {
      if (currentRef) {
        d3.select<HTMLDivElement, DiagramDisplay>(currentRef).on(
          'mousedown.drag',
          null,
        );
      }
    };
  }, [
    defaultPosition.x1,
    defaultPosition.y1,
    display,
    isReadOnly,
    nodeRef,
    redrawNodeConnections,
    uid,
    updateNode,
  ]);
}
