import React, { ReactElement } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { TableLoader } from '../../../../../components/Table/TableLoader';
import {
  useAddHardwareItemMutation,
  useFetchInventoryHardwareItemsQuery,
} from '../../../../../redux/configuration/hardware/api';
import { useSelectHardwareInventoryItems } from './use-select-hardware-inventory-items';
import { Add } from '.';

export function AddHardwareContainer(): ReactElement {
  const match = useRouteMatch();
  const { push } = useHistory();
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const { data, isLoading } = useFetchInventoryHardwareItemsQuery(topologyUid, {
    refetchOnFocus: false,
  });

  const [addHardware, { isError }] = useAddHardwareItemMutation();

  const handleAdd = async () => {
    await Promise.allSettled(
      selectedHardwareItems.map((item) => addHardware(item)),
    );
    push(match.url.replace('/add', ''));
  };

  const {
    handleOnSelect,
    selectedHardwareItems,
  } = useSelectHardwareInventoryItems({
    topologyUid,
  });

  if (!data || isLoading) {
    return <TableLoader />;
  }

  return (
    <Add
      hardware={data.inventoryHardwareItems}
      isLoading={isLoading}
      addHardware={handleAdd}
      handleOnSelect={handleOnSelect}
      selectedHardwareItems={selectedHardwareItems}
      isError={isError}
    />
  );
}

export interface AddHardwareRTKProps {
  hardware?: InventoryHardwareItem[];
  addHardware: VoidFunction;
  handleOnSelect: (
    isSelected: boolean,
    hardwareItem: InventoryHardwareItem,
  ) => void;
  selectedHardwareItems: InventoryHardwareItemToAdd[];
  isError: boolean;
  isLoading: boolean;
}
