import {
  createAction,
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { licencesSelectors } from '../../configuration/licences/slice';
import { RootState } from '../../store';

const inventoryLicenceAdapter = createEntityAdapter<InventoryLicence>({
  selectId: ({ id }) => id,
});

export const initialState = inventoryLicenceAdapter.getInitialState({
  fetched: false,
  loading: false,
});

export const fetchInventoryLicences = createAction<string>(
  'inventoryLicences/fetch',
);

const slice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchInventoryLicences, (state) => {
      state.loading = true;
    });
  },
  initialState,
  name: 'inventoryLicences',
  reducers: {
    fetchInventoryLicencesSuccess: (
      state,
      action: PayloadAction<InventoryLicence[]>,
    ) => {
      state.fetched = true;
      state.loading = false;
      inventoryLicenceAdapter.setAll(state, action.payload);
    },
  },
});

const getInventoryLicences = (state: RootState): typeof initialState =>
  state.entities[slice.name];

const getAvailableLicences = createSelector(
  licencesSelectors.getLicenceInventoryIds,
  inventoryLicenceAdapter.getSelectors(getInventoryLicences).selectAll,
  (ids, licences) => licences.filter((licence) => !ids.includes(licence.id)),
);

export const { reducer: inventoryLicencesReducer } = slice;

export const { fetchInventoryLicencesSuccess } = slice.actions;

export const inventoryLicenceSelectors = {
  ...inventoryLicenceAdapter.getSelectors(getInventoryLicences),
  getAvailableLicences,
  getInventoryLicences,
};
