import React, { ComponentType, ReactElement } from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  RouteComponentProps,
} from 'react-router-dom';
import i18n from '../../../i18n';
import { Documentation } from './Documentation';
import { ConnectedGeneral } from './General/container';
import { LicencesContainer } from './Licences/container';
import { VirtualMachinesRouter } from './VirtualMachines/VirtualMachinesRouter';
import { NetworksContainer } from './Networks/container';
import { Router as HardwareRouter } from './Hardware/Router';
import { VMStart } from './StartStopOrder/VMStart';
import { HardwareStart } from './StartStopOrder/HardwareStart';
import { VMStop } from './StartStopOrder/VMStop';
import { ConnectedRemoteAccess } from './RemoteAccess/container';
import { ConnectedNatRules } from './Traffic/NatRules/container';
import { ConnectedInboundProxyRules } from './Traffic/InboundProxyRules/container';
import { ConnectMailServer } from './Traffic/MailServer/container';
import { ConnectExternalDns } from './Traffic/ExternalDNS/container';

export interface ConfigurationRoute {
  exact: boolean;
  label: string;
  path: Routes | string;
  component: ComponentType<RouteComponentProps<EditTopologyParams>>;
}

export interface NestedConfigurationRoute {
  nestedRoutes: ConfigurationRoute[];
  label: string;
}

export type ConfigurationRoutes = Array<
  ConfigurationRoute | NestedConfigurationRoute
>;

export enum Routes {
  Documentation = '/documentation',
  ExternalDns = '/external-dns',
  General = '/general',
  Hardware = '/hardware',
  HardwareStart = '/hardware-start',
  InboundProxyRules = '/inbound-proxy-rules',
  Licences = '/licenses',
  MailServers = '/mail-servers',
  NatRules = '/nat-rules',
  Networks = '/networks',
  RemoteAccess = '/remote-access',
  StartAndStopOrder = '/start-and-stop-order',
  Traffic = '/traffic',
  VirtualMachines = '/virtual-machines',
  VMStart = '/vm-start',
  VMStop = '/vm-stop',
}

export const routes: ConfigurationRoutes = [
  {
    component: ConnectedGeneral,
    exact: true,
    label: i18n.t('general.name'),
    path: Routes.General,
  },
  {
    component: NetworksContainer,
    exact: true,
    label: i18n.t('networks.name'),
    path: Routes.Networks,
  },
  {
    component: VirtualMachinesRouter,
    exact: false,
    label: i18n.t('virtualMachines.name'),
    path: Routes.VirtualMachines,
  },
  {
    component: HardwareRouter,
    exact: false,
    label: i18n.t('hardware.name'),
    path: Routes.Hardware,
  },
  {
    component: LicencesContainer,
    exact: true,
    label: i18n.t('licences.name'),
    path: Routes.Licences,
  },
  {
    label: i18n.t('startStopOrder.name'),
    nestedRoutes: [
      {
        component: VMStart,
        exact: true,
        label: i18n.t('startStopOrder.vmStart.name'),
        path: `${Routes.StartAndStopOrder}${Routes.VMStart}`,
      },
      {
        component: VMStop,
        exact: true,
        label: i18n.t('startStopOrder.vmStop.name'),
        path: `${Routes.StartAndStopOrder}${Routes.VMStop}`,
      },
      {
        component: HardwareStart,
        exact: true,
        label: i18n.t('startStopOrder.hardwareStart.name'),
        path: `${Routes.StartAndStopOrder}${Routes.HardwareStart}`,
      },
    ],
  },
  {
    component: ConnectedRemoteAccess,
    exact: true,
    label: i18n.t('remoteAccess.name'),
    path: Routes.RemoteAccess,
  },
  {
    label: i18n.t('traffic.name'),
    nestedRoutes: [
      {
        component: ConnectedNatRules,
        exact: true,
        label: i18n.t('traffic.natRules.name'),
        path: `${Routes.Traffic}${Routes.NatRules}`,
      },
      {
        component: ConnectedInboundProxyRules,
        exact: true,
        label: i18n.t('traffic.inboundProxyRules.name'),
        path: `${Routes.Traffic}${Routes.InboundProxyRules}`,
      },
      {
        component: ConnectExternalDns,
        exact: true,
        label: i18n.t('traffic.externalDns.name'),
        path: `${Routes.Traffic}${Routes.ExternalDns}`,
      },
      {
        component: ConnectMailServer,
        exact: true,
        label: i18n.t('traffic.mailServer.name'),
        path: `${Routes.Traffic}${Routes.MailServers}`,
      },
    ],
  },
  {
    component: Documentation,
    exact: true,
    label: i18n.t('documentation.name'),
    path: Routes.Documentation,
  },
];

export function ConfigurationRouter(): ReactElement {
  const match = useRouteMatch();

  return (
    <Switch>
      {routes.map((route) =>
        'nestedRoutes' in route ? (
          route.nestedRoutes.map((nestedRoute) => (
            <Route
              {...nestedRoute}
              key={nestedRoute.path}
              path={`${match.path}${nestedRoute.path}`}
            />
          ))
        ) : (
          <Route
            {...route}
            key={route.path}
            path={`${match.path}${route.path}`}
          />
        ),
      )}
      <Redirect to={`${match.path}${Routes.General}`} />
    </Switch>
  );
}
