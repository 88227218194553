import React, { ReactElement } from 'react';

interface ConnectionLineProps {
  connection: DiagramConnection;
}

export const ConnectionLine = ({
  connection,
}: ConnectionLineProps): ReactElement | null => {
  return (
    <line
      stroke={connection.display?.color || 'var(--cui-color-sky)'}
      strokeWidth="2"
      data-uid={connection.uid}
      data-connection-from={connection.nodes.from.uid}
      data-connection-to={connection.nodes.to.uid}
    ></line>
  );
};
