import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { Alert } from 'dcloud-shared-ui';
import React, { ReactElement, SetStateAction } from 'react';
import { LicencesTable } from '../../../../entities/licences/components/LicencesTable';

export interface LicencesContentProps {
  alertText: string;
  setLicenceToEdit: ActionCreatorWithPayload<string, string>;
  loading: boolean;
  licences: Licence[];
  setLicenceToDelete: (value: SetStateAction<Licence | undefined>) => void;
}

export function LicencesContent({
  alertText,
  setLicenceToEdit,
  loading,
  licences,
  setLicenceToDelete,
}: LicencesContentProps): ReactElement {
  if (loading || licences.length) {
    return (
      <LicencesTable
        licences={licences}
        loading={loading}
        onDelete={setLicenceToDelete}
        onEdit={setLicenceToEdit}
      />
    );
  }

  return <Alert colour="info">{alertText}</Alert>;
}
