import classnames from 'classnames';
import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ControlledCheckbox,
  ControlledTextfield,
} from '../../../../../../../components';
import { parseChangeEventToNumber, parseNumberToString } from '../../utils';
import styles from './GuestAutomationForm.module.css';

export function GuestAutomationForm(): ReactElement {
  const { control, errors, watch } = useFormContext();
  const { t } = useTranslation();
  const formSectionClasses = classnames('col-6', styles.formSection);
  const watchIsGuestAutomationEnabled = watch('isGuestAutomationEnabled');

  return (
    <div className={styles.root}>
      <div className={formSectionClasses}>
        <div className="base-margin-bottom">
          <ControlledCheckbox
            control={control}
            id="isGuestAutomationEnabled"
            label={t('virtualMachines.edit.enableGuestAutomation')}
            testId="edit-vm-enable-guest-automation"
          />
        </div>

        <div
          data-testid="guestAutomationInputs"
          className={watchIsGuestAutomationEnabled ? '' : styles.hide}
        >
          <p>{t('virtualMachines.edit.sessionXmlMessage')}</p>
          <ControlledTextfield
            control={control}
            error={errors?.guestAutomation?.command?.message}
            label={t('virtualMachines.edit.command')}
            name="guestAutomation.command"
            required={false}
            testId="edit-vm-command"
          />
          <ControlledTextfield
            transform={{
              input: parseNumberToString,
              output: parseChangeEventToNumber,
            }}
            control={control}
            error={errors?.guestAutomation?.delaySecs?.message}
            label={t('virtualMachines.edit.delaySecs')}
            name="guestAutomation.delaySecs"
            required={false}
            testId="edit-vm-delaySecs"
            isNumber={true}
          />
        </div>
      </div>
    </div>
  );
}
