import { Header, ThemeSwitch, NavLinks, Links } from 'dcloud-shared-ui';
import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { MainRoutes } from '../../../constants/routes';

export function SiteHeader(): ReactElement {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const links = useMemo<Links[]>(
    () => [
      {
        active: pathname === MainRoutes.Main,
        label: t('common.home'),
        to: MainRoutes.Main,
      },
      {
        active:
          pathname === MainRoutes.Topologies ||
          pathname.includes(MainRoutes.CreateTopology),
        label: t('common.topologies'),
        to: MainRoutes.Topologies,
      },
    ],
    [pathname, t],
  );

  return (
    <Header
      title={t('siteTitle')}
      right={
        <>
          <NavLinks links={links} />
          <ThemeSwitch />
        </>
      }
      logoLinkTo={MainRoutes.Main}
    />
  );
}
