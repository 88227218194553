import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearDocumentation,
  fetchDocumentation,
  getDocumentation,
} from '../slice';

export function useDocumentation(
  uid: string,
): { documentation?: Documentation } {
  const dispatch = useDispatch();
  const { documentation } = useSelector(getDocumentation);

  useEffect(() => {
    dispatch(fetchDocumentation(uid));

    return () => {
      dispatch(clearDocumentation());
    };
  }, [dispatch, uid]);

  return { documentation };
}
