import React, { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CenteredSpinner } from '../../../../../components';
import { useURLParams } from '../../../../../hooks/use-url-params';
import { useGetSessionQuery } from '../../../../../redux/views/session-view/session/api';
import { setIsReadOnly } from '../../../../../redux/views/slice';
import { SessionDiagram } from '.';

export function SessionDiagramContainer(): ReactElement {
  const dispatch = useDispatch();
  const {
    path: { sessionUid },
    query: { versionUid },
  } = useURLParams({
    queryParams: ['versionUid'],
  });
  const { data, isLoading } = useGetSessionQuery({ sessionUid, versionUid });

  useEffect(() => {
    dispatch(setIsReadOnly(true));
  }, [dispatch]);

  if (!data || isLoading) {
    return <CenteredSpinner />;
  }

  return <SessionDiagram connections={data.diagram.connections} />;
}
