import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { editLicence } from '../../../../api/licences';
import {
  showErrorToastWorker,
  showSuccessToastWorker,
} from '../../../toast/sagas';
import { getEtag } from '../../etag/selectors';
import { setEtag } from '../../etag/slice';
import {
  editLicenceFailure,
  editLicenceSuccess,
  licencesSelectors,
} from '../slice';

export function* updateLicenceWorker({
  payload,
}: PayloadAction<EditLicenceFields>): SagaIterator {
  try {
    const etag: string = yield select(getEtag);
    const licence: Licence = yield select(licencesSelectors.getLicenceToEdit);
    const { data, etag: newEtag }: ApiDataResponse<Licence> = yield call(
      editLicence,
      { ...licence, quantity: payload.quantity },
      etag,
    );
    yield put(editLicenceSuccess(data));
    yield put(setEtag(newEtag));
    yield call(showSuccessToastWorker, 'licences.edit.success');
  } catch (e) {
    yield put(editLicenceFailure());
    yield call(showErrorToastWorker, 'licences.edit.error');
  }
}
