import React, { ReactElement } from 'react';
import { Alert, TableDraggableRowsProps } from 'dcloud-shared-ui';
import { useDispatch } from 'react-redux';
import {
  setOrdered,
  updatePositions,
} from '../../../../../../redux/configuration/start-stop-order/slice';
import { VMStartTable } from '../../VMStart/components/VMStartTable';
import { HardwareStartTable } from '../../HardwareStart/components/HardwareStartTable';
import { StartStopButtonGroup } from '../StartStopButtonGroup';
import { TableLoader } from '../../../../../../components/Table/TableLoader';

export interface StartStopOrderContentProps {
  isFetching: boolean;
  isLoading: boolean;
  buttonUnorderedLabel: string;
  buttonOrderedLabel: string;
  ordered?: boolean;
  positions?: StartStopOrderPosition[];
  alertText: string;
  table: typeof VMStartTable | typeof HardwareStartTable;
  refetch: VoidFunction;
}

export function StartStopOrderContent({
  buttonUnorderedLabel,
  buttonOrderedLabel,
  isFetching,
  isLoading,
  positions,
  alertText,
  ordered,
  table: Table,
}: StartStopOrderContentProps): ReactElement {
  const dispatch = useDispatch();

  const handleOrderedOnClick = (ordered: boolean) => {
    dispatch(setOrdered(ordered));
  };

  const handleOnRowDrag: TableDraggableRowsProps<
    StartStopOrderPosition
  >['handleOnRowDrag'] = ({ destination, draggableId, source }) => {
    dispatch(updatePositions({ destination, draggableId, source }));
  };

  if (!isFetching && !positions?.length) {
    return <Alert colour="info">{alertText}</Alert>;
  }

  return (
    <>
      {!isLoading && !!positions?.length && (
        <StartStopButtonGroup
          buttonUnorderedLabel={buttonUnorderedLabel}
          buttonOrderedLabel={buttonOrderedLabel}
          handleButtonUnorderedOnClick={() => handleOrderedOnClick(false)}
          handleButtonOrderedOnClick={() => handleOrderedOnClick(true)}
          isUnorderedButtonSelected={!ordered}
        />
      )}

      {isLoading && <TableLoader />}

      {!isLoading && ordered && !!positions?.length && (
        <div className="padding-top">
          <Table
            positions={positions || []}
            handleOnRowDrag={handleOnRowDrag}
          />
        </div>
      )}
    </>
  );
}
