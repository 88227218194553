export enum MainRoutes {
  CreateTopology = '/create-topology',
  Edit = '/edit/:uid',
  Main = '/',
  Sessions = '/sessions/:sessionUid',
  Topologies = '/topologies',
}

export enum CreateTopologyRoutes {
  DataCenter = '/create-topology',
  DemoImport = '/create-topology/demo-import',
  DemoType = '/create-topology/demo-type',
  Details = '/create-topology/details',
}
