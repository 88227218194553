import React, { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CenteredSpinner } from '../../../../../../components';
import { useGetNetworksAxiosQuery } from '../../../../../../redux/configuration/networks/api';
import { DeleteNetworkModal } from '../DeleteNetworkModal';
import { AddNetwork } from './AddNetwork';
import { NetworkTable } from './NetworkTable';
import { convertNetworksToOptions, convertInterfacesToOptions } from './utils';

interface FormNetworkingProps {
  addNetworkInterface: (networkInterfaceFields: AddNetworkFields) => void;
  hardware: Hardware;
  networkInterfaces: HardwareNetworkInterface[];
  onConfirmDelete: VoidFunction;
  onDeleteClick: (uid: string) => void;
}

export function FormNetworking({
  addNetworkInterface,
  hardware,
  networkInterfaces,
  onConfirmDelete,
  onDeleteClick,
}: FormNetworkingProps): ReactElement {
  const [networkInterfaceToDelete, setNetworkInterfaceToDelete] = useState('');
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const { data: networks } = useGetNetworksAxiosQuery(topologyUid);

  if (!networks) {
    return <CenteredSpinner />;
  }

  const networkInterfacesWithNames = networkInterfaces.map((item) => {
    const networkInterface = networks!.find(
      (network) => item.network.uid === network.uid,
    );

    return {
      ...item,
      network: { ...item.network, name: networkInterface!.name },
    } as HardwareNetworkInterface;
  });

  return (
    <>
      <AddNetwork
        onAddClick={addNetworkInterface}
        networks={convertNetworksToOptions(networks)}
        interfaces={convertInterfacesToOptions(
          hardware.inventoryHardwareItem.networkInterfaces,
          networkInterfaces,
        )}
      />
      {!!networkInterfacesWithNames.length && (
        <NetworkTable
          networkInterfaces={networkInterfacesWithNames}
          onDeleteClick={onDeleteClick}
        />
      )}
      <DeleteNetworkModal
        onClose={() => setNetworkInterfaceToDelete('')}
        onConfirm={onConfirmDelete}
        show={!!networkInterfaceToDelete}
      />
    </>
  );
}
