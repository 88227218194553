import React, { ChangeEvent, ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps, Column, Hooks, PluginHook } from 'react-table';
import { Table } from '../../../../components';
import { QuantitySelect } from '../../../../components/Table/components/QuantitySelect';
import { RowCheckbox } from '../../../../components/Table/components/RowCheckbox';
import { MAX_LICENCE_QTY } from '../../../../constants/entities';
import { UseSelectLicencesProps } from '../../hooks/use-select-licences';
import styles from './InventoryLicencesTable.module.css';

export interface InventoryLicencesTableProps extends UseSelectLicencesProps {
  inventoryLicences: InventoryLicence[];
  loading: boolean;
}

export function InventoryLicencesTable({
  handleOnSelect,
  handleQuantityChange,
  inventoryLicences,
  loading,
  selectedLicences,
}: InventoryLicencesTableProps): ReactElement {
  const { t } = useTranslation();

  const columns = useMemo<Column<InventoryLicence>[]>(
    () => [
      {
        Header: () => t('licences.table.headings.name'),
        accessor: 'name',
        minWidth: 200,
      },
    ],
    [t],
  );

  const pluginHooks = useMemo<PluginHook<InventoryLicence>[]>(
    () => [
      (hooks: Hooks<InventoryLicence>) => {
        hooks.visibleColumns.push((columns) => [
          {
            // eslint-disable-next-line react/display-name
            Cell: ({ row }: CellProps<InventoryLicence>) => (
              <RowCheckbox onChange={handleOnSelect} row={row} />
            ),
            Header: '',
            id: 'selection',
            width: 20,
          },
          ...columns,
          {
            // eslint-disable-next-line react/display-name
            Cell: ({
              row: { isSelected, original },
            }: CellProps<InventoryLicence>) => (
              <QuantitySelect
                className={styles.quantity}
                maxQuantity={MAX_LICENCE_QTY}
                onChange={({ target }: ChangeEvent<HTMLSelectElement>) => {
                  handleQuantityChange(original.id, target.value);
                }}
                show={isSelected}
                value={
                  selectedLicences.find(
                    ({ inventoryLicense: { id } }) => id === original.id,
                  )?.quantity
                }
              />
            ),
            Header: () => !!selectedLicences.length && t('common.quantity'),
            id: 'amount',
            width: 20,
          },
        ]);
      },
    ],
    [handleOnSelect, handleQuantityChange, selectedLicences, t],
  );

  return (
    <Table<InventoryLicence>
      className={styles.root}
      initialState={{ pageSize: 10, sortBy: [{ desc: false, id: 'name' }] }}
      data={inventoryLicences}
      columns={columns}
      loading={loading}
      pluginHooks={pluginHooks}
    />
  );
}
