import { select, call, put } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import { cloneTopology } from '../../../api/topologies';
import { MainRoutes } from '../../../constants/routes';
import { cloneTopologyFailure, topologiesSelectors } from '../slice';
import {
  showErrorToastWorker,
  showSuccessToastWorker,
} from '../../toast/sagas';

export type CloneTopologyAction = PayloadAction<CloneFields>;

export function* cloneTopologyWorker(
  action: CloneTopologyAction,
): SagaIterator {
  const topologyToClone: Topology | undefined = yield select(
    topologiesSelectors.getTopologyToClone,
  );

  if (!topologyToClone) {
    return;
  }

  try {
    const response: CloneTopology = yield call(
      cloneTopology,
      topologyToClone.uid,
      action.payload,
    );

    yield put(push(MainRoutes.Edit.replace(':uid', response.data.uid)));
    yield call(showSuccessToastWorker, 'topologies.clone.success');
  } catch (e) {
    yield call(showErrorToastWorker);
    yield put(cloneTopologyFailure());
  }
}
