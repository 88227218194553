import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useDeleteTopologyMutation } from '../../../../redux/topologies/api';
import {
  clearTopologyToDelete,
  topologiesSelectors,
} from '../../../../redux/topologies/slice';
import { RootState } from '../../../../redux/store';
import { DeleteTopologyModal, DeleteTopologyModalProps } from '.';

export type PropsFromRedux = ReturnType<typeof mapStateToProps>;

type DeleteTopologyModalContainerProps = Pick<
  DeleteTopologyModalProps,
  'clearTopologyToDelete' | 'topologyToDelete'
>;

const mapStateToProps = (state: RootState) => ({
  topologyToDelete: topologiesSelectors.getTopologyToDelete(state),
});

const mapDispatchToProps = {
  clearTopologyToDelete,
};

function DeleteTopologyModalContainer({
  clearTopologyToDelete,
  topologyToDelete,
}: DeleteTopologyModalContainerProps): ReactElement {
  const [deleteTopology, { isLoading }] = useDeleteTopologyMutation();

  return (
    <DeleteTopologyModal
      clearTopologyToDelete={clearTopologyToDelete}
      deleteTopology={() => deleteTopology(topologyToDelete!.uid)}
      isDeleting={isLoading}
      topologyToDelete={topologyToDelete}
    />
  );
}

export const ConnectedDeleteTopologyModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteTopologyModalContainer);
