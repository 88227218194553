import React, { ReactElement } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PageHeading } from '../../../../components/PageHeading';
import { PrimaryButton } from '../../../../components/Buttons/PrimaryButton';
import { RemoteAccessForm } from './RemoteAccessForm';
import { RemoteAccessProps } from './container';

export function RemoteAccess({
  data,
  etag,
  isError,
  isFetching,
  isUpdating,
  updateRemoteAccess,
}: Required<RemoteAccessProps>): ReactElement {
  const { t } = useTranslation();

  const formMethods = useForm<RemoteAccessFormData>({
    defaultValues: {
      anyconnectEnabled: data.anyconnectEnabled,
      endpointKitEnabled: data.endpointKitEnabled,
    },
    mode: 'all',
  });

  const handleUpdateClicked: SubmitHandler<RemoteAccessFormData> = (
    formData,
  ) => {
    updateRemoteAccess({
      etag,
      remoteAccess: { topology: data.topology, uid: data.uid, ...formData },
    });
    formMethods.reset(formData);
  };

  const { dirtyFields } = formMethods.formState;

  return (
    <section className="half-padding-left">
      <FormProvider {...formMethods}>
        <PageHeading
          ctaBtn={
            <PrimaryButton
              onClick={formMethods.handleSubmit(handleUpdateClicked)}
              testId="remote-access-form-submit-btn"
              type="submit"
              colour="success"
              disabled={Object.keys(dirtyFields).length === 0}
              loading={isUpdating}
            >
              {t('buttons.update')}
            </PrimaryButton>
          }
          pageTitle={t('remoteAccess.name')}
        />
        {!isError && (
          <RemoteAccessForm
            control={formMethods.control}
            isFetching={isFetching}
          />
        )}
      </FormProvider>
    </section>
  );
}
