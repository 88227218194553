import { useTranslation } from 'react-i18next';
import { useErrorToast } from '../../../../../../hooks/toasts/use-error-toast';
import { useGetVMDemosInventoryQuery } from '../../../../../../redux/configuration/virtual-machines/api';

interface UseFetchInventoryDemos {
  inventoryDemos: InventoryDemo[];
  isFetching: boolean;
}

export function useFetchInventoryDemos(
  topologyUid: string,
): UseFetchInventoryDemos {
  const { t } = useTranslation();
  const { showErrorToast } = useErrorToast();

  const {
    data: inventoryDemos = [],
    isFetching,
    isError,
  } = useGetVMDemosInventoryQuery(topologyUid);

  isError && showErrorToast(t('demos.fetch.errors.many'));

  return {
    inventoryDemos,
    isFetching,
  };
}
