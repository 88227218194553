import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useErrorToast } from '../../../../../../hooks/toasts/use-error-toast';
import { useGetVmsQuery } from '../../../../../../redux/configuration/virtual-machines/api';

interface UseFetchVms {
  data: VirtualMachine[];
  isFetching: boolean;
}

export function useFetchVms(): UseFetchVms {
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const { t } = useTranslation();
  const { showErrorToast } = useErrorToast();

  const { data = [], isError, isFetching } = useGetVmsQuery(topologyUid);

  useEffect(() => {
    isError &&
      !isFetching &&
      showErrorToast(t('virtualMachines.fetch.errors.many'));
  }, [isError, isFetching, showErrorToast, t]);

  return {
    data,
    isFetching,
  };
}
