import { yupResolver } from '@hookform/resolvers/yup';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionProps,
} from '../../../../../../components/Accordion';
import { PrimaryButton } from '../../../../../../components/Buttons/PrimaryButton';
import { PageHeading } from '../../../../../../components/PageHeading';
import { TEMP_UID_KEY } from '../constants';
import { HardwareProps } from '../container';
import { DeleteNetworkModal } from '../DeleteNetworkModal';
import { FormScriptsContainer } from '../FormScripts/container';
import { FormTemplatesContainer } from '../FormTemplate/container';
import { editValidationSchema } from './form-validation';
import { FormGeneral } from './FormGeneral';
import { FormNetworking } from './FormNetworking';
import {
  createTemporaryNetwork,
  areNetworkInterfacesEqual,
  returnFieldOrUndefined,
} from './utils';

export function Form({
  allHardware,
  hardwareItem,
  isSuccess,
  isUpdating,
  networkInterfaceToDelete,
  setNetworkInterfaceToDelete,
  updateHardwareItem,
}: HardwareProps): ReactElement {
  const { t } = useTranslation();

  const formMethods = useForm<EditHardwareFields>({
    defaultValues: {
      hardwareConsoleEnabled: hardwareItem.hardwareConsoleEnabled || false,
      hardwareNetworkInterfaces: hardwareItem.hardwareNetworkInterfaces || [],
      inventoryCustomScript: hardwareItem.inventoryCustomScript || {
        name: '',
        uid: '',
      },
      inventoryShutdownScript: hardwareItem.inventoryShutdownScript || {
        name: '',
        uid: '',
      },
      inventoryStartupScript: hardwareItem.inventoryStartupScript || {
        name: '',
        uid: '',
      },
      inventoryTemplateConfigScript: hardwareItem.inventoryTemplateConfigScript || {
        name: '',
        uid: '',
      },
      name: hardwareItem.name || '',
      powerControlEnabled: hardwareItem.powerControlEnabled || false,
    },
    mode: 'all',
    resolver: yupResolver(
      editValidationSchema(
        allHardware
          .filter((item) => item.uid !== hardwareItem.uid)
          .map((item) => item.name),
      ),
    ),
  });

  const [networkInterfaces, setNetworkInterfaces] = useState(
    hardwareItem.hardwareNetworkInterfaces,
  );

  const { isDirty, isValid } = formMethods.formState;

  const areEqual = areNetworkInterfacesEqual(
    hardwareItem?.hardwareNetworkInterfaces,
    networkInterfaces,
  );
  const isUnchanged = (!isDirty || !isValid) && areEqual;

  const onConfirmDelete = useCallback(() => {
    setNetworkInterfaces((prevState) =>
      prevState.filter((item) => item.uid !== networkInterfaceToDelete),
    );

    setNetworkInterfaceToDelete('');
  }, [networkInterfaceToDelete, setNetworkInterfaceToDelete]);

  const addNewNetworkInterface = (networkInterfaceFields: AddNetworkFields) => {
    setNetworkInterfaces((prevState) => [
      ...prevState,
      createTemporaryNetwork(networkInterfaceFields),
    ]);
  };

  const updateHardware = (fields: EditHardwareFields): void => {
    if (!hardwareItem.inventoryHardwareItem.hardwareConsoleAvailable) {
      delete fields.hardwareConsoleEnabled;
    }

    if (!hardwareItem.inventoryHardwareItem.powerControlAvailable) {
      delete fields.powerControlEnabled;
    }

    const payload: UpdateHardwarePayload = {
      ...fields,
      hardwareNetworkInterfaces: fields.hardwareNetworkInterfaces.map(
        (item) => {
          if (item.uid.includes(TEMP_UID_KEY)) {
            return {
              network: { uid: item.network.uid },
              networkInterface: { id: item.networkInterface.id },
            };
          }

          return item;
        },
      ),
      inventoryCustomScript: returnFieldOrUndefined(
        fields.inventoryCustomScript,
      ),
      inventoryHardwareItem: hardwareItem.inventoryHardwareItem,
      inventoryShutdownScript: returnFieldOrUndefined(
        fields.inventoryShutdownScript,
      ),
      inventoryStartupScript: returnFieldOrUndefined(
        fields.inventoryStartupScript,
      ),
      inventoryTemplateConfigScript: returnFieldOrUndefined(
        fields.inventoryTemplateConfigScript,
      ),
      name: fields.name,
      topology: hardwareItem.topology,
      uid: hardwareItem.uid,
    };
    updateHardwareItem(payload);
    isSuccess && resetForm(fields);
  };

  const onSubmit = (values: EditHardwareFields) => {
    updateHardware({ ...values, hardwareNetworkInterfaces: networkInterfaces });
  };

  const resetForm = (newHardware: EditHardwareFields) => {
    formMethods.reset(newHardware);
    setNetworkInterfaces(newHardware.hardwareNetworkInterfaces);
  };

  const accordionRows = useMemo<AccordionProps['rows']>(
    () => [
      {
        children: <FormGeneral hardware={hardwareItem} />,
        title: t('hardware.edit.titles.general'),
      },
      {
        children: <FormScriptsContainer />,
        title: t('hardware.edit.titles.scripts'),
      },
      {
        children: <FormTemplatesContainer />,
        title: t('hardware.edit.titles.templates'),
      },
      {
        children: (
          <FormNetworking
            hardware={hardwareItem}
            networkInterfaces={networkInterfaces}
            addNetworkInterface={addNewNetworkInterface}
            onDeleteClick={setNetworkInterfaceToDelete}
            onConfirmDelete={onConfirmDelete}
          />
        ),
        title: t('hardware.edit.titles.networking'),
      },
    ],
    [
      hardwareItem,
      t,
      networkInterfaces,
      setNetworkInterfaceToDelete,
      onConfirmDelete,
    ],
  );

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <PageHeading
          withBackBtn={true}
          pageTitle={t('hardware.edit.titles.page')}
          ctaBtn={
            <PrimaryButton
              type="submit"
              colour="success"
              size="large"
              loading={isUpdating}
              disabled={isUnchanged}
              testId="edit-hardware-update"
            >
              {t('buttons.update')}
            </PrimaryButton>
          }
        />
        <Accordion rows={accordionRows} />
      </form>
      {networkInterfaceToDelete && (
        <DeleteNetworkModal
          onClose={() => setNetworkInterfaceToDelete('')}
          onConfirm={onConfirmDelete}
          show={!!networkInterfaceToDelete}
        />
      )}
    </FormProvider>
  );
}
