import { SelectOption } from 'dcloud-shared-ui';
import { PLEASE_SELECT_OPTION } from '../../../../../constants/form';

export function buildTargetOptions(
  vmsWithValidIPAddressOptions?: VmsWithValidIPAddressOptions,
  vms?: VirtualMachine[],
): SelectOption<string>[] {
  if (!vmsWithValidIPAddressOptions || !vms) {
    return [];
  }

  const vmKeys = Object.keys(vmsWithValidIPAddressOptions);
  const result = [PLEASE_SELECT_OPTION];
  vms.forEach((vm) => {
    if (vmKeys.includes(vm.uid)) {
      result.push({ name: vm.name, value: vm.uid });
    }
  });
  return result;
}

export function buildIpAddressOptions(
  vmNetworkInterfaces?: VMNetworkInterface[],
): SelectOption<string>[] {
  return vmNetworkInterfaces
    ? vmNetworkInterfaces?.reduce(
        (options: SelectOption<string>[], vmNetworkInterface) => {
          vmNetworkInterface.ipAddress &&
            options.push({
              name: vmNetworkInterface.ipAddress,
              value: vmNetworkInterface.uid as string,
            });
          return options;
        },
        [PLEASE_SELECT_OPTION],
      )
    : [];
}

export function buildIPAddressOptionsByVM(
  virtualMachines?: VirtualMachine[],
): VmsWithValidIPAddressOptions {
  const res: VmsWithValidIPAddressOptions = {};
  virtualMachines?.map((vm) => {
    const ipAddressOptions = buildIpAddressOptions(vm.vmNetworkInterfaces);
    if (ipAddressOptions.length > 1) {
      res[vm.uid] = {
        ipAddressOptions: buildIpAddressOptions(vm.vmNetworkInterfaces),
        uid: vm.uid,
      };
    }
  });
  return res;
}

export function selectIPAddressOptionsFromVM(
  ipAddressOptionsByVM?: VmsWithValidIPAddressOptions,
  selectedVM?: string,
): SelectOption<string>[] {
  return selectedVM && ipAddressOptionsByVM
    ? ipAddressOptionsByVM[selectedVM].ipAddressOptions
    : [PLEASE_SELECT_OPTION];
}

interface BuildInboundProxyRulePayloadProps {
  formData: InboundProxyRuleFormData;
  topologyUid: TopologyUid['uid'];
}

export const buildInboundProxyRulePayload = ({
  formData,
  topologyUid,
}: BuildInboundProxyRulePayloadProps): InboundProxyRulePostPayload => {
  const {
    ipAddress,
    hyperLinkText,
    showHyperlink,
    ssl,
    tcpPort,
    urlPath,
  } = formData;

  return {
    hyperlink: {
      show: showHyperlink,
      text: hyperLinkText,
    },
    ssl,
    tcpPort,
    topology: { uid: topologyUid },
    urlPath,
    vmNicTarget: {
      uid: ipAddress,
    },
  };
};
