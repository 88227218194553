import { SelectOption } from 'dcloud-shared-ui';
import { PLEASE_SELECT_OPTION } from '../../../../../../constants/form';

type NetworkOptions = SelectOption<string>[];

export function getNetworkOptions(networks: Network[]): NetworkOptions {
  return [
    PLEASE_SELECT_OPTION,
    ...networks.map((network) => ({
      name: network.name,
      value: network.uid,
    })),
  ];
}
