import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { call, delay, put, select } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';
import i18n from '../../../i18n';
import {
  fetchNetworks as fetchNetworksAPI,
  updateNetwork as updateNetworkAPI,
} from '../../../api/networks';
import config from '../../../config';
import {
  showErrorToastWorker,
  showSuccessToastWorker,
} from '../../toast/sagas';
import { addToast, removeToast } from '../../toast/slice';
import { getEtag } from '../etag/selectors';
import { setEtag } from '../etag/slice';
import { fetchNetworks, fetchNetworksSuccess, setNetwork } from './slice';

export const INFO_DELAY = 1000;

export function* fetchNetworksWorker(
  action: PayloadAction<string>,
): SagaIterator {
  try {
    const response: Network[] = yield call(fetchNetworksAPI, action.payload);
    yield delay(config.FLICKER_DELAY);
    yield put(fetchNetworksSuccess(response));
  } catch (e) {
    yield call(showErrorToastWorker, 'networks.fetch.errors.many');
  }
}

export function* updateNetworkWorker({
  payload: { formData, network },
}: PayloadAction<UpdateNetworkActionPayload>): SagaIterator {
  const infoToastUid = yield call(uuid);

  try {
    const payload: NetworkToUpdate = {
      ...network,
      description: formData.description,
      inventoryNetwork: { id: formData.subnet },
      name: formData.name,
    };
    const etag: string = yield select(getEtag);

    yield put(
      addToast({
        message: i18n.t('common.updating'),
        title: '',
        type: 'info',
        uid: infoToastUid,
      }),
    );

    const { data, etag: newEtag }: NetworkApiResponse = yield call(
      updateNetworkAPI,
      payload,
      etag,
    );
    yield put(setEtag(newEtag));
    yield put(setNetwork({ changes: data, id: payload.uid }));
    yield delay(INFO_DELAY);
    yield put(removeToast(infoToastUid));
    yield call(showSuccessToastWorker, 'networks.update.success');
    yield put(fetchNetworks(network.topology.uid));
  } catch (error) {
    yield put(removeToast(infoToastUid));
    yield call(showErrorToastWorker, 'networks.update.error');
    yield put(setNetwork({ changes: network, id: network.uid }));
  }
}
