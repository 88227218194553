import { dcloudApiAxios, TAGS } from '../../../api';
import {
  NAT_RULES,
  NAT_RULES_IP,
  NAT_RULES_VM,
  TOPOLOGIES_PATH,
} from '../../../../api/paths';
import i18n from '../../../../i18n';

export const demosApi = dcloudApiAxios.injectEndpoints({
  endpoints: (build) => ({
    createIpNatRule: build.mutation<NatRule, NatRulePostPayload>({
      invalidatesTags: [TAGS.NAT_RULES],
      query: (payload) => ({
        data: payload,
        errorMessage: i18n.t('traffic.natRules.add.error'),
        method: 'post',
        successMessage: i18n.t('traffic.natRules.add.success'),
        url: NAT_RULES_IP,
      }),
    }),
    createVmNatRule: build.mutation<NatRule, NatRulePostPayload>({
      invalidatesTags: [TAGS.NAT_RULES],
      query: (payload) => ({
        data: payload,
        errorMessage: i18n.t('traffic.natRules.add.error'),
        method: 'post',
        successMessage: i18n.t('traffic.natRules.add.success'),
        url: NAT_RULES_VM,
      }),
    }),
    deleteNatRule: build.mutation<undefined, NatRule>({
      invalidatesTags: [TAGS.NAT_RULES],
      query: (natRule) => ({
        errorMessage: i18n.t('traffic.natRules.delete.error'),
        method: 'delete',
        successMessage: i18n.t('traffic.natRules.delete.success'),
        url: `${NAT_RULES}/${natRule.uid}`,
      }),
    }),
    getNatRules: build.query<FetchNatRulesResponse, string>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.NAT_RULES],
      query: (topologyUid) => ({
        errorMessage: i18n.t('traffic.natRules.fetch.error'),
        method: 'get',
        url: `${TOPOLOGIES_PATH}/${topologyUid}${NAT_RULES}`,
      }),
    }),
  }),
});

export const {
  useCreateIpNatRuleMutation,
  useCreateVmNatRuleMutation,
  useDeleteNatRuleMutation,
  useGetNatRulesQuery,
} = demosApi;
