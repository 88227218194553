import React, { ReactElement, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  useGetVmQuery,
  useGetVmsQuery,
} from '../../../../../redux/configuration/virtual-machines/api';
import { useGetOperatingSystemsQuery } from '../../../../../redux/configuration/operating-systems/api';
import { setNetworkingToEdit } from '../../../../../redux/configuration/virtual-machines/slice';
import { RootState } from '../../../../../redux/store';
import { getOperatingSystems } from '../../../../../redux/entities/operating-systems/slice';
import {
  createVmNamesArray,
  determineRDPEnabledNIC,
  determineSSHEnabledNIC,
} from './utils';
import { EditLoader } from './components/EditLoader';
import { ConnectedVirtualMachinesEditForm } from './components/Form/container';

export function VirtualMachineEditContainer({
  setNetworkingToEdit: onSetNetworkingToEdit,
  operatingSystems,
}: VirtualMachineEditContainerProps): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const { virtualMachineUid } = useParams<{ virtualMachineUid: string }>();
  const {
    data: vmToEdit,
    isFetching: isFetchingVM,
    isError: isErrorLoadingVM,
    rdpEnabledNic,
    sshEnabledNic,
  } = useGetVmQuery(virtualMachineUid, {
    refetchOnFocus: false,
    selectFromResult: ({ data, isFetching, isError }) => ({
      data,
      isError,
      isFetching,
      rdpEnabledNic: determineRDPEnabledNIC(data?.vmNetworkInterfaces || []),
      sshEnabledNic: determineSSHEnabledNIC(data?.vmNetworkInterfaces || []),
    }),
  });

  useEffect(() => {
    if (vmToEdit) {
      onSetNetworkingToEdit(vmToEdit.vmNetworkInterfaces);
    }

    return () => {
      onSetNetworkingToEdit(undefined);
    };
  }, [onSetNetworkingToEdit, vmToEdit]);

  const { vmNames, isFetchingVms } = useGetVmsQuery(topologyUid, {
    refetchOnFocus: false,
    selectFromResult: ({ data, isFetching }) => ({
      isFetchingVms: isFetching,
      vmNames: createVmNamesArray(data, virtualMachineUid),
    }),
  });

  const {
    isError: isErrorLoadingOperatingSystems,
  } = useGetOperatingSystemsQuery(undefined, {
    refetchOnFocus: false,
    skip: operatingSystems !== undefined,
  });

  if (
    !vmToEdit ||
    !vmNames ||
    !operatingSystems ||
    isFetchingVM ||
    isFetchingVms ||
    isErrorLoadingOperatingSystems ||
    isErrorLoadingVM
  ) {
    return <EditLoader />;
  }

  return (
    <ConnectedVirtualMachinesEditForm
      rdpEnabledNic={rdpEnabledNic}
      sshEnabledNic={sshEnabledNic}
      vmNames={vmNames}
      vmToEdit={vmToEdit}
    />
  );
}

type VirtualMachineEditContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const mapStateToProps = (state: RootState) => ({
  operatingSystems: getOperatingSystems(state).operatingSystems,
});

const mapDispatchToProps = {
  setNetworkingToEdit,
};

export const ConnectedVirtualMachinesEdit = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VirtualMachineEditContainer);
