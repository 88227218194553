import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  createSelector,
} from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { networksSelectors } from '../../configuration/networks/slice';

const inventoryNetworksAdapter = createEntityAdapter<InventoryNetwork>({
  selectId: ({ id }) => id,
});

export const initialState = inventoryNetworksAdapter.getInitialState({
  fetched: false,
  loading: false,
});

const slice = createSlice({
  initialState,
  name: 'inventoryNetworks',
  reducers: {
    fetchInventoryNetworks: (state) => {
      state.loading = true;
    },
    fetchInventoryNetworksSuccess: (
      state,
      action: PayloadAction<InventoryNetwork[]>,
    ) => {
      state.fetched = true;
      state.loading = false;
      inventoryNetworksAdapter.setAll(state, action.payload);
    },
  },
});

const getInventoryNetworks = (state: RootState): typeof initialState =>
  state.entities[slice.name];

export const { reducer: inventoryNetworksReducer } = slice;

export const {
  fetchInventoryNetworks,
  fetchInventoryNetworksSuccess,
} = slice.actions;

const getAvailableRouted = createSelector(
  networksSelectors.getRoutedNetworkInventoryIds,
  inventoryNetworksAdapter.getSelectors(getInventoryNetworks).selectAll,
  (ids, networks) =>
    networks.filter(
      (network) => network.type === 'ROUTED' && !ids.includes(network.id),
    ),
);

const getAvailableUnRouted = createSelector(
  networksSelectors.getUnroutedNetworkInventoryIds,
  inventoryNetworksAdapter.getSelectors(getInventoryNetworks).selectAll,
  (ids, networks) =>
    networks.filter(
      (network) => network.type === 'UNROUTED' && !ids.includes(network.id),
    ),
);

const getMaxNumReached = createSelector(
  getAvailableUnRouted,
  getAvailableRouted,
  (unRoutedIds, routedIds) => unRoutedIds.length + routedIds.length === 0,
);

export const inventoryNetworksSelectors = {
  ...inventoryNetworksAdapter.getSelectors(getInventoryNetworks),
  getAvailableRouted,
  getAvailableUnRouted,
  getFetched: createSelector(getInventoryNetworks, ({ fetched }) => fetched),
  getInventoryNetworks,
  getMaxNumReached,
};
