import { useTranslation } from 'react-i18next';
import React, { ReactElement } from 'react';
import { ConfirmModal } from '../../../../../../../../../components';

export interface NetworkingConfirmDeleteModalProps {
  onConfirm: (VMNetworkInterface: VMNetworkInterface) => void;
  interfaceToConfirmDelete: VMNetworkInterface;
  setInterfaceToConfirmDelete: (
    VMNetworkInterface?: VmNetworkInterfaceToEdit,
  ) => void;
}

export function NetworkingConfirmDeleteModal({
  onConfirm,
  interfaceToConfirmDelete,
  setInterfaceToConfirmDelete,
}: NetworkingConfirmDeleteModalProps): ReactElement {
  const { t } = useTranslation();

  return (
    <ConfirmModal
      clickScreenToClose={true}
      heading={t('virtualMachines.edit.networking.confirmDelete.heading')}
      subHeading={t('virtualMachines.edit.networking.confirmDelete.subheading')}
      loading={false}
      onCancel={() => setInterfaceToConfirmDelete(undefined)}
      onClose={() => setInterfaceToConfirmDelete(undefined)}
      onConfirm={() => onConfirm(interfaceToConfirmDelete!)}
      confirmButtonLabel={t('buttons.delete')}
      show={true}
      size="default"
      testId="networking-interface-delete-modal"
    />
  );
}
