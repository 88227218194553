import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { DemosTable } from '../../../../../../../entities/virtual-machines/components/DemosTable';
import { DemoVmsTable } from '../../../../../../../entities/virtual-machines/components/DemoVmsTable';
import { getVirtualMachines } from '../../../../../../../redux/configuration/virtual-machines/slice';
import { useFetchInventoryDemos } from '../../hooks/use-fetch-inventory-demos';

export function DemosTabContent(): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const { inventoryDemos, isFetching } = useFetchInventoryDemos(topologyUid);
  const { selectedDemoId } = useSelector(getVirtualMachines);
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={path}
        render={() => (
          <DemosTable demos={inventoryDemos} loading={isFetching} />
        )}
      />
      <Route
        path={`${path}/vms`}
        render={() => <DemoVmsTable demoId={selectedDemoId} />}
      />
    </Switch>
  );
}
