import React, { ReactElement, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { Table } from '../../../../../../../components';
import { TableLoader } from '../../../../../../../components/Table/TableLoader';
import { NatRulesTableActions } from '../NatRulesTableActions';

enum NatRuleTypes {
  IP = 'NAT to IP Address',
  VM_NIC = 'NAT to VM',
}

type NatRulesTableProps = {
  isError: boolean;
  isLoading: boolean;
  natRules: NatRule[];
  onDeleteClick: (natRule: NatRule) => void;
};

export function NatRulesTable({
  isError,
  isLoading,
  natRules,
  onDeleteClick,
}: NatRulesTableProps): ReactElement | null {
  const { t } = useTranslation();
  const columns = useMemo<Column<NatRule>[]>(
    () => [
      {
        Header: () => t('traffic.natRules.table.headings.type'),
        accessor: ({ target }) => NatRuleTypes[target.type],
        id: 'type',
        width: 50,
      },
      {
        Header: () => t('traffic.natRules.table.headings.target'),
        accessor: ({ target }) => target.name,
        id: 'name',
        minWidth: 150,
      },
      {
        Header: () => t('traffic.natRules.table.headings.ipAddress'),
        accessor: ({ target }) => target.ipAddress,
        id: 'ipAddress',
        width: 50,
      },
      {
        Header: () => t('traffic.natRules.table.headings.eastWestNat'),
        accessor: ({ eastWest }): string =>
          eastWest ? t('common.yes') : t('common.no'),
        id: 'eastWest',
        width: 50,
      },
    ],
    [t],
  );

  const renderActions = (natRule: NatRule): ReactNode => (
    <NatRulesTableActions onDeleteClick={() => onDeleteClick(natRule)} />
  );

  if (isError || isLoading || !natRules) {
    return <TableLoader />;
  }

  return (
    <Table<NatRule> actions={renderActions} data={natRules} columns={columns} />
  );
}
