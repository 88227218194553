import React, { ReactElement } from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { useFetchDemoVms } from '../../../../pages/EditTopology/configuration/VirtualMachines/Add/hooks/use-fetch-demo-vms';
import { InventoryVirtualMachinesTable } from '../InventoryVirtualMachinesTable';

export type DemoVmsTableProps = {
  demoId: string;
};

export function DemoVmsTable({ demoId }: DemoVmsTableProps): ReactElement {
  const { url } = useRouteMatch();
  const { data = [], isFetching } = useFetchDemoVms(demoId);

  if (!demoId) {
    return <Redirect to={url.substring(0, url.lastIndexOf('/'))} />;
  }

  return (
    <InventoryVirtualMachinesTable
      inventoryTemplateVirtualMachines={data}
      loading={isFetching}
    />
  );
}
