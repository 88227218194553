import {
  createAction,
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface NetworksState {
  adding: boolean;
  editing: boolean;
  showAddModal: boolean;
  showEditModal: boolean;
  fetched: boolean;
  loading: boolean;
  networkToDelete?: Network;
  networktoEdit?: Network;
}

const networksAdapter = createEntityAdapter<Network>({
  selectId: ({ uid }) => uid,
});

export const initialState = networksAdapter.getInitialState<NetworksState>({
  adding: false,
  editing: false,
  fetched: false,
  loading: false,
  networktoEdit: undefined,
  showAddModal: false,
  showEditModal: false,
});

export const fetchNetworks = createAction<string>('networks/fetch');
export const updateNetwork = createAction<UpdateNetworkActionPayload>(
  'network/update',
);
export const addNetwork = createAction<NetworkToAdd>('network/addNetwork');
export const setNetworkToEdit = createAction<string>(
  'network/setNetworkToEdit',
);

const slice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchNetworks, (state) => {
      state.fetched = false;
      state.loading = true;
    });
    builder.addCase(addNetwork, (state) => {
      state.adding = true;
    });
    builder.addCase(updateNetwork, (state) => {
      state.editing = true;
    });
  },
  initialState,
  name: 'networks',
  reducers: {
    addNetworkFailure: (state) => {
      state.adding = false;
      state.showAddModal = false;
    },
    addNetworkSuccess: (state, action: PayloadAction<Network>) => {
      state.adding = false;
      state.showAddModal = false;
      networksAdapter.addOne(state, action.payload);
    },
    clearNetworks: (state) => {
      state.loading = false;
      state.fetched = false;
      networksAdapter.removeAll(state);
    },
    deleteNetwork: (state, { payload }: PayloadAction<Network>) => {
      state.networkToDelete = undefined;
      networksAdapter.removeOne(state, payload.uid);
    },
    deleteNetworkFailure: (state, { payload }: PayloadAction<Network>) => {
      state.networkToDelete = undefined;
      networksAdapter.addOne(state, payload);
    },
    fetchNetworkFailure: (state) => {
      state.networktoEdit = undefined;
      state.showEditModal = false;
    },
    fetchNetworkSuccess: (state, action: PayloadAction<Network>) => {
      state.networktoEdit = action.payload;
      state.showEditModal = true;
    },
    fetchNetworksSuccess: (state, action: PayloadAction<Network[]>) => {
      state.loading = false;
      state.fetched = true;
      networksAdapter.setAll(state, action.payload);
    },
    reset: () => initialState,
    setFetched: (state, action: PayloadAction<boolean>) => {
      state.fetched = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setNetwork: (
      state,
      action: PayloadAction<UpdateNetworkSuccessActionPayload>,
    ) => {
      state.editing = false;
      state.showEditModal = false;
      state.networktoEdit = undefined;
      networksAdapter.updateOne(state, action.payload);
    },
    setNetworkToDelete: (
      state,
      { payload }: PayloadAction<Network | undefined>,
    ) => {
      state.networkToDelete = payload;
    },
    setShowAddModal: (state, action: PayloadAction<boolean>) => {
      state.showAddModal = action.payload;
    },
    setShowEditModal: (state, action: PayloadAction<boolean>) => {
      if (!action.payload) {
        state.networktoEdit = undefined;
      }
      state.showEditModal = action.payload;
    },
  },
});

export const { reducer: networksReducer } = slice;

export const {
  addNetworkFailure,
  addNetworkSuccess,
  clearNetworks,
  deleteNetwork,
  deleteNetworkFailure,
  fetchNetworkFailure,
  fetchNetworkSuccess,
  fetchNetworksSuccess,
  reset,
  setFetched,
  setLoading,
  setNetworkToDelete,
  setShowAddModal,
  setShowEditModal,
  setNetwork,
} = slice.actions;

const getNetworks = (state: RootState) => state.configuration[slice.name];

const getRoutedNetworkInventoryIds = createSelector(
  networksAdapter.getSelectors(getNetworks).selectAll,
  (networks) =>
    networks.map((network) =>
      network.inventoryNetwork.type === 'ROUTED'
        ? network.inventoryNetwork.id
        : null,
    ),
);

const getUnroutedNetworkInventoryIds = createSelector(
  networksAdapter.getSelectors(getNetworks).selectAll,
  (networks) =>
    networks.map((network) =>
      network.inventoryNetwork.type === 'UNROUTED'
        ? network.inventoryNetwork.id
        : null,
    ),
);

const getNetworkNames = createSelector(
  networksAdapter.getSelectors(getNetworks).selectAll,
  (networks) => networks.map((network) => network.name),
);

const getIsLastRouted = createSelector(
  networksAdapter.getSelectors(getNetworks).selectAll,
  (networks) =>
    networks.filter((network) => network.inventoryNetwork.type === 'ROUTED')
      .length === 1,
);

export const networksSelectors = {
  ...networksAdapter.getSelectors<RootState>(
    (state) => state.configuration.networks,
  ),
  getAdding: createSelector(getNetworks, ({ adding }) => adding),
  getEditing: createSelector(getNetworks, ({ editing }) => editing),
  getFetched: createSelector(getNetworks, ({ fetched }) => fetched),
  getIsLastRouted,
  getLoading: createSelector(getNetworks, ({ loading }) => loading),
  getNetworkNames,
  getNetworkToDelete: createSelector(
    getNetworks,
    (state) => state.networkToDelete,
  ),
  getNetworkToEdit: createSelector(
    getNetworks,
    ({ networktoEdit }) => networktoEdit,
  ),
  getRoutedNetworkInventoryIds,
  getShowAddModal: createSelector(
    getNetworks,
    ({ showAddModal }) => showAddModal,
  ),
  getShowEditModal: createSelector(
    getNetworks,
    ({ showEditModal }) => showEditModal,
  ),
  getUnroutedNetworkInventoryIds,
};
