import React, { ReactElement, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { ConnectedInventoryLicencesModal } from '../../../../entities/licences/components/InventoryLicencesModal';
import { LicenceDeleteModal } from '../../../../entities/licences/components/LicenceDeleteModal';
import { ConnectedEditLicenceModal } from '../../../../entities/licences/components/EditLicenceModal/container';
import { PageHeadingWithCTA } from '../../components/PageHeadingWithCTA/PageHeadingWithCTA';
import { LicencesContent } from './LicencesContent';
import { LicencesProps } from './container';

export function Licences({
  deleteLicence,
  fetched,
  loading,
  licences,
  fetchLicences,
  reset,
  setLicenceToEdit,
  setShowInventoryModal,
  showInventoryModal,
}: LicencesProps): ReactElement {
  const { t } = useTranslation();
  const { uid } = useParams<EditTopologyParams>();
  const [licenceToDelete, setLicenceToDelete] = useState<Licence | undefined>(
    undefined,
  );

  useEffect(() => {
    fetchLicences(uid);

    return () => {
      reset();
    };
  }, [fetchLicences, reset, uid]);

  const handleDeleteLicence = () => {
    licenceToDelete && deleteLicence(licenceToDelete);
    setLicenceToDelete(undefined);
  };

  return (
    <div className="half-padding-left">
      <PageHeadingWithCTA
        buttonLabel={t('buttons.add')}
        buttonAriaLabel="Add Button"
        onButtonClick={() => setShowInventoryModal(true)}
        pageTitle={t('licences.name')}
      />
      <LicencesContent
        setLicenceToDelete={setLicenceToDelete}
        setLicenceToEdit={setLicenceToEdit}
        alertText={t('licences.noLicences.alertText')}
        licences={licences}
        loading={!fetched || loading}
      />
      {showInventoryModal && (
        <ConnectedInventoryLicencesModal
          onClose={() => setShowInventoryModal(false)}
        />
      )}
      {licenceToDelete && (
        <LicenceDeleteModal
          licence={licenceToDelete}
          onClose={() => setLicenceToDelete(undefined)}
          onConfirm={() => handleDeleteLicence()}
        />
      )}
      <ConnectedEditLicenceModal />
    </div>
  );
}
