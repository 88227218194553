import React, { ReactElement, useMemo, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { Steps, Step } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import { CreateTopologyRoutes } from '../../constants';
import { MainRoutes } from '../../../../constants/routes';
import { WizardLabel } from './components/WizardLabel';

interface WizardStep {
  label: string | ReactNode;
  path: CreateTopologyRoutes | MainRoutes.CreateTopology;
  visited?: boolean;
}

export interface WizardStepsProps {
  dataCenter?: DataCenter;
  demoType?: string;
}

export function isStepActive(
  stepPath: string,
  currentPathname: string,
): boolean {
  const path = currentPathname.replace(MainRoutes.CreateTopology, '');
  if (stepPath === MainRoutes.CreateTopology) return true;
  if (stepPath === '/demo-type' && path === '/demo-import') return true;
  return path === stepPath;
}

export function WizardSteps({
  dataCenter,
  demoType,
}: WizardStepsProps): ReactElement {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const steps: WizardStep[] = useMemo(
    () => [
      {
        label: (
          <WizardLabel
            labelColour="tertiary"
            labelText={dataCenter}
            labelTestId="wizard-step-label-data-center"
            text={t('common.dataCenter')}
          />
        ),
        path: MainRoutes.CreateTopology,
        visited:
          pathname ===
            `${MainRoutes.CreateTopology}${CreateTopologyRoutes.DemoType}` ||
          pathname ===
            `${MainRoutes.CreateTopology}${CreateTopologyRoutes.DemoImport}` ||
          pathname ===
            `${MainRoutes.CreateTopology}${CreateTopologyRoutes.Details}`,
      },
      {
        label: (
          <WizardLabel
            labelColour="dark"
            labelText={demoType}
            labelTestId="wizard-step-label-demo-type"
            text={t('common.demo')}
          />
        ),
        path: CreateTopologyRoutes.DemoType,
        visited:
          pathname ===
          `${MainRoutes.CreateTopology}${CreateTopologyRoutes.Details}`,
      },
      {
        label: t('common.details'),
        path: CreateTopologyRoutes.Details,
      },
    ],
    [pathname, t, dataCenter, demoType],
  );

  return (
    <Steps>
      {steps.map((step, i) => (
        <Step
          key={`wizard-step-${i}`}
          active={isStepActive(step.path, pathname)}
          label={step.label}
          visited={step.visited}
        />
      ))}
    </Steps>
  );
}
