import { Button, Icon } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import styles from './NetworkingTableActions.module.css';

export interface NetworkingTableActionsProps {
  onDeleteClick: VoidFunction;
}

export function NetworkingTableActions({
  onDeleteClick,
}: NetworkingTableActionsProps): ReactElement {
  return (
    <ul aria-label="Card actions" className={styles.root}>
      <li className={styles.action}>
        <Button
          colour="link"
          type="button"
          onClick={onDeleteClick}
          testId="vm-edit-networking-table-actions-delete"
        >
          <Icon name="delete" />
        </Button>
      </li>
    </ul>
  );
}
