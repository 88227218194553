import { SelectOption } from 'dcloud-shared-ui/dist/components/Select';
import React, { ReactElement } from 'react';
import { Control, SubmitHandler, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../../../../components/Buttons/PrimaryButton';
import {
  ControlledSelect,
  ControlledTextfield,
} from '../../../../../../components/Form';
import { FormWrapper } from '../../../../../../components/FormWrapper';
import styles from './ExternalDnsAddForm.module.css';

interface ExternalDnsAddFormProps {
  control: Control<ExternalDnsFormData>;
  errors: UseFormMethods['errors'];
  formState: UseFormMethods['formState'];
  handleSubmit: UseFormMethods['handleSubmit'];
  assetOptions: SelectOption<string>[];
  natRuleOptions: SelectOption<string>[];
  isCreating: boolean;
  handleCreate: SubmitHandler<ExternalDnsFormData>;
}

export function ExternalDnsAddForm({
  control,
  errors,
  isCreating,
  formState,
  handleCreate,
  handleSubmit,
  assetOptions,
  natRuleOptions,
}: ExternalDnsAddFormProps): ReactElement | null {
  const { t } = useTranslation();
  const { isDirty, isValid } = formState;

  return (
    <FormWrapper
      aria-label="Add External DNS form"
      className={styles.root}
      onSubmit={handleSubmit(handleCreate)}
    >
      <ControlledSelect
        control={control}
        label={t('traffic.externalDns.table.headings.nat')}
        name="natRule"
        required={true}
        error={errors?.natRule?.message}
        className={styles.natTarget}
        options={natRuleOptions}
      />
      <ControlledSelect
        control={control}
        label={t('traffic.externalDns.table.headings.asset')}
        name="asset"
        error={errors?.asset?.message}
        options={assetOptions}
        className={styles.asset}
      />
      <ControlledTextfield
        control={control}
        label={t('traffic.externalDns.table.headings.headings.hostname')}
        name="hostname"
        required={true}
        error={errors?.hostname?.message}
        className={styles.hostname}
        isNumber={true}
      />
      <PrimaryButton
        disabled={!isDirty || !isValid}
        loading={isCreating}
        type="submit"
      >
        {t('traffic.externalDns.addButton')}
      </PrimaryButton>
    </FormWrapper>
  );
}
