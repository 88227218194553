import { call, put } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import { fetchTopologies } from '../../../api/topologies';
import { fetchTopologiesError, fetchTopologiesSuccess } from '../slice';

export function* fetchTopologiesWorker(): SagaIterator {
  try {
    const response = yield call(fetchTopologies);
    yield put(fetchTopologiesSuccess(response));
  } catch (e) {
    yield put(fetchTopologiesError());
  }
}
