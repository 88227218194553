import React, { ReactElement } from 'react';
import {
  PushActionPushButton,
  PushActionUpdateButton,
} from './components/TopologiesPushActions';

export const determinePushAction = (
  topology: Topology,
  onPush: VoidFunction,
  onUpdate: VoidFunction,
): ReactElement | null =>
  'push' in topology._links ? (
    <PushActionPushButton onClick={onPush} />
  ) : 'updatePush' in topology._links ? (
    <PushActionUpdateButton onClick={onUpdate} />
  ) : null;

export const addOnPushActionToTopologies = (
  topologies: Topology[],
  onPush: TopologyAction,
  onUpdate: TopologyAction,
): TopologyWithOnPushActions[] => {
  return topologies.map((topology) => ({
    ...topology,
    onPush: () => onPush(topology),
    onUpdate: () => onUpdate(topology),
  }));
};

export const isTopologyInSyncWithDCloud = (topology: Topology): boolean =>
  !('push' in topology._links || 'updatePush' in topology._links);
