import React, { ReactElement, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { Table } from '../../../../../../../../../components';
import { NetworkingTableActions } from '../NetworkingTableActions';

type NetworkingTableProps = {
  vmNetworkInterfaces: VmNetworkingToEdit;
  onDeleteClick: (vmNetworkInterfaceToEdit: VmNetworkInterfaceToEdit) => void;
  isLoading: boolean;
  nicTypes: VmNetworkInterfaceType[];
};

export function NetworkingTable({
  vmNetworkInterfaces,
  onDeleteClick,
  isLoading,
  nicTypes,
}: NetworkingTableProps): ReactElement | null {
  const { t } = useTranslation();

  const columns = useMemo<Column<VmNetworkInterfaceToEdit>[]>(
    () => [
      {
        Header: () => t('virtualMachines.edit.networking.network'),
        accessor: ({ network }) => network.name,
        id: 'name',
        minWidth: 150,
      },
      {
        Header: () => t('virtualMachines.edit.networking.type'),
        accessor: ({ type }) =>
          nicTypes.find((nicType) => nicType.id === type)?.name,
        id: 'type',
        minWidth: 150,
      },

      {
        Header: () => t('virtualMachines.edit.networking.macAddress'),
        accessor: 'macAddress',
        minWidth: 150,
      },
      {
        Header: () => t('virtualMachines.edit.networking.ipAddress'),
        accessor: 'ipAddress',
        minWidth: 150,
      },
    ],
    [nicTypes, t],
  );

  const renderActions = (
    vmNetworkInterfaceToEdit: VmNetworkInterfaceToEdit,
  ): ReactNode => (
    <NetworkingTableActions
      onDeleteClick={() => {
        onDeleteClick(vmNetworkInterfaceToEdit);
      }}
    />
  );

  return (
    <Table<VmNetworkInterfaceToEdit>
      actions={renderActions}
      data={vmNetworkInterfaces}
      columns={columns}
      loading={isLoading}
    />
  );
}
