import { dcloudApi, TAGS } from '../../api';
import { TOPOLOGIES_PATH } from '../../../api/paths';
import { addErrorToast, addSuccessToast } from '../../toast/slice';
import i18n from '../../../i18n';
import { setEtag } from '../etag/slice';
import { setTopologyToEdit } from '../../topologies/slice';

export const topologyApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    fetchTopology: build.query<Topology, string>({
      keepUnusedDataFor: 0,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data, meta } = await queryFulfilled;
          dispatch(setTopologyToEdit(data));
          const etag = meta?.response.headers.get('etag');
          etag && dispatch(setEtag(etag));
        } catch (err) {
          const message = err.error.data
            ? err.error.data[0].message
            : i18n.t('general.fetch.error');
          dispatch(addErrorToast({ message }));
        }
      },
      providesTags: [TAGS.GENERAL],
      query: (topologyUid) => ({
        url: `${TOPOLOGIES_PATH}/${topologyUid}`,
      }),
    }),
    updateTopology: build.mutation<Topology, TopologyUpdatePayload>({
      invalidatesTags: [TAGS.GENERAL],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addSuccessToast({ message: i18n.t('general.update.success') }),
          );
        } catch (err) {
          const message = err.error.data
            ? err.error.data[0].message
            : i18n.t('general.update.error');
          dispatch(addErrorToast({ message }));
        }
      },
      query: ({ etag, topology }) => ({
        body: topology,
        headers: { 'If-Match': etag },
        method: 'PUT',
        url: `${TOPOLOGIES_PATH}/${topology.uid}`,
      }),
    }),
  }),
});

export const { useFetchTopologyQuery, useUpdateTopologyMutation } = topologyApi;
