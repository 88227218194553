import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { Footer } from 'dcloud-shared-ui';
import { useScrollToTop } from '../../hooks/use-scroll-to-top';
import { ConnectedEditHeader } from '../../components/Headers/EditHeader/container';
import { EditTopologyRouter, EditTopologyRoutes } from './EditTopologyRouter';
import styles from './EditTopology.module.css';

export function EditTopology(): ReactElement {
  useScrollToTop();
  const { pathname } = useLocation();
  const shouldShowFooter = !pathname.includes(EditTopologyRoutes.Diagram);

  return (
    <>
      <ConnectedEditHeader />
      <EditTopologyRouter />
      {shouldShowFooter && <Footer className={styles.footer} />}
    </>
  );
}
