import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '../../store';

const dataCentersAdapter = createEntityAdapter<DataCenterFromApi>({
  selectId: ({ id }) => id,
});

export const initialState = dataCentersAdapter.getInitialState({
  fetched: false,
  loading: false,
});

const slice = createSlice({
  initialState,
  name: 'dataCenters',
  reducers: {
    fetchDataCenters: (state) => {
      state.loading = true;
    },
    fetchDataCentersSuccess: (
      state,
      action: PayloadAction<DataCenterFromApi[]>,
    ) => {
      state.fetched = true;
      state.loading = false;
      dataCentersAdapter.setAll(state, action.payload);
    },
  },
});

const getDataCenters = (state: RootState) => state.entities[slice.name];

export const { reducer: dataCentersReducer } = slice;
export const { fetchDataCenters, fetchDataCentersSuccess } = slice.actions;

export const dataCentersSelectors = {
  ...dataCentersAdapter.getSelectors(getDataCenters),
  getDataCentersFetched: createSelector(
    getDataCenters,
    ({ fetched }) => fetched,
  ),
  getDataCentersLoading: createSelector(
    getDataCenters,
    ({ loading }) => loading,
  ),
};
