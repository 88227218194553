import { Alert } from 'dcloud-shared-ui';
import React, { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeading } from '../../../../../components/PageHeading';
import { NatRuleDeleteModal } from './components/NatRuleDeleteModal';
import { NatRulesAddFormContainer } from './components/NatRulesAddForm/container';
import { NatRulesTable } from './components/NatRulesTable';
import { NatRulesContainerProps, NatRulesRTKProps } from './container';

export type NatRulesProps = NatRulesContainerProps & NatRulesRTKProps;

export function NatRules({
  isError,
  isLoading,
  natRuleToDelete,
  natRules,
  setNatRuleToDelete,
}: NatRulesProps): ReactElement | null {
  const { t } = useTranslation();

  const onDeleteClick = useCallback(
    (natRule: NatRule) => {
      setNatRuleToDelete(natRule);
    },
    [setNatRuleToDelete],
  );

  return (
    <section className="half-padding-left">
      <PageHeading pageTitle={t('traffic.natRules.name')} />
      <NatRulesAddFormContainer />
      {!natRules?.length && !isLoading ? (
        <Alert colour="info">{t('traffic.natRules.empty')}</Alert>
      ) : (
        <NatRulesTable
          onDeleteClick={onDeleteClick}
          natRules={natRules || []}
          isError={isError}
          isLoading={isLoading}
        />
      )}
      {natRuleToDelete && (
        <NatRuleDeleteModal
          setNatRuleToDelete={setNatRuleToDelete}
          natRule={natRuleToDelete}
        />
      )}
    </section>
  );
}
