import React, { ReactElement, useMemo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { Table } from '../../../../components/Table';
import { convertMbToGb } from '../../../../pages/EditTopology/configuration/VirtualMachines/Edit/utils';
import { VirtualMachinesTableActions } from './VirtualMachinesTableActions';

export interface VirtualMachinesTableProps {
  loading: boolean;
  virtualMachines: VirtualMachine[];
  onDeleteClick: (virtualMachine: VirtualMachine) => void;
}

export function VirtualMachinesTable({
  loading,
  virtualMachines,
  onDeleteClick,
}: VirtualMachinesTableProps): ReactElement {
  const { t } = useTranslation();

  const columns = useMemo<Column<VirtualMachine>[]>(
    () => [
      {
        Header: () => t('virtualMachines.table.headings.name'),
        accessor: 'name',
        minWidth: 150,
      },
      {
        Cell: ({ value }) =>
          value ? convertMbToGb(value, { roundCeil: false }) : null,
        Header: () => t('virtualMachines.table.headings.memoryGb'),
        accessor: 'memoryMb',
        maxWidth: 50,
      },
      {
        Header: () => t('virtualMachines.table.headings.cpuQty'),
        accessor: 'cpuQty',
        maxWidth: 25,
      },
      {
        Cell: ({ value }) => value.length,
        Header: () => t('virtualMachines.table.headings.nicQty'),
        accessor: 'vmNetworkInterfaces',
        maxWidth: 25,
      },
    ],
    [t],
  );

  const renderActions = (virtualMachine: VirtualMachine): ReactNode => (
    <VirtualMachinesTableActions
      virtualMachine={virtualMachine}
      onDeleteClick={() => onDeleteClick(virtualMachine)}
    />
  );

  return (
    <Table<VirtualMachine>
      actions={renderActions}
      columns={columns}
      loading={loading}
      data={virtualMachines}
      initialState={{ sortBy: [{ desc: false, id: 'name' }] }}
    />
  );
}
