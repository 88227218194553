import { Icon } from 'dcloud-shared-ui';
import React, {
  PropsWithChildren,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import classnames from 'classnames';
import { camelize } from '../../utils/helpers';
import styles from './AccordionRow.module.css';

export type AccordionRowProps = PropsWithChildren<{
  id: string;
  isOpen?: boolean;
  onRowClick: VoidFunction;
  title: string;
}>;

export function AccordionRow({
  id,
  isOpen = false,
  children,
  onRowClick,
  title,
}: AccordionRowProps): ReactElement {
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);
  const controlsId = camelize(title);

  useEffect(() => {
    const height =
      isOpen && contentRef.current ? contentRef.current.scrollHeight : 0;
    setContentHeight(height);
  }, [isOpen]);

  return (
    <div
      className={classnames(styles.root, 'half-margin-bottom', {
        [styles.active]: isOpen,
      })}
    >
      <button
        type="button"
        className={classnames(
          styles.button,
          'text-size-24 text-weight-200 flex flex-between flex-middle',
        )}
        onClick={onRowClick}
        data-test="accordion-row"
        aria-expanded={isOpen ? 'true' : 'false'}
        aria-controls={controlsId}
        id={id}
      >
        {title}
        <Icon
          name={isOpen ? 'chevron-up' : 'chevron-down'}
          className="text-size-20"
        />
      </button>
      <div
        ref={contentRef}
        style={
          isOpen
            ? { height: 'auto', minHeight: `${contentHeight}px` }
            : { height: '0' }
        }
        className={styles.content}
        role="region"
        id={controlsId}
        aria-labelledby={id}
      >
        <div className={styles.childrenWrap}>{children}</div>
      </div>
    </div>
  );
}
