import React, { ReactElement } from 'react';
import { ConnectedToastsContainer } from './components/ToastsContainer/container';
import { MainRouter } from './routers/MainRouter';

export function App(): ReactElement {
  return (
    <>
      <MainRouter />
      <ConnectedToastsContainer />
    </>
  );
}
