import { combineReducers } from 'redux';
import { dataCentersReducer } from './data-centers/slice';
import { inventoryLicencesReducer } from './inventory-licences/slice';
import { inventoryNetworksReducer } from './inventory-networks/slice';
import { nicTypesReducer } from './nic-types/slice';
import { operatingSystemsReducer } from './operating-systems/slice';

export const entitiesReducer = combineReducers({
  dataCenters: dataCentersReducer,
  inventoryLicences: inventoryLicencesReducer,
  inventoryNetworks: inventoryNetworksReducer,
  nicTypes: nicTypesReducer,
  operatingSystems: operatingSystemsReducer,
});
