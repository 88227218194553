import {
  createEntityAdapter,
  createAction,
  createSlice,
  PayloadAction,
  EntityState,
  createSelector,
} from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface LicencesState {
  adding: boolean;
  fetched: boolean;
  showEditModal: boolean;
  licenceToEdit?: Licence;
  loading: boolean;
  showInventoryModal: boolean;
  updating: boolean;
}

const licencesAdapter = createEntityAdapter<Licence>({
  selectId: ({ uid }) => uid,
});

export const initialState = licencesAdapter.getInitialState<LicencesState>({
  adding: false,
  fetched: false,
  loading: false,
  showEditModal: false,
  showInventoryModal: false,
  updating: false,
});

export const addLicences = createAction<InventoryLicenceToAdd[]>(
  'licences/add',
);
export const editLicence = createAction<EditLicenceFields>('licence/edit');
export const fetchLicences = createAction<string>('licences/fetch');
export const setLicenceToEdit = createAction<string>('licence/fetch');

const slice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(setLicenceToEdit, (state) => {
      state.showEditModal = true;
    });
    builder.addCase(fetchLicences, (state) => {
      state.loading = true;
      state.fetched = false;
    });
    builder.addCase(addLicences, (state) => {
      state.adding = true;
    });
    builder.addCase(editLicence, (state) => {
      state.updating = true;
    });
  },
  initialState,
  name: 'licences',
  reducers: {
    addLicenceFailure: (state) => {
      state.adding = false;
    },
    addLicenceSuccess: (state, action: PayloadAction<Licence>) => {
      state.adding = false;
      licencesAdapter.addOne(state, action.payload);
    },
    closeEditModal: (state) => {
      state.licenceToEdit = undefined;
      state.showEditModal = false;
    },
    deleteLicence: (state, action: PayloadAction<Licence>) => {
      licencesAdapter.removeOne(state, action.payload.uid);
    },
    deleteLicenceFailure: licencesAdapter.addOne,
    editLicenceFailure: (state) => {
      state.licenceToEdit = undefined;
      state.showEditModal = false;
      state.updating = false;
    },
    editLicenceSuccess: (state, { payload }: PayloadAction<Licence>) => {
      state.updating = false;
      licencesAdapter.updateOne(state, {
        changes: { quantity: payload.quantity },
        id: payload.uid,
      });
      state.licenceToEdit = undefined;
    },
    fetchLicenceFailure: (state) => {
      state.showEditModal = false;
      state.licenceToEdit = undefined;
    },
    fetchLicenceSuccess: (state, { payload }: PayloadAction<Licence>) => {
      state.licenceToEdit = payload;
    },
    fetchLicencesSuccess: (state, action: PayloadAction<Licence[]>) => {
      state.loading = false;
      state.fetched = true;
      licencesAdapter.setAll(state, action.payload);
    },
    reset: () => initialState,
    setShowInventoryModal: (state, action: PayloadAction<boolean>) => {
      state.showInventoryModal = action.payload;
    },
  },
});

export const getLicences = (
  state: RootState,
): EntityState<Licence> & typeof initialState =>
  state.configuration[slice.name];

export const { reducer: licencesReducer } = slice;
export const {
  addLicenceFailure,
  addLicenceSuccess,
  closeEditModal,
  deleteLicence,
  deleteLicenceFailure,
  editLicenceFailure,
  editLicenceSuccess,
  fetchLicenceFailure,
  fetchLicenceSuccess,
  fetchLicencesSuccess,
  reset,
  setShowInventoryModal,
} = slice.actions;

const getLicenceInventoryIds = createSelector(
  licencesAdapter.getSelectors(getLicences).selectAll,
  (licences) => licences.map((licence) => licence.inventoryLicense.id),
);

export const licencesSelectors = {
  ...licencesAdapter.getSelectors(getLicences),
  getIsUpdating: createSelector(getLicences, ({ updating }) => updating),
  getLicenceInventoryIds,
  getLicenceToEdit: createSelector(
    getLicences,
    ({ licenceToEdit }) => licenceToEdit,
  ),
};
