import { SelectOption } from 'dcloud-shared-ui';
import { PLEASE_SELECT_OPTION } from '../../../../../../../constants/form';
import { NetworkingFormData } from './components/NetworkingForm';

export function getNicTypeOptions(
  nicTypes: VmNetworkInterfaceType[],
): SelectOption<string>[] {
  return [
    PLEASE_SELECT_OPTION,
    ...nicTypes.map((nicType) => ({
      name: nicType.name,
      value: nicType.id,
    })),
  ];
}

export function findNetworkName(
  networks: Network[],
  formData: NetworkingFormData,
): string | undefined {
  return networks.find((network) => formData.networkName === network.uid)?.name;
}
