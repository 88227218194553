import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  useGetRemoteAccessQuery,
  useUpdateRemoteAccessMutation,
} from '../../../../redux/configuration/remote-access/api';
import { getEtag } from '../../../../redux/configuration/etag/selectors';
import { RootState } from '../../../../redux/store';
import { RemoteAccessLoader } from './RemoteAccessLoader';
import { RemoteAccess } from '.';

export function RemoteAccessContainer({
  etag,
}: RemoteAccessContainerProps): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();

  const { data, isFetching, isError } = useGetRemoteAccessQuery(topologyUid, {
    refetchOnFocus: false,
  });

  const [
    updateRemoteAccess,
    { isLoading: isUpdating },
  ] = useUpdateRemoteAccessMutation();

  if (!data) {
    return <RemoteAccessLoader />;
  }

  return (
    <RemoteAccess
      data={data}
      etag={etag}
      isError={isError}
      isFetching={isFetching}
      isUpdating={isUpdating}
      updateRemoteAccess={updateRemoteAccess}
    />
  );
}

type RemoteAccessContainerProps = ReturnType<typeof mapStateToProps>;

interface RemoteAccessRTKProps {
  data?: RemoteAccess;
  isFetching: boolean;
  isError: boolean;
  isUpdating: boolean;
  updateRemoteAccess: RTKMutation<RemoteAccessPayload, RemoteAccess>;
}

export type RemoteAccessProps = RemoteAccessContainerProps &
  RemoteAccessRTKProps;

const mapStateToProps = (state: RootState) => ({
  etag: getEtag(state),
});

export const ConnectedRemoteAccess = connect(mapStateToProps)(
  RemoteAccessContainer,
);
