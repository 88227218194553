import { connect } from 'react-redux';
import { RootState } from '../../redux/store';
import { getToasts } from '../../redux/toast/slice';
import { ToastsContainer } from '.';

export type ToastsContainerReduxProps = ReturnType<typeof mapStateToProps>;

const mapStateToProps = (state: RootState) => ({
  toasts: getToasts(state),
});

export const ConnectedToastsContainer = connect(mapStateToProps)(
  ToastsContainer,
);
