import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'dcloud-shared-ui';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { cloneTopologyFieldsSchema } from '../../../../../validation';
import { SubmitButton } from '../../../../../components/Buttons';
import {
  ControlledTextfield,
  ControlledTextarea,
} from '../../../../../components';

interface CloneTopologyModalProps {
  isCloning: boolean;
  topologyToClone: Topology;
  onClose: VoidFunction;
  onSubmit: (fields: CloneFields) => void;
}

const I18N_CLONE_PATH = 'topologies.clone.form';

export function CloneTopologyForm({
  isCloning,
  topologyToClone,
  onClose,
  onSubmit,
}: CloneTopologyModalProps): ReactElement {
  const { t } = useTranslation();

  const { handleSubmit, errors, control, formState } = useForm<CloneFields>({
    defaultValues: {
      description: topologyToClone.description,
      name: topologyToClone.name,
    },
    mode: 'all',
    resolver: yupResolver(cloneTopologyFieldsSchema),
  });

  const { isValid } = formState;

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      data-testid={`topology-clone-form-${topologyToClone.uid}`}
    >
      <ControlledTextfield
        control={control}
        error={errors?.name?.message}
        label={t(`${I18N_CLONE_PATH}.name.label`)}
        labelClass="col-3"
        name="name"
        required={true}
      />
      <ControlledTextarea
        control={control}
        error={errors?.description?.message}
        label={t(`${I18N_CLONE_PATH}.description.label`)}
        labelClass="col-3"
        name="description"
        rows={4}
        required={true}
      />
      <div className="flex flex-right">
        <Button
          className="dbl-margin-top"
          colour="ghost"
          size="large"
          onClick={onClose}
          disabled={isCloning}
        >
          {t('buttons.cancel')}
        </Button>
        <SubmitButton
          loading={isCloning}
          colour="success"
          disabled={!isValid || isCloning}
          className="dbl-margin-top"
        >
          {t('buttons.create')}
        </SubmitButton>
      </div>
    </form>
  );
}
