import { SelectOption } from 'dcloud-shared-ui';
import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { Control, SubmitHandler, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledSelect } from '../../../../../../../components';
import { PrimaryButton } from '../../../../../../../components/Buttons/PrimaryButton';
import { FormWrapper } from '../../../../../../../components/FormWrapper';
import { buildIPAddressOptionsNicTarget } from '../../utils';
import styles from './MailServerAddForm.module.css';

interface MailServerAddFormProps {
  control: Control<MailServerFormData>;
  errors: UseFormMethods['errors'];
  formState: UseFormMethods['formState'];
  handleSubmit: UseFormMethods['handleSubmit'];
  vmOptions: SelectOption<string>[];
  assetOptions: SelectOption<string>[];
  ipAddressOptions: SelectOption<string>[];
  isCreating: boolean;
  vmTargets: VmNicTarget[];
  handleCreate: SubmitHandler<MailServerFormData>;
  setVmIpAddressOptions: Dispatch<SetStateAction<SelectOption<string>[]>>;
}

export function MailServerAddForm({
  vmOptions,
  assetOptions,
  control,
  vmTargets,
  errors,
  ipAddressOptions,
  isCreating,
  formState,
  handleCreate,
  setVmIpAddressOptions,
  handleSubmit,
}: MailServerAddFormProps): ReactElement | null {
  const { t } = useTranslation();
  const { isDirty, isValid } = formState;

  return (
    <FormWrapper
      aria-label="Add Mail Server form"
      className={styles.root}
      onSubmit={handleSubmit(handleCreate)}
    >
      <ControlledSelect
        control={control}
        options={vmOptions}
        label={t('traffic.mailServer.table.headings.virtualMachine')}
        name="virtualMachine"
        required={true}
        error={errors?.virtualMachine?.message}
        className={styles.vmInput}
        customOnChange={({ target }) => {
          control.setValue('virtualMachine', '');
          setVmIpAddressOptions(
            buildIPAddressOptionsNicTarget(target.value, vmTargets),
          );
        }}
      />
      <ControlledSelect
        control={control}
        options={ipAddressOptions}
        label={t('traffic.mailServer.table.headings.ipAddress')}
        name="ipAddress"
        required={true}
        error={errors?.ipAddress?.message}
        className={styles.ipAddressInput}
      />
      <ControlledSelect
        control={control}
        options={assetOptions}
        label={t('traffic.mailServer.table.headings.asset')}
        name="asset"
        error={errors?.asset?.message}
        className={styles.assetInput}
      />
      <PrimaryButton
        disabled={!isDirty || !isValid}
        loading={isCreating}
        type="submit"
      >
        {t('buttons.add')}
      </PrimaryButton>
    </FormWrapper>
  );
}
