import { AxiosPromise } from 'axios';
import { api } from '../../api';
import { stripGzipFromEtag } from '../utils';
import {
  INVENTORIES_PATH,
  INVENTORY_LICENCES_PATH,
  LICENCES_PATH,
  TOPOLOGIES_PATH,
} from '../paths';

export async function fetchLicence(
  licenceUid: string,
): Promise<ApiDataResponse<Licence>> {
  const { data, headers } = await api.get<Licence>(
    `${LICENCES_PATH}/${licenceUid}`,
  );

  return { data, etag: stripGzipFromEtag(headers.etag) };
}

export async function fetchLicences(topologyUid: string): Promise<Licence[]> {
  const { data } = await api.get<FetchLicencesResponse>(
    `${TOPOLOGIES_PATH}/${topologyUid}${LICENCES_PATH}`,
  );

  return data._embedded.licenses;
}

export async function fetchInventoryLicences(
  datacenter: string,
): Promise<InventoryLicence[]> {
  const { data } = await api.get<FetchInventoryLicencesResponse>(
    `${INVENTORIES_PATH}/${datacenter}${INVENTORY_LICENCES_PATH}`,
  );

  return data._embedded.inventoryLicenses;
}

export async function addLicence(
  payload: InventoryLicenceToAdd,
): Promise<ApiDataResponse<Licence>> {
  const { data, headers } = await api.post<Licence>(LICENCES_PATH, payload);

  return { data, etag: stripGzipFromEtag(headers.etag) };
}

export const deleteLicence = (uid: LicenceUid): AxiosPromise<void> =>
  api.delete(`${LICENCES_PATH}/${uid}`);

export async function editLicence(
  licence: Omit<Licence, '_links'>,
  etag: string,
): Promise<ApiDataResponse<Licence>> {
  const { data, headers } = await api.put<Licence>(
    `${LICENCES_PATH}/${licence.uid}`,
    licence,
    {
      headers: {
        'If-Match': etag,
      },
    },
  );

  return { data, etag: stripGzipFromEtag(headers.etag) };
}
