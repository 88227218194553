import { SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { configurationWatcher } from './configuration/sagas';
import { dataCentersWatchers } from './entities/data-centers/sagas';
import { inventoryLicencesWatchers } from './entities/inventory-licences/sagas';
import { fetchInventoryNetworksWatcher } from './entities/inventory-networks/sagas/fetch-inventory-networks-worker';
import { topologiesWorker } from './topologies/sagas';

export function* rootSaga(): SagaIterator {
  yield all([
    yield fork(configurationWatcher),
    yield fork(dataCentersWatchers),
    yield fork(inventoryLicencesWatchers),
    yield fork(fetchInventoryNetworksWatcher),
    yield fork(topologiesWorker),
  ]);
}
