import { dcloudApi, dcloudApiAxios, TAGS } from '../../api';
import {
  INVENTORY_DEMO_VMS_PATH,
  INVENTORY_VIRTUAL_MACHINES_DEMOS_PATH,
  INVENTORY_VIRTUAL_MACHINES_TEMPLATES_PATH,
  TOPOLOGIES_PATH,
  VMS_PATH,
  VM_NETWORK_INTERFACE_TYPE,
} from '../../../api/paths';
import { addToast } from '../../toast/slice';
import i18n from '../../../i18n';
import { setNicTypes } from '../../entities/nic-types/slice';

type GetDemoVmsProps = {
  demoId: string;
  topologyUid: string;
};

type PostVmProps = {
  payload: VmToCreate;
};

export const virtualMachinesAxiosApi = dcloudApiAxios.injectEndpoints({
  endpoints: (build) => ({
    getVm: build.query<VirtualMachine, string>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.VIRTUAL_MACHINE],
      query: (vmUid) => ({
        errorMessage: i18n.t('virtualMachines.fetch.errors.one'),
        method: 'get',
        url: `${VMS_PATH}/${vmUid}`,
      }),
    }),

    getVmNetworkInterfaceTypes: build.query<
      VmNetworkInterfaceType[],
      undefined
    >({
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setNicTypes(data));
        } catch (err) {
          return;
        }
      },
      providesTags: [TAGS.VIRTUAL_MACHINES_NETWORK_INTERFACE_TYPES],
      query: () => ({
        errorMessage: i18n.t(
          'virtualMachines.edit.networkInterfaceTypes.error',
        ),
        method: 'get',
        url: VM_NETWORK_INTERFACE_TYPE,
      }),
      transformResponse: (response: FetchVmNetworkInterfaceTypesResponse) =>
        response.vmNetworkInterfaceTypes,
    }),
    getVmsAxios: build.query<VirtualMachine[], string>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.VIRTUAL_MACHINES],
      query: (topologyUid) => ({
        errorMessage: i18n.t('virtualMachines.fetch.errors.many'),
        method: 'get',
        url: `${TOPOLOGIES_PATH}/${topologyUid}/${VMS_PATH}`,
      }),
      transformResponse: ({ vms }: FetchVirtualMachinesAxiosResponse) => vms,
    }),
    updateVm: build.mutation<VirtualMachine, VmToEdit>({
      invalidatesTags: [TAGS.VIRTUAL_MACHINE],
      query: (vm) => ({
        data: vm,
        errorMessage: i18n.t('virtualMachines.edit.error'),
        method: 'put',
        successMessage: i18n.t('virtualMachines.edit.success'),
        url: `${VMS_PATH}/${vm.uid}`,
      }),
    }),
  }),
});

export const virtualMachinesApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    createVm: build.mutation<VirtualMachine, PostVmProps>({
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addToast({
              message: i18n.t('virtualMachines.add.success'),
              title: i18n.t('common.success'),
              type: 'success',
            }),
          );
        } catch (err) {
          dispatch(
            addToast({
              message: i18n.t('virtualMachines.add.error'),
              title: i18n.t('common.error'),
              type: 'error',
            }),
          );
        }
      },
      query: ({ payload }) => ({
        body: payload,
        method: 'POST',
        url: `${VMS_PATH}`,
      }),
    }),
    deleteVM: build.mutation<VirtualMachine, string>({
      invalidatesTags: [TAGS.VIRTUAL_MACHINES],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addToast({
              message: i18n.t('virtualMachines.delete.success'),
              title: i18n.t('common.success'),
              type: 'success',
            }),
          );
        } catch (err) {
          dispatch(
            addToast({
              message: i18n.t('virtualMachines.delete.error'),
              title: i18n.t('common.error'),
              type: 'error',
            }),
          );
        }
      },
      query: (id) => ({
        method: 'DELETE',
        url: `${VMS_PATH}/${id}`,
      }),
    }),
    getDemoVms: build.query<InventoryVirtualMachine[], GetDemoVmsProps>({
      providesTags: [TAGS.VIRTUAL_MACHINES_TEMPLATES_INVENTORY],
      query: ({ demoId, topologyUid }) => ({
        url: `${TOPOLOGIES_PATH}/${topologyUid}${INVENTORY_VIRTUAL_MACHINES_DEMOS_PATH}/${demoId}${INVENTORY_DEMO_VMS_PATH}`,
      }),
      transformResponse: (response: FetchInventoryDemoVmsResponse) =>
        response._embedded.inventoryVms,
    }),
    getVMDemosInventory: build.query<InventoryDemo[], string>({
      providesTags: [TAGS.VIRTUAL_MACHINES_TEMPLATES_INVENTORY],
      query: (topologyUid) => ({
        url: `${TOPOLOGIES_PATH}/${topologyUid}${INVENTORY_VIRTUAL_MACHINES_DEMOS_PATH}`,
      }),
      transformResponse: (response: FetchInventoryDemosResponse) =>
        response._embedded.inventoryDemos,
    }),
    getVMTemplatesInventory: build.query<InventoryVirtualMachine[], string>({
      providesTags: [TAGS.VIRTUAL_MACHINES_TEMPLATES_INVENTORY],
      query: (topologyUid) => ({
        url: `${TOPOLOGIES_PATH}/${topologyUid}${INVENTORY_VIRTUAL_MACHINES_TEMPLATES_PATH}`,
      }),
      transformResponse: (
        response: FetchInventoryTemplateVirtualMachineResponse,
      ) => response._embedded.inventoryVms,
    }),
    getVms: build.query<VirtualMachine[], string>({
      providesTags: [TAGS.VIRTUAL_MACHINES],
      query: (topologyUid) => ({
        url: `${TOPOLOGIES_PATH}/${topologyUid}/${VMS_PATH}`,
      }),
      transformResponse: (response: FetchVirtualMachinesResponse) =>
        response._embedded.vms,
    }),
  }),
});

export const {
  useCreateVmMutation,
  useDeleteVMMutation,
  useGetVmsQuery,
  useGetDemoVmsQuery,
  useGetVMDemosInventoryQuery,
  useGetVMTemplatesInventoryQuery,
} = virtualMachinesApi;

export const {
  useGetVmNetworkInterfaceTypesQuery,
  useGetVmQuery,
  useGetVmsAxiosQuery,
  useUpdateVmMutation,
} = virtualMachinesAxiosApi;
