import { useState } from 'react';

type LicenceDefaultProps = Pick<InventoryLicenceToAdd, 'quantity' | 'topology'>;

export interface UseSelectLicences {
  topologyUid: string;
}

export interface UseSelectLicencesProps {
  handleOnSelect: (isSelected: boolean, licence: InventoryLicence) => void;
  handleQuantityChange: (id: string, quantity: string) => void;
  selectedLicences: InventoryLicenceToAdd[];
}

export function useSelectLicences({
  topologyUid,
}: UseSelectLicences): UseSelectLicencesProps {
  const [selected, setSelected] = useState<InventoryLicenceToAdd[]>([]);

  const defaultLicenceProps: LicenceDefaultProps = {
    quantity: 1,
    topology: { uid: topologyUid },
  };

  const handleOnSelect: UseSelectLicencesProps['handleOnSelect'] = (
    isSelected,
    licence,
  ) => {
    setSelected((prev) =>
      isSelected
        ? [
            ...prev,
            {
              inventoryLicense: { id: licence.id },
              ...defaultLicenceProps,
            },
          ]
        : prev.filter(({ inventoryLicense: { id } }) => id !== licence.id),
    );
  };

  const handleQuantityChange: UseSelectLicencesProps['handleQuantityChange'] = (
    id,
    quantity,
  ) => {
    setSelected((prev) =>
      prev.map((licence) =>
        licence.inventoryLicense.id === id
          ? { ...licence, quantity: Number(quantity) }
          : licence,
      ),
    );
  };

  return { handleOnSelect, handleQuantityChange, selectedLicences: selected };
}
