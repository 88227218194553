import { SagaIterator } from 'redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, delay, put } from 'redux-saga/effects';
import { addNetwork } from '../../../../api/networks';
import {
  showSuccessToastWorker,
  showErrorToastWorker,
} from '../../../toast/sagas';
import { setEtag } from '../../etag/slice';
import { addNetworkFailure, addNetworkSuccess, fetchNetworks } from '../slice';
import config from '../../../../config';

export function* addNetworkWorker({
  payload,
}: PayloadAction<NetworkToAdd>): SagaIterator {
  try {
    const { data, etag }: AddNetworkResponse = yield call(addNetwork, payload);
    yield delay(config.FLICKER_DELAY);
    yield put(addNetworkSuccess(data));
    yield put(setEtag(etag));
    yield call(showSuccessToastWorker, 'networks.form.add.success');
    yield put(fetchNetworks(data.topology.uid));
  } catch (e) {
    yield put(addNetworkFailure());
    yield call(showErrorToastWorker, 'networks.form.add.error');
  }
}
