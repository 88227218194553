import { connect } from 'react-redux';
import {
  addNetwork,
  networksSelectors,
  updateNetwork,
} from '../../../../redux/configuration/networks/slice';
import { inventoryNetworksSelectors } from '../../../../redux/entities/inventory-networks/slice';
import { RootState } from '../../../../redux/store';
import { EditNetworkForm } from './EditNetworkForm';

export type EditNetworkFormProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps & {
    networkToEdit: Network;
  };

const mapStateToProps = (state: RootState) => ({
  availableRouted: inventoryNetworksSelectors.getAvailableRouted(state),
  availableUnrouted: inventoryNetworksSelectors.getAvailableUnRouted(state),
  editing: networksSelectors.getEditing(state),
  networkNames: networksSelectors.getNetworkNames(state),
});

const mapDispatchToProps = {
  addNetwork,
  updateNetwork,
};

export const ConnectedEditNetworkForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditNetworkForm);
