import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { call, delay, put } from 'redux-saga/effects';
import { fetchLicences as fetchLicencesAPI } from '../../../../api/licences';
import config from '../../../../config';
import { showErrorToastWorker } from '../../../toast/sagas';
import { fetchLicencesSuccess } from '../slice';

export function* fetchLicencesWorker(
  action: PayloadAction<string>,
): SagaIterator {
  try {
    const licences: Licence[] = yield call(fetchLicencesAPI, action.payload);
    yield delay(config.FLICKER_DELAY);
    yield put(fetchLicencesSuccess(licences));
  } catch (e) {
    yield call(showErrorToastWorker, 'licences.fetch.error');
  }
}
