import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageHeading } from '../../../../../components/PageHeading';
import { PrimaryButton } from '../../../../../components/Buttons/PrimaryButton';
import { getEtag } from '../../../../../redux/configuration/etag/selectors';
import { startStopOrderSelectors } from '../../../../../redux/configuration/start-stop-order/slice';
import {
  useGetVmStartOrderQuery,
  useUpdateVmStartOrderMutation,
} from '../../../../../redux/configuration/start-stop-order/vm-start/api';
import { StartStopOrderContent } from '../components/StartStopOrderContent';
import { VMStartTable } from './components/VMStartTable';

export function VMStart(): ReactElement {
  const { t } = useTranslation();
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const {
    isFetching,
    isError,
    refetch,
    isLoading,
  } = useGetVmStartOrderQuery(topologyUid, { refetchOnFocus: false });
  const [
    updateVMStartOrder,
    { isLoading: isUpdating },
  ] = useUpdateVmStartOrderMutation();
  const etag = useSelector(getEtag);
  const isDirty = useSelector(startStopOrderSelectors.getStartStopOrderIsDirty);
  const startStopOrder = useSelector(startStopOrderSelectors.getStartStopOrder);

  return (
    <section className="half-padding-left">
      <PageHeading
        ctaBtn={
          <PrimaryButton
            className="pull-right dbl-margin-top"
            testId="vm-start-order-submit"
            type="submit"
            colour="success"
            onClick={() =>
              updateVMStartOrder({ etag, payload: startStopOrder! })
            }
            disabled={!isDirty}
            loading={isUpdating}
          >
            {t('buttons.update')}
          </PrimaryButton>
        }
        pageTitle={t('startStopOrder.vmStart.name')}
      />
      {!isError && startStopOrder && (
        <StartStopOrderContent
          isFetching={isFetching}
          isLoading={isLoading}
          buttonUnorderedLabel={t('buttons.concurrent')}
          buttonOrderedLabel={t('buttons.sequential')}
          ordered={startStopOrder.ordered}
          positions={startStopOrder.positions}
          alertText={t('startStopOrder.vmStart.table.noResults')}
          table={VMStartTable}
          refetch={refetch}
        />
      )}
    </section>
  );
}
