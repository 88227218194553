import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setVirtualMachineToConfigure } from '../../../../../../redux/configuration/virtual-machines/slice';

export function useClearSelectedOnUnmount(): void {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(setVirtualMachineToConfigure(undefined));
    };
  }, [dispatch]);
}
