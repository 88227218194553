export const DOMAIN_DIVISION = 25;
export const GRID_TICKS = 130;
export const HEADER_OFFSET_VAR = '--cui-header-height';
export const SPACE_DIMENSION = 4000;

export enum Zoom {
  Max = 2.75,
  Min = 0.5,
}

export enum DiagramElementIds {
  Board = 'board',
  Grid = 'grid',
}
