const buttons = {
  add: 'Add',
  back: 'Back',
  cancel: 'Cancel',
  concurrent: 'Concurrent',
  confirm: 'Confirm',
  create: 'Create',
  delete: 'Delete',
  new: 'New',
  ordered: 'Ordered',
  push: 'Push',
  refresh: 'Refresh',
  save: 'Save',
  sequential: 'Sequential',
  submit: 'Submit',
  unordered: 'Unordered',
  update: 'Update',
};

const common = {
  dataCenter: 'Data Center',
  deleting: 'Deleting ...',
  demo: 'Demo',
  demoId: 'Demo ID',
  description: 'Description',
  details: 'Details',
  error: 'Error',
  errorMessage: 'There was an error, please try again.',
  home: 'Home',
  lastUpdated: 'Last Updated',
  name: 'Name',
  no: 'No',
  none: 'None',
  pleaseSelect: 'Please select',
  quantity: 'Quantity',
  search: 'Search',
  status: 'Status',
  success: 'Success',
  topologies: 'Topologies',
  updating: 'Updating ...',
  yes: 'Yes',
};

const demos = {
  fetch: {
    errors: {
      many: 'There was an error fetching demos, please try again.',
    },
  },
  name: 'Demo',
  table: {
    headings: {
      id: 'ID',
      name: 'Name',
      publishedOn: 'Publish Date',
    },
  },
};

const diagram = {
  anyconnect: 'AnyConnect',
  endpointKit: 'Endpoint Kit',
  fetch: {
    error: 'There was an error fetching the diagram, please try again.',
  },
  hideGrid: 'Hide grid',
  showGrid: 'Show grid',
  update: {
    error: 'There was an error updating the diagram, please try again.',
  },
};

const topologies = {
  clone: {
    form: {
      description: {
        label: 'Description',
        validation: {
          required: 'Please enter a Description',
        },
      },
      name: {
        label: 'Name',
        validation: {
          maxLength: 'Enter a Name with 255 characters or less',
          required: 'Please enter a Name',
        },
      },
    },
    heading: 'Create A Copy',
    success: 'Topology cloned successfully.',
  },
  delete: {
    error: 'There was an error deleting the topology, please try again later',
    heading: 'Delete :topology?',
    subHeading: 'This action cannot be undone.',
    success: 'Topology deleted successfully.',
  },
  error: {
    heading: 'Oops',
    subHeading: 'There was an error getting your topologies, please try again',
  },
  filter: {
    empty: 'No Topology matched your search.',
  },
  noTopologies: {
    heading: 'Start Building Demos',
    subHeading: 'Use our Wizard to create your first Topology',
  },
  push: {
    buttonText: 'Save to Custom Content',
    error: 'There was an error saving the topology, please try again.',
    inSyncWithDcloud: 'In Sync with dCloud',
    modalHeading: 'Save to Custom Content?',
    modalSubheading:
      'Are you sure you want to save :topology to your custom content in dCloud?',
    pushButtonText: 'Push to Custom Content',
    pushModalHeading: 'Push to Custom Content?',
    pushUpdatesButtonText: 'Push updates to Custom Content',
    success: 'Topology saved successfully.',
  },
  title: 'My Topologies',
  updatePush: {
    buttonText: 'Push to Custom Content',
    error: 'There was an error updating the topology, please try again.',
    modalHeading: 'Push to Custom Content?',
    modalSubheading:
      'Are you sure you want to push :topology to your custom content in dCloud?',
    pushButtonText: 'Push to Custom Content',
    pushUpdatesButtonText: 'Push updates to Custom Content',
    success: 'Topology updated successfully.',
  },
};

const configuration = {
  header: {
    buttons: {
      config: 'Config',
      diagram: 'Diagram',
    },
    links: {
      home: 'Home',
      topologies: 'Topologies',
    },
  },
};

const createTopology = {
  demo: {
    blank: {
      subTitle: 'Create from scratch',
      title: 'Blank',
    },
    import: {
      demos: {
        id: 'ID',
        name: 'Name',
        publishDate: 'Publish Date',
      },
      subTitle: 'Import TBv2 Content',
      title: 'Import',
    },
  },
  error: 'There was an error creating the topology, please try again',
  success: 'The topology was created successfully',
};

const home = {
  documentation: {
    description:
      'Our detailed technical documentation explains everything you need to know.',
    title: 'Documentation',
  },
  getStarted: 'Get Started',
  heading: 'Introducing dCloud Topology Builder',
  myTopologies: {
    description:
      'Create, edit and manage your content in one place. User friendly, fast and powerful.',
    title: 'My Topologies',
  },
  subHeading: 'Bring your ideas to life with dCloud',
  videos: {
    description:
      'Become an expert in dCloud Topology Builder with our series of step-by-step tutorials',
    title: 'Videos',
  },
};

const hardware = {
  add: {
    error:
      'There was an error adding one or more item(s) of hardware, please try again.',
    success: 'Hardware added successfully.',
    title: 'Add Hardware',
  },
  delete: {
    error: 'There was an error deleting the hardware, please try again.',
    heading: 'Delete :hardware',
    subHeading: 'Are you sure you want to delete this item?',
    success: 'Hardware deleted successfully.',
  },
  edit: {
    enableHardwareConsole: 'Enable Hardware Console',
    enablePowerControl: 'Enable Power Control',
    error: {
      fetchScripts:
        'There was an error fetching inventory scripts, please try again.',
      update: 'There was an error updating the hardware, please try again.',
    },
    inventoryNameLabel: 'Inventory Name',
    nameLabel: 'Name',
    nameMaxLengthError: 'Enter a Name with 255 characters or less',
    nameRequiredError: 'Please provide a unique name',
    networkingInterface: {
      delete: {
        heading: 'Delete',
        subHeading: 'Are you sure you want to delete this item?',
      },
      table: {
        interface: 'Interface',
        network: 'Network',
      },
    },
    success: 'Hardware successfully edited',
    titles: {
      general: 'General',
      networking: 'Networking',
      page: 'Edit Hardware',
      scripts: 'Scripts',
      templates: 'Template Config',
    },
    uniqueNameError: 'Please provide a unique name. This one is taken.',
  },
  empty: {
    alertText: 'No Hardware',
  },
  fetch: {
    error: 'There was an error fetching hardware, please try again.',
  },
  name: 'Hardware',
  table: {
    headings: {
      hardwareConsoleEnabled: 'HW Console Available',
      interfaces: 'Interfaces',
      name: 'Name',
      powerControlEnabled: 'Power Control Available',
    },
  },
};

const virtualMachines = {
  add: {
    error: 'There was an error creating the virtual machine, please try again.',
    success: 'Virtual machine added successfully',
    title: 'Add Virtual Machine',
  },
  configure: {
    table: {
      headings: {
        ipAddress: 'IP address',
        name: 'NIC Name',
        network: 'Network',
      },
    },
    title: 'Configure Network',
  },
  delete: {
    error: 'There was an error deleting the virtual machine, please try again.',
    heading: 'Delete :virtualMachine',
    subHeading: 'Are you sure you want to delete this item?',
    success: 'Virtual machine deleted successfully.',
  },
  edit: {
    advancedSettings: {
      allDisksNonPersistent: 'All Disks are Non-Persistent',
      biosUuid: 'BIOS UUID',
      hiddenFromSession: 'Hide VM from running sessions',
      nameInHypervisor: 'VM Name',
      notStarted: 'Do Not Start Virtual Machine',
    },
    command: 'Command to Execute',
    cpuQty: 'vCPUs',
    delaySecs: 'Delay (sec)',
    description: 'Description',
    enableGuestAutomation: 'Enable Guest Automation',
    error: 'There was an error updating the virtual machine, please try again.',
    formErrors: {
      advancedSettings: {
        biosUuid: {
          patternMatch:
            'Enter a BIOS UUID in the format of 16 hexadecimal pairs, each pair separated by a space, except for pairs 8 and 9 which must be separated by a hyphen, for example "22 29 5e 14 9a a9 98 04-62 ff a4 ae 0e 4c 55 d3"',
          required: 'Please enter a BIOS UUID',
        },
        nameInHypervisor: {
          allowedChars:
            'Please enter a VM Name consisting of alphanumeric, underscore and hyphen characters',
          maxLength: 'Enter a VM Name with 255 characters or less',
          required: 'Please enter a VM Name',
        },
      },
      commandMaxLength: 'Enter a Command with 255 characters or less',
      cpuQty: 'vCPUs must be between 1 and 999',
      delayIncrementsOf5: 'Please enter a value in increments of 5',
      delayMax: 'Please enter a maximum value of 7200',
      delayMin: 'Please enter a minimum value of 0',
      delayTypeError: 'Please enter a number',
      descriptionMaxLength: 'Enter a Description with 255 characters or less',
      descriptionRequired: 'Please enter a Description',
      internalUrls: {
        description: {
          emptyUrlWarning: 'Please enter a description',
          maxLength: 'Enter a Description with 255 characters or less',
        },
        location: {
          emptyDescriptionWarning:
            'Please enter a URL or remove the associated description',
          url: 'Please enter a valid URL',
        },
      },
      memoryGbLimits: 'Memory must be between 1 and 1,024 GB',
      memoryMbLimits: 'Memory must be between 4 and 1,048,576 MB',
      memoryMbMultipleOf4: 'Memory must be a multiple of 4',
      nameMaxLength: 'Enter a Name with 255 characters or less',
      nameRequired: 'Please enter a Name',
      nameUnique: 'Please provide a unique name. This one is taken.',
      networking: {
        ipAddress: 'Please enter a valid IP Address',
        macAddress: {
          patternMatch: 'Please enter a valid MAC Address',
          prefixPatternMatch: 'The MAC Address must begin with 00:50:56',
        },
        networkName: 'Please enter a Network Name',
        type: 'Please choose a NIC Type',
      },
      remoteAccess: {
        adapter: 'Please choose an adapter',
        displayCredentials: {
          passwordMaxLength: 'Enter a Password with 255 characters or less',
          usernameMaxLength: 'Enter a Username with 255 characters or less',
        },
        passwordMaxLength: 'Enter a Password with 255 characters or less',
        usernameMaxLength: 'Enter a Username with 255 characters or less',
      },
    },
    memoryMb: 'Memory',
    name: 'Name',
    nestedHypervisor: 'Nested Hypervisor',
    networkInterfaceTypes: {
      error:
        'There was an error fetching network interface types, please try again.',
    },
    networking: {
      confirmDelete: {
        heading: 'Warning',
        subheading:
          'This NIC has one or more rules associated with it. These can be found in the Traffic section. Deleting this NIC interface will also delete all rules associated with it. Please confirm you wish to delete.',
      },
      empty: {
        alertText: 'No Network Interfaces',
      },
      ipAddress: 'IP Address',
      macAddress: 'MAC Address',
      maxLimit: 'You cannot add more then 10 NICs to a vm',
      minLimit: 'Must have at least one NIC attached to your vm',
      network: 'Network',
      type: 'NIC Type',
    },
    osIcon: 'Operating System Icon',
    remoteAccess: {
      adapterToConnect: 'Adapter to connect',
      autoLogin: 'Enable automatic Login',
      displayCredentials: {
        enableDisplayDifferentCredentials:
          'Display different username and password to user',
        password: 'Display Password',
        username: 'Display Username',
      },
      internalUrls: {
        description1: 'Description 1',
        description2: 'Description 2',
        url1: 'URL 1',
        url2: 'URL 2',
      },
      noValidAdaptersMessage:
        'Web RDP and SSH Access are disabled as no valid adapters are available. Please use the Networking section to add a network interface with an IP Address',
      password: 'Password',
      sshAccess: 'SSH Access',
      username: 'Username',
      vmConsole: 'VM Console',
      webRDP: 'Web RDP',
    },
    sectionTitles: {
      advancedSettings: 'Advanced Settings',
      general: 'Virtual Machine',
      guestAutomation: 'Guest Automation',
      networking: 'Networking',
      remoteAccess: 'Remote Access',
    },
    sessionXmlMessage:
      'Session.xml file will be deposited in the dcloud folder of the VM root drive',
    success: 'Virtual machine updated successfully',
    title: 'Edit Virtual Machine',
  },
  empty: {
    alertText: 'No Virtual Machines',
  },
  fetch: {
    errors: {
      many: 'There was an error fetching virtual machines, please try again.',
      one: 'There was an error fetching the virtual machine, please try again.',
    },
  },
  name: 'Virtual Machines',
  table: {
    headings: {
      cpuQty: 'CPUs',
      memoryGb: 'Memory (GB)',
      name: 'Name',
      nicQty: 'NICs',
    },
  },
  tabs: {
    demo: 'Demo',
    template: 'Template',
  },
};

const inventoryVirtualMachines = {
  fetch: {
    errors: {
      many: 'There was an error fetching virtual machines, please try again.',
    },
  },
  name: 'Template',
  table: {
    headings: {
      cpuQty: 'CPUs',
      description: 'Description',
      memoryGb: 'Memory (GB)',
      name: 'Name',
      networkInterfaces: 'NICs',
    },
  },
};

const sessions = {
  fetch: {
    error: 'There was an error fetching the session, please try again.',
  },
};

const startStopOrder = {
  hardwareStart: {
    fetch: {
      errors: {
        many:
          'There was an error fetching the Hardware Start order, please try again.',
      },
    },
    name: 'Hardware Start',
    table: {
      headings: {
        name: 'Name',
        position: 'Position',
      },
      noResults: 'No Hardware',
    },
    update: {
      error:
        'There was an error updating Hardware start order, please try again.',
      success: 'Hardware start order updated successfully',
    },
  },
  name: 'Start & Stop Order',
  vmStart: {
    fetch: {
      errors: {
        many: 'There was an error fetching VM Start Order, please try again.',
      },
    },
    name: 'VM Start',
    table: {
      headings: {
        delay: 'Delay (seconds)',
        name: 'Name',
      },
      noResults: 'No Virtual Machines',
    },
    update: {
      error: 'There was an error updating VM start order, please try again.',
      success: 'VM start order updated successfully',
    },
  },
  vmStop: {
    fetch: {
      errors: {
        many: 'There was an error fetching VM Stop Order, please try again.',
      },
    },
    name: 'VM Stop',
    table: {
      headings: {
        name: 'Name',
        position: 'Position',
      },
      noResults: 'No Virtual Machines',
    },
    update: {
      error: 'There was an error updating VM stop order, please try again.',
      success: 'VM stop order updated successfully',
    },
  },
};

const remoteAccess = {
  fetch: {
    error: 'There was an error fetching Remote Access, please try again.',
  },
  formLabels: {
    anyconnectEnabled: 'Enable AnyConnect',
    endpointKitEnabled: 'Enable Endpoint Kit',
  },
  name: 'Remote Access',
  update: {
    error: 'There was an error updating Remote Access, please try again.',
    success: 'Remote Access updated successfully',
  },
};

export default {
  buttons,
  common,
  configuration,
  createTopology,
  demos,
  diagram,
  dnsAssets: {
    fetch: {
      error: 'There was an error fetching DNS Assets, please try again.',
    },
  },
  documentation: {
    demoProfileUrl: 'Demo Profile URL',
    documentationUrl: 'Documentation URL',
    error: {
      demoProfileUrl: 'Demo Profile URL must be a valid URL',
      documentationUrl: 'Documentation URL must be a valid URL',
    },
    name: 'Documentation',
    success: 'Documentation updated successfully.',
  },
  errors: {
    general: 'An error occurred, please try again later.',
  },
  general: {
    fetch: {
      error:
        'There was an error fetching General information, please try again.',
    },
    form: {
      description: {
        label: 'Description',
        validation: {
          required: 'Please enter a Description',
        },
      },
      name: {
        label: 'Name',
        validation: {
          maxLength: 'Enter a Name with 255 characters or less',
          required: 'Please enter a Name',
        },
      },
      notes: {
        label: 'Notes',
      },
      save: 'Save',
    },
    name: 'General',
    update: {
      error:
        'There was an error updating General information, please try again.',
      success: 'General fields updated successfully.',
    },
  },
  hardware,
  home,
  inventoryHardware: {
    add: {
      title: 'Add Hardware',
    },
    fetch: {
      error:
        'There was an error fetching the inventory hardware, please try again.',
    },
    table: {
      headings: {
        description: 'Description',
        hardwareConsoleAvailable: 'HW Console Available',
        interfaces: 'Interfaces',
        name: 'Name',
        powerControlAvailable: 'Power Control Available',
      },
    },
  },
  inventoryNetworks: {
    fetch: {
      error:
        'There was an error fetching the inventory networks, please try again.',
    },
  },
  inventoryVirtualMachines,
  licences: {
    add: {
      error: 'There was an error adding the license, please try again.',
      success: 'License added successfully.',
      title: 'Add Licenses',
    },
    delete: {
      error: 'There was an error deleting the license, please try again.',
      heading: 'Delete :licence',
      subHeading: 'Are you sure you want to delete this item?',
      success: 'License deleted successfully.',
    },
    edit: {
      error: 'There was an error updating the license, please try again.',
      success: 'License updated successfully.',
      title: 'Edit License',
    },
    fetch: {
      error: 'There was an error fetching licenses, please try again.',
    },
    name: 'Licenses',
    noLicences: {
      alertText: 'No Licenses',
    },
    table: {
      headings: {
        name: 'Name',
        quantity: 'Quantity',
      },
    },
  },
  networks: {
    delete: {
      error: 'There was an error deleting the network, please try again.',
      heading: 'Delete :network',
      subHeading: 'Are you sure you want to delete this item?',
      success: 'Network deleted successfully.',
    },
    fetch: {
      errors: {
        many: 'There was an error fetching networks, please try again.',
        one: 'There was an error fetching the network, please try again.',
      },
    },
    form: {
      add: {
        error: 'There was an error adding your network, please try again.',
        success: 'Network added successfully.',
        title: 'Add Network',
      },
      description: {
        label: 'Description',
        validation: {
          maxLength: 'Enter a Description with 500 characters or less',
          required: 'Please enter a Description',
        },
      },
      edit: { title: 'Edit Network' },
      maxNumNetworksReached:
        'You have reached the maximum number of Networks for a Topology.',
      maxRoutedInfo:
        'There are already 5 routed networks which is the maximum per topology',
      maxUnroutedInfo:
        'There are already 20 non routed networks which is the maximum per topology',
      minRoutedInfo: 'Must have at least one routed network',
      name: {
        label: 'Name',
        validation: {
          maxLength: 'Enter a Name with 255 characters or less',
          noSpecialCharacters: 'Please enter a Name with no special characters',
          required: 'Please enter a Name',
          unique: 'Please enter a unique Name',
        },
      },
      routedByVpodGw: 'Routed by vPodGW',
      subnet: {
        validation: {
          required: 'Please choose a Subnet',
        },
      },
    },
    name: 'Networks',
    table: {
      defaultNetworkTooltip:
        'This routed network is mandatory and cannot be removed. You can change the name and description only.',
      headings: {
        description: 'Description',
        name: 'Name',
        routed: 'Routed by vPodGW',
        subnet: 'Subnet',
      },
    },
    update: {
      error: 'There was an error updating your network, please try again.',
      success: 'Network updated successfully.',
    },
  },
  operatingSystems: {
    fetch: {
      error: 'There was an error fetching Operating Systems, please try again.',
    },
  },
  remoteAccess,
  sessions,
  siteTitle: 'dCloud Topology Builder',
  startStopOrder,
  topologies,
  traffic: {
    dnsAssets: {
      error:
        'There was an error fetching Inventory DNS Assets, please try again.',
    },
    externalDns: {
      add: {
        error:
          'There was an error creating the External DNS record, please try again.',
        success: 'External DNS record created successfully',
      },
      addButton: 'Add A Record',
      empty: 'No External Records',
      fetch: {
        error:
          'There was an error fetching External Dns records, please try again.',
      },
      formErrors: {
        hostnameMatch:
          'Please enter a Hostname consisting of alphanumeric hyphen characters',
        hostnameMaxLength: 'Enter a Hostname with 63 characters or less',
        hostnameRequired: 'Hostname is required.',
        natRuleNotSelected: 'Please select a Nat rule.',
      },
      name: 'External DNS',
      noConfiguredNatRules: 'You must configure a Nat before using DNS.',
      table: {
        headings: {
          aRecord: 'A',
          asset: 'Asset',
          hostname: 'Hostname',
          nat: 'NAT',
        },
      },
    },
    inboundProxyRules: {
      add: {
        error:
          'There was an error creating the Inbond Proxy rule, please try again.',
        success: 'Inbound Proxy Rule created successfully',
      },
      delete: {
        error:
          'There was an error deleting the Inbound Proxy Rule, please try again.',
        heading: 'Delete Inbound Proxy Rule',
        subHeading: 'Are you sure you want to delete this Inbound Proxy Rule?',
        success: 'Inbound Proxy Rule deleted successfully',
      },
      empty: 'No Inbound Proxy Rules',
      error:
        'There was an error fetching Inbound Proxy Rules, please try again.',
      formErrors: {
        hyperLinkTextMax: 'Please enter a value with 15 characters or less',
        hyperLinkTextRequired: 'Please enter Hyperlink Text',
        ipAddressRequired: 'Please select an IP Address',
        targetRequired: 'Please select a Target',
        tcpPortMax: 'Please enter a value less than or equal to 65535',
        tcpPortMin: 'Please enter a value greater than or equal to 0',
        tcpPortRequired: 'Please enter a TCP Port',
        urlPathMatch: 'Please enter a valid URL Path',
        urlPathMax: 'Please enter a value with 255 characters or less',
        urlPathRequired: 'Please enter a URL Path',
      },
      name: 'Inbound Proxy Rules',
      noConfiguredVMs:
        'You must configure a VM with an IP Address before using Inbound Proxy Rules',
      table: {
        hyperlinkText: 'Hyperlink Text',
        ipAddress: 'IP Address',
        showHyperlink: 'Show Hyperlink',
        ssl: 'SSL',
        target: 'Target',
        tcpPort: 'TCP Port',
        urlPath: 'URL Path',
      },
    },
    mailServer: {
      add: {
        error: 'There was an error creating the Mail Server, please try again.',
        success: 'Mail Server created successfully',
      },
      delete: {
        error: 'There was an error deleting the Mail Server, please try again.',
        heading: 'Delete Mail Server',
        subHeading: 'Are you sure you want to delete this Mail Server?',
        success: 'Mail Server deleted successfully',
      },
      empty: 'No Mail Servers',
      fetch: {
        error: 'There was an error fetching Mail Servers, please try again.',
      },
      formErrors: {
        assetNotSelected: 'Please select an Asset',
        ipAddressNotSelected: 'Please select an IP address',
        vmNotSelected: 'Please select a Virtual Machine',
      },
      name: 'Mail Server',
      noConfiguredMailserver:
        'You must configure a VM with an IP Address before using Mail Server.',
      table: {
        headings: {
          asset: 'Asset',
          ipAddress: 'IP Address',
          virtualMachine: 'Virtual Machine',
        },
      },
    },
    name: 'Traffic',
    natRules: {
      add: {
        error: 'There was an error creating the NAT rule, please try again.',
        success: 'NAT Rule created successfully',
        type: {
          natToIp: 'NAT to IP Address',
          natToVm: 'NAT to VM',
        },
      },
      delete: {
        error: 'There was an error deleting the NAT Rule, please try again.',
        heading: 'Delete NAT Rule',
        subHeading: 'Are you sure you want to delete this NAT Rule?',
        success: 'NAT Rule deleted successfully',
      },
      empty: 'No NAT Rules',
      fetch: {
        error: 'There was an error fetching NAT Rules, please try again.',
      },
      formErrors: {
        ipAddressInvalid: 'Please enter a valid IPv4 Address',
        ipAddressNotSelected: 'Please select an IP address',
        targetMax: 'Enter a Name with 50 characters or less',
        targetNotSelected: 'Please select a VM',
        targetRequired: 'Name cannot be blank',
        typeRequired: 'Please select a type',
      },
      name: 'NAT Rules',
      noConfiguredVms:
        'You must configure a VM with an IP Address before using Nat Rules.',
      table: {
        headings: {
          eastWestNat: 'East/West NAT',
          ipAddress: 'IP Address',
          target: 'Target',
          type: 'Type',
        },
      },
    },
    vmNicTargets: {
      fetch: {
        error: 'There was an error fetching VM targets, please try again.',
      },
    },
  },
  virtualMachines,
};
