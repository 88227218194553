import { SagaIterator } from 'redux-saga';
import { all, takeLatest } from 'redux-saga/effects';
import { fetchTopologies, cloneTopology, deleteTopology } from '../slice';
import { fetchTopologiesWorker } from './fetch-topologies-worker';
import { deleteTopologyWorker } from './delete-topology-worker';
import { cloneTopologyWorker } from './clone-topology-worker';

export function* topologiesWorker(): SagaIterator {
  yield all([
    yield takeLatest(fetchTopologies.type, fetchTopologiesWorker),
    yield takeLatest(cloneTopology.type, cloneTopologyWorker),
    yield takeLatest(deleteTopology.type, deleteTopologyWorker),
  ]);
}
