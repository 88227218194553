import { Checkbox, FormGroup } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import { Control, Controller } from 'react-hook-form';
import { ControlledFieldProps } from '../types';

export interface ControlledCheckboxProps<T extends Record<string, any>>
  extends Pick<ControlledFieldProps<T>, 'className' | 'control' | 'label'> {
  defaultValue?: boolean;
  disabled?: boolean;
  id: keyof T;
  inline?: boolean;
  testId?: string;
}

export function ControlledCheckbox<T extends Record<string, any>>({
  className,
  control,
  defaultValue = false,
  disabled = false,
  label,
  id,
  inline = false,
  testId,
}: ControlledCheckboxProps<T>): ReactElement {
  const typedId = id as string;

  return (
    <Controller
      name={typedId}
      control={control as Control}
      defaultValue={defaultValue}
      render={({ onChange, value }) => (
        <FormGroup inline={inline} className={className}>
          <Checkbox
            label={label}
            disabled={disabled}
            id={typedId}
            onChange={(event) => onChange(event.target.checked)}
            checked={value}
            data-testid={testId}
          />
        </FormGroup>
      )}
    />
  );
}
