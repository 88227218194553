import {
  createAction,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '../../store';

export const getValidAdaptersHelper = (
  networkingToEdit: VmNetworkInterfacesToEdit | undefined,
): VMRemoteAccessValidAdapter[] => {
  return networkingToEdit
    ? networkingToEdit?.reduce(function (
        adapters: VMRemoteAccessValidAdapter[],
        vmNetworkInterface,
      ) {
        vmNetworkInterface.ipAddress &&
          adapters.push({
            ipAddress: vmNetworkInterface.ipAddress,
            uid: vmNetworkInterface.uid as string,
          });
        return adapters;
      },
      [])
    : [];
};

interface VirtualMachinesState {
  selectedDemoId: string;
  virtualMachineToConfigure?: InventoryVirtualMachine;
  virtualMachineToDelete?: VirtualMachine;
  networkingToEdit?: VmNetworkInterfacesToEdit;
  interfaceToConfirmDelete?: VMNetworkInterface;
}

export const initialState: VirtualMachinesState = {
  interfaceToConfirmDelete: undefined,
  networkingToEdit: [],
  selectedDemoId: '',
  virtualMachineToConfigure: undefined,
  virtualMachineToDelete: undefined,
};

export const fetchVirtualMachines = createAction<string>(
  'virtualMachines/fetch',
);

const slice = createSlice({
  initialState,
  name: 'virtualMachines',
  reducers: {
    addVMNetworkInterface: (
      state,
      action: PayloadAction<VmNetworkInterfaceToEdit>,
    ) => {
      if (state.networkingToEdit) {
        state.networkingToEdit = [
          ...state.networkingToEdit,
          {
            ...action.payload,
          },
        ];
      } else {
        state.networkingToEdit = [action.payload];
      }
    },
    clearVirtualMachines: (state) => {
      state.virtualMachineToConfigure = undefined;
    },
    deleteVMNetworkInterface: (
      state,
      action: PayloadAction<VmNetworkInterfaceToEdit>,
    ) => {
      if (state.networkingToEdit) {
        state.networkingToEdit = state.networkingToEdit.filter(
          (item) => item.uid !== action.payload.uid,
        );
      }
      if (state.interfaceToConfirmDelete) {
        state.interfaceToConfirmDelete = undefined;
      }
    },
    setAutoLogin: (state, action: PayloadAction<boolean>) => {
      state.networkingToEdit = state.networkingToEdit?.map((item) =>
        item.rdp?.enabled
          ? {
              ...item,
              rdp: { ...item.rdp, autoLogin: action.payload },
            }
          : { ...item },
      );
    },
    setInterfaceToConfirmDelete: (
      state,
      action: PayloadAction<VMNetworkInterface | undefined>,
    ) => {
      state.interfaceToConfirmDelete = action.payload;
    },
    setNetworkingToEdit: (
      state,
      action: PayloadAction<VmNetworkInterfacesToEdit | undefined>,
    ) => {
      state.networkingToEdit = action.payload;
    },
    setSSHAdapter: (state, action: PayloadAction<string>) => {
      if (action.payload.length > 0) {
        state.networkingToEdit = state.networkingToEdit?.map((item) =>
          item.uid === action.payload
            ? {
                ...item,
                ssh: {
                  ...item.ssh,
                  enabled: true,
                },
              }
            : { ...item, ssh: undefined },
        );
      } else {
        state.networkingToEdit = state.networkingToEdit?.map((item) => {
          return { ...item, ssh: undefined };
        });
      }
    },
    setSelectedDemoId: (state, action: PayloadAction<string>) => {
      state.selectedDemoId = action.payload;
    },
    setVirtualMachineToConfigure: (
      state,
      action: PayloadAction<InventoryVirtualMachine | undefined>,
    ) => {
      state.virtualMachineToConfigure = action.payload;
    },
    setVirtualMachineToDelete: (
      state,
      action: PayloadAction<VirtualMachine | undefined>,
    ) => {
      state.virtualMachineToDelete = action.payload;
    },
    setWebRDPAdapter: (
      state,
      action: PayloadAction<VMSetRDPAdapterActionPayload>,
    ) => {
      if (action.payload.uid.length > 0) {
        state.networkingToEdit = state.networkingToEdit?.map((item) =>
          item.uid === action.payload.uid
            ? {
                ...item,
                rdp: {
                  ...item.rdp,
                  autoLogin: action.payload.isAutoLoginChecked,
                  enabled: true,
                },
              }
            : { ...item, rdp: undefined },
        );
      } else {
        state.networkingToEdit = state.networkingToEdit?.map((item) => {
          return { ...item, rdp: undefined };
        });
      }
    },
  },
});

export const getVirtualMachines = (state: RootState): VirtualMachinesState =>
  state.configuration[slice.name];

export const { reducer: virtualMachinesReducer } = slice;
export const {
  addVMNetworkInterface,
  deleteVMNetworkInterface,
  clearVirtualMachines,
  setInterfaceToConfirmDelete,
  setSelectedDemoId,
  setVirtualMachineToConfigure,
  setVirtualMachineToDelete,
  setNetworkingToEdit,
  setWebRDPAdapter,
  setSSHAdapter,
  setAutoLogin,
} = slice.actions;

export const virtualMachinesSelectors = {
  getValidAdapters: createSelector(getVirtualMachines, ({ networkingToEdit }) =>
    getValidAdaptersHelper(networkingToEdit),
  ),
  getVirtualMachineToConfigure: createSelector(
    getVirtualMachines,
    ({ virtualMachineToConfigure }) => virtualMachineToConfigure,
  ),
};
