import { Icon } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './BackButton.module.css';

export function BackButton(): ReactElement {
  const { pathname } = useLocation();

  return (
    <Link
      to={pathname.substring(0, pathname.lastIndexOf('/'))}
      className={styles.root}
    >
      <Icon name="chevron-left" testId="page-back-button" />
    </Link>
  );
}
