import React, { ReactElement } from 'react';
import { Connections } from '../../../components';
import { ConnectedSessionNodes } from '../../../components/Nodes/container';
import { useCanvas } from '../../../hooks/use-canvas';
import styles from './SessionDiagram.module.css';

interface SessionDiagramProps {
  connections: DiagramConnection[];
}

export function SessionDiagram({
  connections,
}: SessionDiagramProps): ReactElement {
  const { canvasRef } = useCanvas();

  return (
    <div className={styles.root}>
      <div id="canvas" className={styles.board} ref={canvasRef}>
        <svg preserveAspectRatio="xMidYMid meet" className={styles.svg}>
          <Connections connections={connections} />
        </svg>
        <ConnectedSessionNodes />
      </div>
    </div>
  );
}
