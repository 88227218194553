/* eslint react/display-name:0, react/prop-types: 0 */
import React, { forwardRef, PropsWithChildren } from 'react';
import classnames from 'classnames';
import styles from './Node.module.css';

interface NodeProps {
  className?: string;
  uid?: string;
  isSelected?: boolean;
  onClick?: VoidFunction;
  isReadOnly: boolean;
}

export const Node = forwardRef<HTMLDivElement, PropsWithChildren<NodeProps>>(
  (
    { className, children, onClick, isReadOnly, isSelected = false, uid },
    ref,
  ) => {
    return (
      <div
        data-component={uid}
        className={classnames(styles.root, className, {
          [styles.editable]: !isReadOnly,
          [styles.selected]: isSelected,
        })}
        ref={ref}
        onClick={onClick}
      >
        {children}
      </div>
    );
  },
);
