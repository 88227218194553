import { EXTERNAL_DNS, TOPOLOGIES_PATH } from '../../../../api/paths';
import i18n from '../../../../i18n';
import { dcloudApiAxios, TAGS } from '../../../api';

export const externalDnsApi = dcloudApiAxios.injectEndpoints({
  endpoints: (build) => ({
    addExternalDnsRecord: build.mutation<
      ExternalDnsRecord,
      ExternalDnsPostPayload
    >({
      invalidatesTags: [TAGS.EXTERNAL_DNS],
      query: (payload) => ({
        data: payload,
        errorMessage: i18n.t('traffic.externalDns.add.error'),
        method: 'post',
        successMessage: i18n.t('traffic.externalDns.add.success'),
        url: `${EXTERNAL_DNS}`,
      }),
    }),
    getExternalDnsRecords: build.query<FetchExternalDnsResponse, string>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.EXTERNAL_DNS],
      query: (topologyUid) => ({
        errorMessage: i18n.t('traffic.externalDns.fetch.error'),
        method: 'get',
        url: `${TOPOLOGIES_PATH}/${topologyUid}${EXTERNAL_DNS}`,
      }),
    }),
  }),
});

export const {
  useAddExternalDnsRecordMutation,
  useGetExternalDnsRecordsQuery,
} = externalDnsApi;
