import { IconButton } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import classnames from 'classnames';
import styles from './Actions.module.css';

export interface ActionsProps {
  className?: string;
  canDelete?: boolean;
  onDelete: VoidFunction;
  name: string;
}

export function Actions({
  className = undefined,
  canDelete,
  onDelete,
  name,
}: ActionsProps): ReactElement {
  return (
    <ul
      aria-label="VM Configure Networking actions"
      className={classnames(styles.root, className)}
    >
      {canDelete && (
        <li>
          <IconButton
            className={styles.action}
            icon="delete"
            colour="link"
            onClick={onDelete}
            testId={`vm-configure-networking-table-delete-${name}`}
          />
        </li>
      )}
    </ul>
  );
}
