import { yupResolver } from '@hookform/resolvers/yup';
import React, { ReactElement } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useAddExternalDnsRecordMutation } from '../../../../../../redux/configuration/traffic/external-dns/api';
import { externalDnsScheme } from '../../../../../../validation';
import { buildAssetOptions } from '../../MailServer/utils';
import { buildExternalDnsPayload, buildNatOptions } from '../utils';
import { ExternalDnsAddForm } from '.';

interface ExternalDnsAddFormContainerProps {
  natTargets: NatRule[];
  dnsAssets?: InventoryDnsAsset[];
}

export function ExternalDnsAddFormContainer({
  dnsAssets,
  natTargets,
}: ExternalDnsAddFormContainerProps): ReactElement {
  const { uid: topologyUid } = useParams<TopologyUid>();
  const {
    control,
    errors,
    formState,
    handleSubmit,
    reset: resetForm,
  } = useForm<ExternalDnsFormData>({
    defaultValues: {
      asset: '',
      hostname: '',
      natRule: '',
    },
    mode: 'all',
    resolver: yupResolver(externalDnsScheme),
  });

  const [
    createExternalDnsRecord,
    { isError, isLoading },
  ] = useAddExternalDnsRecordMutation();

  const handleCreate: SubmitHandler<ExternalDnsFormData> = async (formData) => {
    const payload = buildExternalDnsPayload({ formData, topologyUid });
    await createExternalDnsRecord(payload);

    !isError && resetForm();
  };

  return (
    <ExternalDnsAddForm
      assetOptions={buildAssetOptions(dnsAssets)}
      natRuleOptions={buildNatOptions(natTargets)}
      control={control}
      errors={errors}
      formState={formState}
      handleCreate={handleCreate}
      handleSubmit={handleSubmit}
      isCreating={isLoading}
    />
  );
}
