import { dcloudApi, TAGS } from '../../../api';
import { TOPOLOGIES_PATH, HARDWARE_START_ORDER } from '../../../../api/paths';
import { setEtag } from '../../etag/slice';
import { setStartStopOrder } from '../slice';
import { addToast } from '../../../toast/slice';
import i18n from '../../../../i18n';

export const hardwareStartOrderApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    getHardwareStartOrder: build.query<StartStopOrder, string>({
      keepUnusedDataFor: 0,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data, meta } = await queryFulfilled;
          const etag = meta?.response.headers.get('etag');
          etag && dispatch(setEtag(etag));
          dispatch(setStartStopOrder(data));
        } catch (err) {
          dispatch(
            addToast({
              message: i18n.t('startStopOrder.hardwareStart.fetch.errors.many'),
              title: i18n.t('common.error'),
              type: 'error',
            }),
          );
        }
      },
      providesTags: [TAGS.HARDWARE_START_ORDER],
      query: (topologyUid) => ({
        url: `${TOPOLOGIES_PATH}/${topologyUid}${HARDWARE_START_ORDER}`,
      }),
    }),
    updateHWStartOrder: build.mutation<VirtualMachine, StartStopOrderPayload>({
      invalidatesTags: [TAGS.HARDWARE_START_ORDER],
      async onCacheEntryAdded(_, { cacheDataLoaded, dispatch }) {
        const { meta } = await cacheDataLoaded;
        const etag = meta?.response.headers.get('etag');
        etag && dispatch(setEtag(etag));
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addToast({
              message: i18n.t('startStopOrder.hardwareStart.update.success'),
              title: i18n.t('common.success'),
              type: 'success',
            }),
          );
        } catch (err) {
          dispatch(
            addToast({
              message: i18n.t('startStopOrder.hardwareStart.update.error'),
              title: i18n.t('common.error'),
              type: 'error',
            }),
          );
        }
      },
      query: ({ etag, payload }) => ({
        body: payload,
        headers: { 'If-Match': etag },
        method: 'PUT',
        url: `${HARDWARE_START_ORDER}/${payload.uid}`,
      }),
    }),
  }),
});

export const {
  useGetHardwareStartOrderQuery,
  useUpdateHWStartOrderMutation,
} = hardwareStartOrderApi;
