import { SagaIterator } from 'redux-saga';
import { put } from 'redux-saga/effects';
import i18n from '../../i18n';
import config from '../../config';
import { addToast } from './slice';

export function* showSuccessToastWorker(message: string): SagaIterator {
  yield put(
    addToast({
      duration: config.TOAST_DELAY,
      message: i18n.t(message),
      title: i18n.t('common.success'),
      type: 'success',
    }),
  );
}

export function* showErrorToastWorker(
  message = 'errors.general',
): SagaIterator {
  yield put(
    addToast({
      message: i18n.t(message),
      title: i18n.t('common.error'),
      type: 'error',
    }),
  );
}
