import { Header, ThemeSwitch } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { MainRoutes } from '../../../constants/routes';
import { DiagramProvider } from '../components';
import { SessionDiagramContainer } from './components/SessionDiagram/container';

export function SessionView(): ReactElement {
  const { t } = useTranslation();

  return (
    <DiagramProvider>
      <Header
        title={t('siteTitle')}
        right={<ThemeSwitch />}
        logoLinkTo={MainRoutes.Main}
      />
      <SessionDiagramContainer />
    </DiagramProvider>
  );
}
