import classnames from 'classnames';
import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ControlledCheckbox,
  ControlledSelect,
  ControlledTextarea,
  ControlledTextfield,
} from '../../../../../../../components';
import { createOsOptions } from '../../utils';
import { CpuInput } from '../CpuInput';
import { MemoryInput } from '../MemoryInput';
import styles from './GeneralForm.module.css';

export interface GeneralFormInterface {
  operatingSystems: OperatingSystem[];
}

export function GeneralForm({
  operatingSystems,
}: GeneralFormInterface): ReactElement {
  const { control, errors } = useFormContext();
  const { t } = useTranslation();
  const formSectionClasses = classnames('col-6', styles.formSection);

  return (
    <div className={styles.root}>
      <div className={formSectionClasses}>
        <ControlledTextfield
          control={control}
          error={errors?.name?.message}
          label={t('virtualMachines.edit.name')}
          name="name"
          required={true}
          testId="edit-vm-name"
          id={`vm-edit-general-name-input`}
        />
        <ControlledTextarea
          control={control}
          error={errors?.description?.message}
          label={t('virtualMachines.edit.description')}
          name="description"
          rows={4}
          required={true}
          testId="edit-vm-description"
          id={`vm-edit-general-description-input`}
        />
        <ControlledSelect
          control={control}
          label={t('virtualMachines.edit.osIcon')}
          name="osFamily"
          options={createOsOptions(operatingSystems)}
          id={`vm-edit-general-os-input`}
        />
      </div>
      <div className={formSectionClasses}>
        <CpuInput />
        <MemoryInput />
        <ControlledCheckbox
          className={styles.checkbox}
          control={control}
          id="nestedHypervisor"
          label={t('virtualMachines.edit.nestedHypervisor')}
          testId="edit-vm-nested-hypervisor"
        />
      </div>
    </div>
  );
}
