import React, { ReactElement } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { TopologiesLayout } from '../../layouts/TopologiesLayout';
import { CreateTopologyRoutes } from './constants';
import { useCreateTopology } from './hooks/use-create-topology';
import { WizardSteps } from './components/WizardSteps';
import { NoDataCenterRedirectRoute } from './components/NoDataCenterRedirectRoute';
import { DemoType } from './pages/DemoType';
import { Details } from './pages/Details';
import { DataCenters } from './pages/DataCenters';
import { DemoImportContainer } from './pages/DemoImport/container';

export function CreateTopology(): ReactElement {
  const { path } = useRouteMatch();

  const {
    creating,
    isLoading,
    demoType,
    demoToImport,
    datacenter,
    onCreate,
    setDatacenter,
    setDemoType,
    setDemoToImport,
  } = useCreateTopology();

  return (
    <TopologiesLayout showLoadingSpinner={isLoading}>
      <div className="container">
        <WizardSteps dataCenter={datacenter} demoType={demoType} />
        <Switch>
          <Route
            path={path}
            render={() => (
              <DataCenters
                setDataCenter={setDatacenter}
                resetDemoType={() => setDemoType('')}
              />
            )}
            exact={true}
          />
          <NoDataCenterRedirectRoute
            datacenter={datacenter}
            path={`${path}${CreateTopologyRoutes.DemoType}`}
            render={() => (
              <DemoType
                setDemoType={setDemoType}
                resetDemoToImport={() => setDemoToImport(undefined)}
              />
            )}
            exact={true}
          />
          <NoDataCenterRedirectRoute
            datacenter={datacenter}
            path={`${path}${CreateTopologyRoutes.Details}`}
            render={() => (
              <Details
                onSubmit={onCreate}
                submitting={creating}
                demoToImport={demoToImport}
              />
            )}
            exact={true}
          />
          <NoDataCenterRedirectRoute
            datacenter={datacenter}
            path={`${path}${CreateTopologyRoutes.DemoImport}`}
            render={() => (
              <DemoImportContainer
                setDemoToImport={setDemoToImport}
                datacenter={datacenter as DataCenter}
              />
            )}
            exact={true}
          />
        </Switch>
      </div>
    </TopologiesLayout>
  );
}
