import { SelectOption } from 'dcloud-shared-ui';
import { v4 } from 'uuid';
import { sortObjectKeys } from '../../../../../../utils/helpers';
import { TEMP_UID_KEY } from '../constants';

export function createTemporaryNetwork(
  fields: AddNetworkFields,
): HardwareNetworkInterface {
  return {
    network: {
      _links: {},
      name: '',
      uid: fields.network,
    },
    networkInterface: {
      id: fields.interface,
    },
    uid: `${TEMP_UID_KEY}${v4()}`,
  };
}

export const convertNetworksToOptions = (
  networks: Network[],
): SelectOption<string>[] =>
  networks.map((item) => ({ name: item.name, value: item.uid }));

export const convertInterfacesToOptions = (
  interfaces: NetworkInterface[],
  selectedInterfaces: HardwareNetworkInterface[],
): SelectOption<string>[] => {
  return interfaces
    .filter((item) => {
      const selectedIds = selectedInterfaces.map(
        (item) => item.networkInterface.id,
      );

      return !selectedIds.includes(item.id);
    })
    .map((item) => ({ name: item.id, value: item.id }));
};

export const areNetworkInterfacesEqual = <
  T extends HardwareNetworkInterface[] | undefined
>(
  arr1: T,
  arr2: T,
): boolean => {
  const networkInterfaceArray1 = arr1
    ?.sort()
    .map((item) =>
      item.networkInterface
        ? { ...item, networkInterface: sortObjectKeys(item?.networkInterface) }
        : { ...item },
    );
  const networkInterfaceArray2 = arr2
    ?.sort()
    .map((item) =>
      item.networkInterface
        ? { ...item, networkInterface: sortObjectKeys(item?.networkInterface) }
        : { ...item },
    );
  return (
    JSON.stringify(networkInterfaceArray1) ===
    JSON.stringify(networkInterfaceArray2)
  );
};

export const returnFieldOrUndefined = (
  field: UidOnlyField,
): UidOnlyField | undefined => {
  return field.uid ? field : undefined;
};
