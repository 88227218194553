import { SelectOption } from 'dcloud-shared-ui';
import { NAT_TYPES } from '../../../../../constants/entities';
import { PLEASE_SELECT_OPTION } from '../../../../../constants/form';
import i18n from '../../../../../i18n';

interface BuildNatRulePayloadProps {
  formData: NatRuleFormData;
  topologyUid: TopologyUid['uid'];
}

interface BuildNatIpOptionsProps {
  vmTargets: VmNicTarget[];
  vmName: string;
}

export const buildNatTypesOptions = (): SelectOption<string>[] => {
  const options = Object.keys(NAT_TYPES).map((key) => ({
    name: i18n.t(`traffic.natRules.add.type.${key}`),
    value: NAT_TYPES[key],
  }));

  return [PLEASE_SELECT_OPTION, ...options];
};

export const buildNatRulePayload = ({
  formData,
  topologyUid,
}: BuildNatRulePayloadProps): NatRulePostPayload => {
  const { eastWest, ...target } = formData;

  if (formData.type === 'IP') {
    return {
      eastWest,
      target: {
        ipAddress: target.ipAddress,
        name: target.name,
      },
      topology: { uid: topologyUid },
    };
  } else {
    return {
      eastWest,
      target: { targetItem: target.targetItem },
      topology: { uid: topologyUid },
    };
  }
};

export const buildVmNameOptions = (
  vmTargets: VmNicTarget[],
): SelectOption<string>[] => {
  const vms = vmTargets.map(({ vm }) => vm);
  const options = vms.map(({ name }) => ({ name, value: name }));
  const result = options.filter(
    (currentItem, index, options) =>
      options.findIndex((target) => target.name === currentItem.name) === index,
  );
  return [PLEASE_SELECT_OPTION, ...result];
};

export const buildNatIpOptions = ({
  vmTargets,
  vmName,
}: BuildNatIpOptionsProps): SelectOption<string>[] => {
  const vmTargetsForName = vmTargets.filter((nic) => nic.vm.name === vmName);

  const options = vmTargetsForName.map(({ ipAddress, uid }: VmNicTarget) => ({
    name: ipAddress!,
    value: uid!,
  }));

  return [PLEASE_SELECT_OPTION, ...options];
};
