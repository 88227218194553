import React, { ReactElement } from 'react';
import {
  TopologiesError,
  TopologiesNoResults,
} from '../../../../entities/topologies/components';
import { ConnectedTopologiesView } from '../../../../entities/topologies/components/TopologiesView/container';
import { ConnectedSaveCustomContentModal } from '../SaveCustomContentModal/container';

interface TopologiesContentProps {
  hasError: boolean;
  topologies: Topology[];
  refetch: VoidFunction;
}

export function TopologiesContent({
  hasError,
  topologies,
  refetch,
}: TopologiesContentProps): ReactElement {
  if (hasError) {
    return <TopologiesError fetchTopologies={refetch} />;
  }

  if (topologies.length === 0) {
    return <TopologiesNoResults />;
  }

  return (
    <>
      <ConnectedTopologiesView />
      <ConnectedSaveCustomContentModal />
    </>
  );
}
