import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { PrimaryButton } from '../../../../components/Buttons/PrimaryButton';
import { PageHeading } from '..';
import styles from './PageHeadingWithCTA.module.css';

export interface PageHeadingWithCTAProps {
  buttonAriaLabel?: string;
  buttonLabel?: string;
  disableButton?: boolean;
  onButtonClick?: VoidFunction;
  pageTitle: string;
}

export function PageHeadingWithCTA({
  buttonAriaLabel = 'CTA Button',
  buttonLabel,
  disableButton = false,
  onButtonClick,
  pageTitle,
}: PageHeadingWithCTAProps): ReactElement {
  const classes = classNames('dbl-margin-bottom flex-between', styles.root);

  return (
    <div className={classes}>
      <PageHeading text={pageTitle} />
      {onButtonClick && (
        <PrimaryButton
          onClick={onButtonClick}
          className={styles.ctaBtn}
          aria-label={buttonAriaLabel}
          disabled={disableButton}
          testId={buttonAriaLabel}
        >
          {buttonLabel}
        </PrimaryButton>
      )}
    </div>
  );
}
