import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../components';
import { EditLicenceForm } from '../EditLicenceForm';
import { EditLicenceModalReduxProps } from './container';
import styles from './EditLicenceModal.module.css';

export function EditLicenceModal({
  closeModal,
  editLicence,
  updating,
  licence,
}: EditLicenceModalReduxProps): ReactElement {
  const { t } = useTranslation();

  return (
    <Modal
      className={styles.root}
      testId="edit-licence-modal"
      title={t('licences.edit.title')}
      show={!!licence}
      size="default"
      onClose={() => closeModal()}
      clickScreenToClose={true}
    >
      {licence && (
        <EditLicenceForm
          licence={licence}
          loading={updating}
          onSubmit={editLicence}
        />
      )}
    </Modal>
  );
}
