import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { fetchInventoryLicences as fetchInventoryLicencesApi } from '../../../api/licences';
import { fetchTopology } from '../../../api/topologies';
import config from '../../../config';
import { showErrorToastWorker } from '../../toast/sagas';
import { fetchInventoryLicences, fetchInventoryLicencesSuccess } from './slice';

export function* fetchInventoryLicencesWorker({
  payload,
}: PayloadAction<string>): SagaIterator {
  try {
    const { data: topology } = yield call(fetchTopology, payload);
    const inventoryLicences: InventoryLicence[] = yield call(
      fetchInventoryLicencesApi,
      topology.datacenter.toLowerCase(),
    );
    yield delay(config.FLICKER_DELAY);
    yield put(fetchInventoryLicencesSuccess(inventoryLicences));
  } catch (e) {
    yield call(showErrorToastWorker, 'licences.fetch.error');
  }
}

export function* inventoryLicencesWatchers(): SagaIterator {
  yield all([
    yield takeLatest(fetchInventoryLicences.type, fetchInventoryLicencesWorker),
  ]);
}
