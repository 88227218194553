import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../components';
import { CloneTopologyForm } from './CloneTopologyForm';
import { PropsFromRedux } from './container';

interface CloneTopologyModalProps extends PropsFromRedux {
  clearTopologyToClone: VoidFunction;
  cloneTopology: (fields: CloneFields) => void;
}

export function CloneTopologyModal({
  clearTopologyToClone,
  cloneTopology,
  isCloning,
  topologyToClone,
}: CloneTopologyModalProps): ReactElement {
  const { t } = useTranslation();

  return (
    <Modal
      testId="topology-clone-modal"
      title={t('topologies.clone.heading')}
      show={!!topologyToClone}
      size="default"
      onClose={clearTopologyToClone}
      clickScreenToClose={true}
    >
      {topologyToClone && (
        <CloneTopologyForm
          topologyToClone={topologyToClone}
          isCloning={isCloning}
          onSubmit={cloneTopology}
          onClose={clearTopologyToClone}
        />
      )}
    </Modal>
  );
}
