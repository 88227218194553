import React, { ReactElement, useRef } from 'react';
import { Node } from '../Node';
import { useConnection } from '../Connections/hooks/use-connection';
import { useDrag } from '../Node/hooks/use-drag';
import { usePosition } from '../Node/hooks/use-position';
import styles from './VPodGateway.module.css';
import { ReactComponent as VPodGatewayImage } from './images/VPodGateway.svg';
import { DefaultVPodGateWayDisplay as defaultPosition } from './constants';

export function VPodGateway({
  display,
  isReadOnly,
  uid,
}: BaseNodeProps): ReactElement {
  const nodeRef = useRef<HTMLDivElement>(null);
  const { redrawNodeConnections } = useConnection();

  const hookProps = {
    defaultPosition,
    display,
    nodeRef,
    redrawNodeConnections,
    uid,
  };

  usePosition({ ...hookProps });
  useDrag({ ...hookProps, isReadOnly });

  return (
    <Node className={styles.root} ref={nodeRef} isReadOnly={isReadOnly}>
      <p className={styles.title}>vPodGW</p>
      <VPodGatewayImage data-component={uid} />
    </Node>
  );
}
