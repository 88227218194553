import { connect } from 'react-redux';
import { RootState } from '../../../../redux/store';
import {
  deleteNetwork,
  networksSelectors,
  setNetworkToDelete,
} from '../../../../redux/configuration/networks/slice';
import { DeleteNetworkModal } from '.';

export type DeleteNetworkModalReduxProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const mapStateToProps = (state: RootState) => ({
  network: networksSelectors.getNetworkToDelete(state),
});

const mapDispatchToProps = {
  deleteNetwork,
  setNetworkToDelete,
};

export const ConnectedDeleteNetworkModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteNetworkModal);
