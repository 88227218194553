import React, { ReactElement, useRef } from 'react';
import { Node } from '../Node';
import { useConnection } from '../Connections/hooks/use-connection';
import { useDrag } from '../Node/hooks/use-drag';
import { usePosition } from '../Node/hooks/use-position';
import styles from './HardwareNode.module.css';
import { ReactComponent as DefaultHardwareItem } from './images/DefaultHardwareItem.svg';
import { DefaultHardwareNodeDisplay as defaultPosition } from './constants';

interface HardwareNodeProps extends BaseNodeProps {
  asset?: Hardware;
}

export function HardwareNode({
  uid,
  asset,
  isReadOnly,
  ...node
}: HardwareNodeProps): ReactElement {
  const nodeRef = useRef<HTMLDivElement>(null);
  const { redrawNodeConnections } = useConnection();

  const hookProps = {
    defaultPosition,
    display: node.display,
    nodeRef,
    redrawNodeConnections,
    uid,
  };

  usePosition({ ...hookProps });
  useDrag({ ...hookProps, isReadOnly });

  return (
    <Node className={styles.root} ref={nodeRef} isReadOnly={isReadOnly}>
      <p className={styles.title}>{asset?.name}</p>
      <DefaultHardwareItem data-component={uid} />
    </Node>
  );
}
