import React, { PropsWithChildren, ReactElement } from 'react';
import classnames from 'classnames';
import { LinkButton } from 'dcloud-shared-ui';
import styles from './NavigationButton.module.css';

type NavigationButtonProps = PropsWithChildren<{
  selected: boolean;
  testId: string;
  to: string;
}>;

export function NavigationButton({
  children,
  to,
  selected,
  testId,
}: NavigationButtonProps): ReactElement {
  return (
    <LinkButton
      colour={selected ? 'dark' : 'ghost'}
      to={to}
      className={classnames(styles.root, {
        [styles.selected]: selected,
      })}
      testId={testId}
    >
      {children}
    </LinkButton>
  );
}
