import { Alert } from 'dcloud-shared-ui';
import React, { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TableLoader } from '../../../../../../../components/Table/TableLoader';
import { NetworkingConfirmDeleteModal } from './components/NetworkingConfirmDeleteModal';
import { NetworkingForm } from './components/NetworkingForm';
import { NetworkingTable } from './components/NetworkingTable';
import { NetworkingProps } from './container';

export function Networking({
  addErrorToast,
  isErrorLoadingNicTypes,
  isErrorLoadingNetworks,
  networkingToEdit,
  addVMNetworkInterface,
  deleteVMNetworkInterface,
  networks,
  nicTypes,
  isLoadingNicTypes,
  isLoadingNetworks,
  interfaceToConfirmDelete,
  setInterfaceToConfirmDelete,
}: NetworkingProps): ReactElement | null {
  const { t } = useTranslation();

  const onDeleteClick = useCallback(
    (vmNetworkInterfaceToEdit: VmNetworkInterfaceToEdit) => {
      return networkingToEdit && networkingToEdit?.length <= 1
        ? addErrorToast({
            message: t('virtualMachines.edit.networking.minLimit'),
          })
        : vmNetworkInterfaceToEdit.inUse
        ? setInterfaceToConfirmDelete(vmNetworkInterfaceToEdit)
        : deleteVMNetworkInterface(vmNetworkInterfaceToEdit);
    },
    [
      addErrorToast,
      deleteVMNetworkInterface,
      networkingToEdit,
      setInterfaceToConfirmDelete,
      t,
    ],
  );

  const onConfirmDeleteClick = useCallback(
    (vmNetworkInterfaceToEdit: VmNetworkInterfaceToEdit) => {
      deleteVMNetworkInterface(vmNetworkInterfaceToEdit);
    },
    [deleteVMNetworkInterface],
  );

  if (
    isLoadingNicTypes ||
    isLoadingNetworks ||
    isErrorLoadingNetworks ||
    isErrorLoadingNicTypes
  ) {
    return <TableLoader />;
  }

  return (
    <section className="half-padding-left">
      <>
        <NetworkingForm
          addVMNetworkInterface={addVMNetworkInterface}
          networks={networks || []}
          nicTypes={nicTypes || []}
          addErrorToast={addErrorToast}
          totalNics={networkingToEdit?.length || 0}
        />
        {networkingToEdit && networkingToEdit.length === 0 && (
          <Alert colour="info">
            {t('virtualMachines.edit.networking.empty.alertText')}
          </Alert>
        )}
        {networkingToEdit && networkingToEdit.length > 0 && (
          <NetworkingTable
            vmNetworkInterfaces={networkingToEdit || []}
            onDeleteClick={onDeleteClick}
            isLoading={isLoadingNicTypes || isErrorLoadingNicTypes}
            nicTypes={nicTypes || []}
          />
        )}
        {interfaceToConfirmDelete && (
          <NetworkingConfirmDeleteModal
            interfaceToConfirmDelete={interfaceToConfirmDelete}
            onConfirm={onConfirmDeleteClick}
            setInterfaceToConfirmDelete={setInterfaceToConfirmDelete}
          />
        )}
      </>
    </section>
  );
}
