import { yupResolver } from '@hookform/resolvers/yup';
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import { SelectOption } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  ControlledCheckbox,
  ControlledSelect,
  ControlledTextfield,
} from '../../../../../../../components';
import { PrimaryButton } from '../../../../../../../components/Buttons/PrimaryButton';
import { FormWrapper } from '../../../../../../../components/FormWrapper';
import { inboundProxyRulesSchema } from '../../../../../../../validation';
import {
  parseChangeEventToNumber,
  parseNumberToString,
} from '../../../../VirtualMachines/Edit/utils';
import { buildInboundProxyRulePayload } from '../../utils';
import styles from './InboundProxyRulesAddForm.module.css';

interface InboundProxyRulesAddFormProps {
  setSelectedVM: ActionCreatorWithOptionalPayload<string | undefined, string>;
  ipAddressOptions: SelectOption<string>[];
  targetOptions: SelectOption<string>[];
  isCreating: boolean;
  createInboundProxyRule: RTKMutation<
    InboundProxyRulePostPayload,
    InboundProxyRule
  >;
}

export function InboundProxyRulesAddForm({
  setSelectedVM,
  ipAddressOptions,
  targetOptions,
  isCreating = false,
  createInboundProxyRule,
}: InboundProxyRulesAddFormProps): ReactElement | null {
  const { uid: topologyUid } = useParams<TopologyUid>();
  const { t } = useTranslation();
  const {
    control,
    errors,
    formState,
    handleSubmit,
    reset: resetForm,
  } = useForm<InboundProxyRuleFormData>({
    defaultValues: {
      hyperLinkText: '',
      ipAddress: '',
      showHyperlink: true,
      ssl: true,
      target: '',
      tcpPort: undefined,
      urlPath: '',
    },
    mode: 'all',
    resolver: yupResolver(inboundProxyRulesSchema),
  });
  const { isDirty, isValid } = formState;

  const handleCreate: SubmitHandler<InboundProxyRuleFormData> = async (
    formData,
  ) => {
    const payload = buildInboundProxyRulePayload({ formData, topologyUid });
    const { error } = await createInboundProxyRule(payload);

    !error && resetForm();
  };

  return (
    <FormWrapper
      aria-label="Add inbound proxy rule form"
      className={styles.root}
      onSubmit={handleSubmit(handleCreate)}
    >
      <ControlledSelect
        control={control}
        options={targetOptions}
        label={t('traffic.inboundProxyRules.table.headings.target')}
        name="target"
        required={true}
        error={errors?.target?.message}
        className={styles.targetInput}
        customOnChange={(e) => setSelectedVM(e.target.value)}
      />
      <ControlledSelect
        control={control}
        options={ipAddressOptions}
        label={t('traffic.inboundProxyRules.table.headings.ipAddress')}
        name="ipAddress"
        required={true}
        error={errors?.ipAddress?.message}
        className={styles.ipAddressInput}
      />
      <ControlledTextfield
        control={control}
        label={t('traffic.inboundProxyRules.table.headings.tcpPort')}
        name="tcpPort"
        required={true}
        error={errors?.tcpPort?.message}
        className={styles.tcpPortInput}
        isNumber={true}
        transform={{
          input: parseNumberToString,
          output: parseChangeEventToNumber,
        }}
      />
      <ControlledCheckbox
        className={styles.verticalAlign}
        control={control}
        label={t('traffic.inboundProxyRules.table.headings.ssl')}
        id="ssl"
      />
      <ControlledTextfield
        control={control}
        label={t('traffic.inboundProxyRules.table.headings.urlPath')}
        name="urlPath"
        required={true}
        error={errors?.urlPath?.message}
      />
      <ControlledCheckbox
        className={styles.verticalAlign}
        control={control}
        label={t('traffic.inboundProxyRules.table.headings.showHyperlink')}
        id="showHyperlink"
      />
      <ControlledTextfield
        control={control}
        label={t('traffic.inboundProxyRules.table.headings.hyperlinkText')}
        name="hyperLinkText"
        required={true}
        error={errors?.hyperLinkText?.message}
      />
      <PrimaryButton
        disabled={!isDirty || !isValid}
        loading={isCreating}
        type="submit"
      >
        {t('buttons.add')}
      </PrimaryButton>
    </FormWrapper>
  );
}
