import { connect } from 'react-redux';
import {
  clearTopologyToClone,
  topologiesSelectors,
  cloneTopology,
} from '../../../../redux/topologies/slice';
import { RootState } from '../../../../redux/store';
import { CloneTopologyModal } from '.';

export type PropsFromRedux = ReturnType<typeof mapStateToProps>;

const mapStateToProps = (state: RootState) => ({
  isCloning: topologiesSelectors.getIsCloning(state),
  topologyToClone: topologiesSelectors.getTopologyToClone(state),
});

const mapDispatchToProps = {
  clearTopologyToClone,
  cloneTopology,
};

export const ConnectedCloneTopologyModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CloneTopologyModal);
