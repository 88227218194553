import React, { FunctionComponent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { MainRoutes } from '../../../../constants/routes';
import { TopologySVGIcon } from '../../../../components/SVGIcons/TopologySVGIcon';
import { Tile, TileProps } from './components/Tile';
import styles from './Tiles.module.css';
import { ReactComponent as DocumentationImage } from './images/docs.svg';
import { ReactComponent as VideosImage } from './images/videos.svg';

const TILES: TileProps[] = [
  {
    Image: TopologySVGIcon as FunctionComponent,
    description: 'home.myTopologies.description',
    link: MainRoutes.Topologies,
    title: 'home.myTopologies.title',
  },
  {
    Image: DocumentationImage,
    description: 'home.documentation.description',
    link: MainRoutes.Main,
    title: 'home.documentation.title',
  },
  {
    Image: VideosImage,
    description: 'home.videos.description',
    link: MainRoutes.Main,
    title: 'home.videos.title',
  },
];

export function Tiles(): ReactElement {
  const { t } = useTranslation();

  return (
    <div className={classnames('row container dbl-padding-top', styles.root)}>
      {TILES.map((tile) => (
        <Tile
          title={t(tile.title)}
          description={t(tile.description)}
          link={tile.link}
          key={tile.title}
          Image={tile.Image}
        />
      ))}
    </div>
  );
}
