import { useEffect, useCallback } from 'react';
import * as d3 from 'd3';
import {
  calculateDefaultPositionOnGraphBasedOnType,
  setXY,
  x,
  y,
  zoom,
} from '../../../d3';
import { useWindowResize } from '../../../../../hooks/use-window-resize';
import { UseConnection } from '../../Connections/hooks/use-connection';

interface UsePosition {
  display?: DiagramDisplay;
  nodeRef: React.RefObject<HTMLDivElement>;
  redrawNodeConnections?: UseConnection['redrawNodeConnections'];
  uid: string;
}

export function usePosition({
  display,
  nodeRef,
  redrawNodeConnections,
  uid,
}: UsePosition): void {
  const position = useCallback(() => {
    d3.select<HTMLDivElement, DiagramNode>(nodeRef.current!)
      .datum(display)
      .attr('style', function (d) {
        const transform = d3.zoomTransform(this);
        const zx = transform.rescaleX(x);
        const zy = transform.rescaleY(y);
        const defaultXY = calculateDefaultPositionOnGraphBasedOnType(this);
        const displayX = d?.x1 || defaultXY.x1;
        const displayX2 = d?.x2 || defaultXY.x2;
        const displayY = d?.y1 || defaultXY.y;
        const tx = zx(displayX);
        const tx2 = zx(displayX2);
        const ty = zy(displayY);

        return `transform: translate(${tx}px, ${ty}px) scale(${transform.k}); width: ${(tx2 - tx) / transform.k}px`;
      });
  }, [display, nodeRef]);

  useWindowResize(() => {
    position();
    redrawNodeConnections && redrawNodeConnections(uid);
  });

  useEffect(() => {
    setXY();
    position();

    zoom.on(`zoom.${uid}`, function () {
      position();
      redrawNodeConnections && redrawNodeConnections(uid);
    });

    return () => {
      zoom.on(`zoom.${uid}`, null);
    };
  }, [display, position, uid, redrawNodeConnections]);
}
