import { SagaIterator } from 'redux-saga';
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { fetchDataCenters as fetchDataCentersApi } from '../../../api/data-centers';
import config from '../../../config';
import { fetchDataCenters, fetchDataCentersSuccess } from './slice';

export function* fetchDataCentersWorker(): SagaIterator {
  try {
    const datacenters: DataCenterFromApi[] = yield call(fetchDataCentersApi);
    yield delay(config.FLICKER_DELAY);
    yield put(fetchDataCentersSuccess(datacenters));
  } catch (e) {}
}

export function* dataCentersWatchers(): SagaIterator {
  yield all([yield takeLatest(fetchDataCenters.type, fetchDataCentersWorker)]);
}
