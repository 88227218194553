import { DEFAULT_DELAY } from './constants';

export const objectEquals = <T extends ObjectRecord>(
  obj: T,
  obj2: T,
): boolean => JSON.stringify(obj) === JSON.stringify(obj2);

export const sortObjectKeys = <T extends ObjectRecord>(obj: T): T => {
  return Object.keys(obj)
    .sort()
    .reduce((result: ObjectRecord, key) => {
      result[key] = obj[key];
      return result;
    }, {}) as T;
};

export function delay(timer = DEFAULT_DELAY): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, timer);
  });
}

export function deepClone<T extends ObjectRecord>(obj: T): T {
  return JSON.parse(JSON.stringify(obj)) as T;
}

export function camelize(str: string): string {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
}
