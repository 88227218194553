import { SelectOption } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledSelect } from '../../../../../../components';

interface FormTemplateConfigProps {
  isLoading: boolean;
  templateOptions: SelectOption<string>[];
}

export function FormTemplateConfig({
  isLoading,
  templateOptions,
}: FormTemplateConfigProps): ReactElement {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <div className="row">
      <div className="col-6">
        <ControlledSelect
          control={control}
          label={t('hardware.edit.titles.templates')}
          horizontal={false}
          options={templateOptions}
          name="inventoryTemplateConfigScript.uid"
          loading={isLoading}
        />
      </div>
    </div>
  );
}
