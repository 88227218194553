import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { call, delay, put } from 'redux-saga/effects';
import { fetchDocumentation } from '../../../../api/documentation';
import config from '../../../../config';
import { setEtag } from '../../etag/slice';
import { fetchDocumentationSuccess } from '../slice';

export function* fetchDocumentationWorker(
  action: PayloadAction<string>,
): SagaIterator {
  const { data, etag }: DocumentationApiResponse = yield call(
    fetchDocumentation,
    action.payload,
  );
  yield delay(config.FLICKER_DELAY);
  yield put(setEtag(etag));
  yield put(fetchDocumentationSuccess(data));
}
