import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { networkFieldsSchema } from '../../../validation';
import { AddNetworkFormProps } from '../components/AddNetworkForm';
import {
  getNetworkAlertMessage,
  getNetworkSubnetOptions,
  handleRoutedChange,
} from '../utils';

type UseAddNetworkFormProps = UseNetworkFormProps<AddNetworkFormProps>;

export function useAddNetworkForm({
  addNetwork,
  availableRouted,
  availableUnrouted,
  networkNames,
}: UseAddNetworkFormProps): UseNetworkForm {
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const [isRouted, setIsRouted] = useState<boolean>(!!availableRouted.length);

  const isNetworkCheckboxDisabled =
    !availableUnrouted.length || !availableRouted.length;

  const alertMessage = getNetworkAlertMessage({
    availableRouted,
    availableUnrouted,
    isNetworkCheckboxDisabled,
  });

  const subnetOptions = getNetworkSubnetOptions({
    availableRouted,
    availableUnrouted,
    isRouted,
  });

  const {
    control,
    errors,
    formState,
    getValues,
    handleSubmit,
    setValue,
    trigger,
  } = useForm<NetworkFormFields>({
    defaultValues: {
      description: '',
      name: '',
      subnet: '',
    },
    mode: 'all',
    resolver: yupResolver(networkFieldsSchema(networkNames)),
  });

  const { isDirty, isValid } = formState;

  const onSubmit: UseNetworkForm['onSubmit'] = (formData) => {
    const payload: NetworkToAdd = {
      description: formData.description,
      inventoryNetwork: { id: formData.subnet },
      name: formData.name,
      topology: { uid: topologyUid },
    };

    addNetwork(payload);
  };

  const onRoutedChange: UseNetworkForm['onRoutedChange'] = (e) => {
    handleRoutedChange({
      getValues,
      newRoutedValue: e.target.checked,
      setIsRouted,
      setValue,
      trigger,
    });
  };

  return {
    alertMessage,
    control,
    errors,
    handleSubmit,
    isDirty,
    isNetworkCheckboxDisabled,
    isRouted,
    isValid,
    onRoutedChange,
    onSubmit,
    subnetOptions,
  };
}
