import { AxiosPromise } from 'axios';
import { api } from '../../api';
import {
  NETWORKS_PATH,
  TOPOLOGIES_PATH,
  INVENTORIES_PATH,
  GLOBAL_PATH,
  INVENTORY_NETWORKS_PATH,
} from '../paths';
import { stripGzipFromEtag } from '../utils';

export async function fetchNetworks(topologyUid: string): Promise<Network[]> {
  const { data } = await api.get<FetchNetworksResponse>(
    `${TOPOLOGIES_PATH}/${topologyUid}${NETWORKS_PATH}`,
  );

  return data._embedded.networks;
}

export async function fetchNetwork(
  networkUid: string,
): Promise<NetworkApiResponse> {
  const { data, headers } = await api.get<Network>(
    `${NETWORKS_PATH}/${networkUid}`,
  );

  return { data, etag: stripGzipFromEtag(headers.etag) };
}

export async function updateNetwork(
  network: NetworkToUpdate,
  etag: string,
): Promise<NetworkApiResponse> {
  const { data, headers } = await api.put<Network>(
    `${NETWORKS_PATH}/${network.uid}`,
    network,
    { headers: { 'If-Match': etag } },
  );

  return { data, etag: headers.etag };
}

export async function addNetwork(
  payload: NetworkToAdd,
): Promise<AddNetworkResponse> {
  const { data, headers } = await api.post<Network>(NETWORKS_PATH, payload);

  return { data, etag: stripGzipFromEtag(headers.etag) };
}

export async function fetchInventoryNetworks(): Promise<InventoryNetwork[]> {
  const { data } = await api.get<FetchInventoryNetworksResponse>(
    `${INVENTORIES_PATH}/${GLOBAL_PATH}/${INVENTORY_NETWORKS_PATH}`,
  );

  return data._embedded.inventoryNetworks;
}

export const deleteNetwork = (networkUid: string): AxiosPromise<void> =>
  api.delete(`${NETWORKS_PATH}/${networkUid}`);
