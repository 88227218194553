import { api } from '..';

export enum Endpoints {
  DataCenters = '/datacenters',
}

export async function fetchDataCenters(): Promise<DataCenterFromApi[]> {
  const { data } = await api.get<FetchDataCentersResponse>(
    Endpoints.DataCenters,
  );

  return data._embedded.datacenters;
}
