import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { call, all, put } from 'redux-saga/effects';
import { setShowInventoryModal } from '../slice';
import { addLicenceWorker } from './add-licence-worker';

export function* addLicencesWorker(
  action: PayloadAction<InventoryLicenceToAdd[]>,
): SagaIterator {
  yield all(
    action.payload.map((licenceToAdd) => call(addLicenceWorker, licenceToAdd)),
  );
  yield put(setShowInventoryModal(false));
}
