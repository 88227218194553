import classnames from 'classnames';
import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PrimaryButton } from '../../../../components/Buttons/PrimaryButton';
import { InventoryModal } from '../../../../components/InventoryModal';
import { useSelectLicences } from '../../hooks/use-select-licences';
import { InventoryLicencesTable } from '../InventoryLicencesTable';
import styles from './InventoryLicencesModal.module.css';
import { InventoryLicencesProps } from '.';

export interface InventoryLicencesModalProps extends InventoryLicencesProps {
  onClose: VoidFunction;
}

export function InventoryLicencesModal({
  adding,
  addLicences,
  fetchInventoryLicences,
  inventoryLicences,
  loading,
  onClose,
}: InventoryLicencesModalProps): ReactElement {
  const { uid } = useParams<EditTopologyParams>();
  const { t } = useTranslation();
  const {
    handleOnSelect,
    handleQuantityChange,
    selectedLicences,
  } = useSelectLicences({ topologyUid: uid });

  useEffect(() => {
    fetchInventoryLicences(uid);
  }, [fetchInventoryLicences, uid]);

  return (
    <InventoryModal
      className={styles.root}
      onClose={onClose}
      show={true}
      title={t('licences.add.title')}
    >
      <div className={classnames('container', styles.container)}>
        <PrimaryButton
          loading={adding}
          disabled={!selectedLicences.length}
          onClick={() => addLicences(selectedLicences)}
        >
          {t('buttons.add')}
        </PrimaryButton>
        <InventoryLicencesTable
          handleOnSelect={handleOnSelect}
          handleQuantityChange={handleQuantityChange}
          inventoryLicences={inventoryLicences}
          loading={loading}
          selectedLicences={selectedLicences}
        />
      </div>
    </InventoryModal>
  );
}
