import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { createTopology } from '../../../api/topologies';
import { MainRoutes } from '../../../constants/routes';
import { useLoadDataCenters } from '../../../redux/entities/data-centers/hooks/use-load-data-centers';
import { addErrorToast, addSuccessToast } from '../../../redux/toast/slice';

interface UseCreateTopology {
  creating: boolean;
  datacenter?: DataCenter;
  demoType: string;
  demoToImport?: InventoryDemo;
  isLoading: boolean;
  onCreate: (fields: TopologyGeneralFields) => void;
  setDatacenter: (datacenter: DataCenter) => void;
  setDemoType: (demoType: string) => void;
  setDemoToImport: (demo?: InventoryDemo) => void;
}

export function useCreateTopology(): UseCreateTopology {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useLoadDataCenters();
  const [creating, setCreating] = useState(false);
  const [demoType, setDemoType] = useState('');
  const [demoToImport, setDemoToImport] = useState<InventoryDemo | undefined>(
    undefined,
  );
  const [datacenter, setDatacenter] = useState<DataCenter | undefined>(
    undefined,
  );
  const { t } = useTranslation();

  async function onCreate(fields: TopologyGeneralFields): Promise<void> {
    if (!datacenter) return;
    if (demoType === 'IMPORT' && !demoToImport) return;

    setCreating(true);

    const payload: TopologyToCreate = {
      ...fields,
      datacenter,
    };

    if (demoToImport) {
      payload.demoId = demoToImport.id;
    }

    try {
      const response = await createTopology(payload);
      history.push(MainRoutes.Edit.replace(':uid', response.uid));
      dispatch(addSuccessToast({ message: t('createTopology.success') }));
    } catch (e) {
      setCreating(false);
      dispatch(addErrorToast({ message: t('createTopology.error') }));
    }
  }

  return {
    creating,
    datacenter,
    demoToImport,
    demoType,
    isLoading,
    onCreate,
    setDatacenter,
    setDemoToImport,
    setDemoType,
  };
}
