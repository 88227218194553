import { connect } from 'react-redux';
import {
  deleteLicence,
  fetchLicences,
  getLicences,
  licencesSelectors,
  reset,
  setLicenceToEdit,
  setShowInventoryModal,
} from '../../../../redux/configuration/licences/slice';
import { RootState } from '../../../../redux/store';
import { Licences } from '.';

export type LicencesProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const mapStateToProps = (state: RootState) => ({
  fetched: getLicences(state).fetched,
  licences: licencesSelectors.selectAll(state),
  loading: getLicences(state).loading,
  showInventoryModal: getLicences(state).showInventoryModal,
});

const mapDispatchToProps = {
  deleteLicence,
  fetchLicences,
  reset,
  setLicenceToEdit,
  setShowInventoryModal,
};

export const LicencesContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Licences);
