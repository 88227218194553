import {
  createAction,
  createSlice,
  PayloadAction,
  createSelector,
} from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { parseDelayOnBlur } from './utils';

interface StartStopOrderState {
  isDirty: boolean;
  order?: StartStopOrder;
}

interface SetDelaySeconds {
  delaySeconds: string;
  position: number;
}

interface DragResult {
  destination?: {
    droppableId: string;
    index: number;
  };
  draggableId: string;
  source: {
    droppableId: string;
    index: number;
  };
}

export const initialState: StartStopOrderState = {
  isDirty: false,
  order: undefined,
};

export const setStartStopOrder = createAction<StartStopOrder>(
  'startStopOrder/setStartStopOrder',
);

export const setDelaySecondsOnBlur = createAction<SetDelaySeconds>(
  'startStopOrder/setDelaySecondsOnBlur',
);

export const setDelaySecondsOnChange = createAction<SetDelaySeconds>(
  'startStopOrder/setDelaySecondsOnChange',
);

export const updatePositions = createAction<DragResult>(
  'startStopOrder/updatePositions',
);

export const setOrdered = createAction<boolean>('startStopOrder/setOrdered');

const slice = createSlice({
  initialState,
  name: 'startStopOrder',
  reducers: {
    setDelaySecondsOnBlur: (
      state,
      { payload }: PayloadAction<SetDelaySeconds>,
    ) => {
      if (!state.order) {
        return;
      }

      state.order.positions = state.order.positions.map((position) => {
        if (position.position === payload.position) {
          return {
            ...position,
            delaySeconds: parseDelayOnBlur(payload.delaySeconds),
          };
        } else {
          return position;
        }
      });
    },
    setDelaySecondsOnChange: (
      state,
      { payload }: PayloadAction<SetDelaySeconds>,
    ) => {
      if (!state.order) {
        return;
      }

      state.order.positions = state.order.positions.map((position) => {
        if (position.position === payload.position) {
          return {
            ...position,
            delaySeconds: payload.delaySeconds || '',
          };
        } else {
          return position;
        }
      });
      state.isDirty = true;
    },
    setOrdered: (state, { payload }: PayloadAction<boolean>) => {
      if (state.order) {
        state.order.ordered = payload;
      }

      state.isDirty = true;
    },
    setStartStopOrder: (state, { payload }: PayloadAction<StartStopOrder>) => {
      const orderedPositions = payload.positions
        .slice()
        .sort((a, b) => a.position - b.position);

      state.order = { ...state.order, ...payload, positions: orderedPositions };
      state.isDirty = false;
    },
    updatePositions: (state, { payload }: PayloadAction<DragResult>) => {
      const isDraggedToSource =
        payload.destination?.droppableId === payload.source.droppableId &&
        payload.destination?.index === payload.source.index;

      if (!state.order || !payload.destination || isDraggedToSource) {
        return;
      }

      const updatedPositions = [...state.order.positions];

      updatedPositions.splice(payload.source.index, 1);
      updatedPositions.splice(
        payload.destination.index,
        0,
        state.order.positions[Number(payload.draggableId)],
      );

      state.order.positions = updatedPositions.map((position, idx) => ({
        ...position,
        position: idx + 1,
      }));
      state.isDirty = true;
    },
  },
});

export const getStartStopOrder = (state: RootState): typeof initialState =>
  state.configuration[slice.name];

export const { reducer: startStopOrderReducer } = slice;

export const startStopOrderSelectors = {
  getStartStopOrder: createSelector(getStartStopOrder, ({ order }) => order),
  getStartStopOrderIsDirty: createSelector(
    getStartStopOrder,
    ({ isDirty }) => isDirty,
  ),
};
