import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { addLicence } from '../../../../api/licences';
import {
  showErrorToastWorker,
  showSuccessToastWorker,
} from '../../../toast/sagas';
import { setEtag } from '../../etag/slice';
import { addLicenceFailure, addLicenceSuccess } from '../slice';

export function* addLicenceWorker(
  licenceToAdd: InventoryLicenceToAdd,
): SagaIterator {
  try {
    const { data, etag }: ApiDataResponse<Licence> = yield call(
      addLicence,
      licenceToAdd,
    );
    yield put(addLicenceSuccess(data));
    yield put(setEtag(etag));
    yield call(showSuccessToastWorker, 'licences.add.success');
  } catch (e) {
    yield put(addLicenceFailure());
    yield call(showErrorToastWorker, 'licences.add.error');
  }
}
