import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export interface DocumentationState {
  documentation?: Documentation;
}

export const initialState: DocumentationState = {};

export const fetchDocumentation = createAction<string>('documentation/fetch');

export const updateDocumentation = createAction<DocumentationFields>(
  'documentation/update',
);

const documentationSlice = createSlice({
  initialState,
  name: 'documentation',
  reducers: {
    clearDocumentation: (state) => {
      state.documentation = initialState.documentation;
    },
    fetchDocumentationSuccess: (
      state,
      action: PayloadAction<Documentation>,
    ) => {
      state.documentation = action.payload;
    },
    updateDocumentationSuccess: (
      state,
      action: PayloadAction<Documentation>,
    ) => {
      state.documentation = action.payload;
    },
  },
});

export const getDocumentation = (state: RootState): DocumentationState =>
  state.configuration.documentation;

export const {
  reducer: documentationReducer,
  actions: {
    clearDocumentation,
    fetchDocumentationSuccess,
    updateDocumentationSuccess,
  },
} = documentationSlice;
