import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import classnames from 'classnames';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { ControlledTextfield, SubmitButton } from '../../../../components';
import { QuantitySelect } from '../../../../components/Table/components/QuantitySelect';
import { MAX_LICENCE_QTY } from '../../../../constants/entities';
import styles from './EditLicenceForm.module.css';

export interface EditLicenceFormProps {
  className?: string;
  licence: Licence;
  loading: boolean;
  onSubmit: ActionCreatorWithPayload<EditLicenceFields, string>;
}

export function EditLicenceForm({
  className,
  licence,
  loading,
  onSubmit,
}: EditLicenceFormProps): ReactElement {
  const { t } = useTranslation();

  const { control, handleSubmit, watch } = useForm<EditLicenceFields>({
    defaultValues: {
      name: licence.inventoryLicense.name,
      quantity: licence.quantity,
    },
  });

  const isChanged = Number(watch('quantity')) === licence.quantity;

  return (
    <form
      className={classnames(styles.root, className)}
      onSubmit={handleSubmit(onSubmit)}
      data-testid="edit-licence-form"
    >
      <ControlledTextfield
        className={styles.readOnly}
        control={control}
        disabled={true}
        label={t('common.name')}
        name="name"
        required={true}
      />
      <Controller
        control={control}
        name="quantity"
        render={({ name: fieldName, onChange, onBlur, value }) => (
          <QuantitySelect
            className={styles.quantity}
            inline={true}
            label={t('common.quantity')}
            maxQuantity={MAX_LICENCE_QTY}
            name={fieldName}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
          />
        )}
      />
      <div className="flex flex-right dbl-margin-top">
        <SubmitButton
          className={styles.submitBtn}
          colour="success"
          disabled={isChanged}
          loading={loading}
        >
          {t('buttons.update')}
        </SubmitButton>
      </div>
    </form>
  );
}
