import { Button } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './PushActionUpdateButton.module.css';
interface PushActionUpdateButtonProps {
  onClick: VoidFunction;
}
export function PushActionUpdateButton({
  onClick,
}: PushActionUpdateButtonProps): ReactElement {
  const { t } = useTranslation();

  return (
    <Button
      className={styles.saveBtn}
      colour="secondary"
      onClick={onClick}
      size="small"
    >
      {t('topologies.push.pushUpdatesButtonText')}
    </Button>
  );
}
