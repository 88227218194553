import React, { ReactElement } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Image, LinkButton } from 'dcloud-shared-ui';
import { Container } from '../../../../components/Container';
import { MainRoutes } from '../../../../constants/routes';
import heroSvg from '../../../../assets/images/app/hero.svg';
import styles from './Hero.module.css';

export function Hero(): ReactElement {
  const { t } = useTranslation();

  return (
    <section className={styles.root}>
      <Container className={styles.container}>
        <div className={classnames('row', styles.content)}>
          <div className="base-padding-right col-md-6">
            <h1 className="display-2 text-weight-300 base-margin-bottom">
              {t('home.heading')}
            </h1>
            <h2
              className={classnames(
                styles.subheading,
                'subheading text-size-20 text-weight-300',
              )}
            >
              {t('home.subHeading')}
            </h2>
            <LinkButton
              to={MainRoutes.Topologies}
              colour="primary"
              className={classnames(styles.getStarted, 'dbl-margin-top')}
              size="large"
            >
              {t('home.getStarted')}
            </LinkButton>
          </div>
          <div className={`col-md-6 ${styles.image}`}>
            <Image alt="Hero image" src={heroSvg} />
          </div>
        </div>
      </Container>
    </section>
  );
}
