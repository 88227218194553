import {
  REMOTE_ACCESSES_PATH,
  REMOTE_ACCESS_PATH,
  TOPOLOGIES_PATH,
} from '../../../api/paths';
import i18n from '../../../i18n';
import { dcloudApi, TAGS } from '../../api';
import { addErrorToast, addSuccessToast } from '../../toast/slice';
import { setEtag } from '../etag/slice';

export const remoteAccessApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    getRemoteAccess: build.query<RemoteAccess, string>({
      keepUnusedDataFor: 0,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { meta } = await queryFulfilled;
          const etag = meta?.response.headers.get('etag');
          etag && dispatch(setEtag(etag));
        } catch (err) {
          const message = err.error.data
            ? err.error.data[0].message
            : i18n.t('remoteAccess.fetch.error');
          dispatch(addErrorToast({ message }));
        }
      },
      providesTags: [TAGS.REMOTE_ACCESS],
      query: (topologyUid) => ({
        url: `${TOPOLOGIES_PATH}/${topologyUid}${REMOTE_ACCESS_PATH}`,
      }),
    }),
    updateRemoteAccess: build.mutation<RemoteAccess, RemoteAccessPayload>({
      invalidatesTags: [TAGS.REMOTE_ACCESS],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addSuccessToast({ message: i18n.t('remoteAccess.update.success') }),
          );
        } catch (err) {
          const message = err.error.data
            ? err.error.data[0].message
            : i18n.t('remoteAccess.update.error');
          dispatch(addErrorToast({ message }));
        }
      },
      query: ({ etag, remoteAccess }) => ({
        body: remoteAccess,
        headers: { 'If-Match': etag },
        method: 'PUT',
        url: `${REMOTE_ACCESSES_PATH}/${remoteAccess.uid}`,
      }),
    }),
  }),
});

export const {
  useGetRemoteAccessQuery,
  useUpdateRemoteAccessMutation,
} = remoteAccessApi;
