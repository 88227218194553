import React, { ReactElement } from 'react';
import classnames from 'classnames';
import { Button, ButtonColours, Heading } from 'dcloud-shared-ui';
import i18n from '../../../i18n';
import { Modal, ModalProps } from '../Modal';
import { PrimaryButton } from '../../Buttons/PrimaryButton';
import styles from './ConfirmModal.module.css';

export interface ConfirmProps {
  onCancel: () => void;
  onConfirm: () => void;
  heading?: string;
  highlightedSelection?: string;
  loading: boolean;
  subHeading?: string;
  showCancel?: boolean;
  testId?: string;
  cancelButtonLabel?: string;
  confirmButtonColour?: ButtonColours;
  confirmButtonLabel?: string;
}

export function Confirm({
  onCancel,
  onConfirm,
  heading = i18n.t('buttons.confirm'),
  highlightedSelection,
  loading,
  subHeading,
  showCancel = true,
  testId,
  cancelButtonLabel = i18n.t('buttons.cancel'),
  confirmButtonColour = 'danger',
  confirmButtonLabel = i18n.t('buttons.confirm'),
}: ConfirmProps): ReactElement {
  const split = !!highlightedSelection
    ? heading?.split(highlightedSelection)
    : null;
  const [firstPart, secondPart] = split && split.length === 2 ? split : [];

  return (
    <div className={styles.root} data-testid={testId}>
      {firstPart && secondPart ? (
        <span
          role="heading"
          aria-level={2}
          className={classnames(
            'text-size-28 half-padding-bottom',
            styles['confirm__heading'],
          )}
        >
          {firstPart}{' '}
          <span className="text-danger">{highlightedSelection}</span>
          {secondPart}
        </span>
      ) : (
        <span
          role="heading"
          aria-level={2}
          className="text-size-28 half-padding-bottom"
        >
          {heading}
        </span>
      )}
      {subHeading && (
        <Heading level="h3" size="18" weight="200">
          {subHeading}
        </Heading>
      )}

      <div className={styles.buttonWrapper}>
        {showCancel && (
          <Button colour="ghost" onClick={onCancel} size="large">
            {cancelButtonLabel}
          </Button>
        )}
        <PrimaryButton
          colour={confirmButtonColour}
          disabled={loading}
          loading={loading}
          onClick={onConfirm}
          size="large"
        >
          {confirmButtonLabel}
        </PrimaryButton>
      </div>
    </div>
  );
}

export type ConfirmModalProps = ConfirmProps & Omit<ModalProps, 'children'>;
export function ConfirmModal({
  clickScreenToClose,
  size,
  show,
  onClose,
  ...rest
}: ConfirmModalProps): ReactElement {
  return (
    <Modal
      size={size}
      show={show}
      onClose={onClose}
      clickScreenToClose={clickScreenToClose}
    >
      <Confirm {...rest} />
    </Modal>
  );
}
