interface updateNodeDisplayReducerProps {
  diagramResponse: DiagramResponse;
  display: DiagramDisplay;
  nodeUid: DiagramNode['uid'];
}

export function updateNodeDisplayReducer({
  diagramResponse,
  display,
  nodeUid,
}: updateNodeDisplayReducerProps): DiagramResponse {
  const newNodes = diagramResponse.nodes.map((node) => {
    if (node.uid !== nodeUid) return node;

    return { ...node, display };
  });

  return { ...diagramResponse, nodes: newNodes };
}
