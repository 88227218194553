import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'dcloud-shared-ui';
import { NotifyPanel } from '../../../../components';

export interface TopologiesErrorProps {
  fetchTopologies: VoidFunction;
}

export function TopologiesError({
  fetchTopologies,
}: TopologiesErrorProps): ReactElement {
  const { t } = useTranslation();

  return (
    <NotifyPanel
      callToAction={
        <Button
          className="base-margin-top base-margin-bottom"
          colour="success"
          onClick={fetchTopologies}
          size="large"
          testId="topologies-error-refresh"
        >
          {t('buttons.refresh')}
        </Button>
      }
      heading={t('topologies.error.heading')}
      subHeading={t('topologies.error.subHeading')}
    />
  );
}
