import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CellProps, Column } from 'react-table';
import { Table } from '../../../../components/Table';
import { setVirtualMachineToConfigure } from '../../../../redux/configuration/virtual-machines/slice';
import { convertMbToGb } from '../../../../pages/EditTopology/configuration/VirtualMachines/Edit/utils';

export interface InventoryVirtualMachinesTableProps {
  loading: boolean;
  inventoryTemplateVirtualMachines: InventoryVirtualMachine[];
}

export const INVENTORY_TEMPLATES_LIMIT = 10;

export function InventoryVirtualMachinesTable({
  loading,
  inventoryTemplateVirtualMachines,
}: InventoryVirtualMachinesTableProps): ReactElement {
  const { t } = useTranslation();
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const dispatch = useDispatch();
  const { url } = useRouteMatch();

  const columns = useMemo<Column<InventoryVirtualMachine>[]>(
    () => [
      {
        // eslint-disable-next-line react/display-name
        Cell: ({ row, value }: CellProps<InventoryVirtualMachine>) => (
          <Link
            to={`${url.replace(':uid', topologyUid)}/configure`}
            onClick={() => dispatch(setVirtualMachineToConfigure(row.original))}
          >
            {value}
          </Link>
        ),
        Header: () => t('inventoryVirtualMachines.table.headings.name'),
        accessor: 'name',
      },
      {
        Header: () => t('inventoryVirtualMachines.table.headings.description'),
        accessor: 'description',
      },
      {
        Cell: ({ value }) => value.length,
        Header: () =>
          t('inventoryVirtualMachines.table.headings.networkInterfaces'),
        accessor: 'networkInterfaces',
        maxWidth: 25,
      },
      {
        Header: () => t('inventoryVirtualMachines.table.headings.cpuQty'),
        accessor: 'cpuQty',
        maxWidth: 25,
      },
      {
        Cell: ({ value }) =>
          value ? convertMbToGb(value, { roundCeil: false }) : null,
        Header: () => t('inventoryVirtualMachines.table.headings.memoryGb'),
        accessor: 'memoryMb',
        maxWidth: 50,
      },
    ],
    [dispatch, t, topologyUid, url],
  );

  return (
    <Table<InventoryVirtualMachine>
      ariaLabel="Inventory VMs Table"
      columns={columns}
      loading={loading}
      data={inventoryTemplateVirtualMachines}
      initialState={{
        pageSize: INVENTORY_TEMPLATES_LIMIT,
        sortBy: [{ desc: false, id: 'name' }],
      }}
    />
  );
}
