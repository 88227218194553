import React, { ReactElement } from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ControlledCheckbox,
  ControlledSelect,
} from '../../../../../../../components';
import { ControlledSelectSkeleton } from '../../../../../../../components/Form/ControlledSelect/ControlledSelectSkeleton';
import { buildNatIpOptions, buildVmNameOptions } from '../../utils';
import { NatRulesAddFormProps } from '../NatRulesAddForm';
import styles from './NatRulesVmInfo.module.css';

interface NatRulesVmInfoProps {
  control: Control<NatRuleFormData>;
  errors: DeepMap<NatRuleFormData, FieldError>;
  setVmIpAddressOptions: NatRulesAddFormProps['setVmIpAddressOptions'];
  vmIpAddressOptions: NatRulesAddFormProps['vmIpAddressOptions'];
  vmTargets: NatRulesAddFormProps['vmTargets'];
}

export function NatRulesVmInfo({
  control,
  errors,
  vmTargets,
  setVmIpAddressOptions,
  vmIpAddressOptions,
}: NatRulesVmInfoProps): ReactElement {
  const { t } = useTranslation();

  if (!vmTargets) {
    return <ControlledSelectSkeleton />;
  }

  return (
    <div className={styles.root} data-testid="natRulesVmInfo">
      <ControlledSelect
        className={styles.targetField}
        options={buildVmNameOptions(vmTargets)}
        control={control}
        label={t('traffic.natRules.table.headings.target')}
        name="name"
        required={true}
        error={errors?.name?.message}
        customOnChange={({ target }) => {
          control.setValue('targetItem.uid', '');
          setVmIpAddressOptions(
            buildNatIpOptions({ vmName: target.value, vmTargets }),
          );
        }}
        testId="natRulesVmTarget"
      />
      <ControlledSelect
        className={styles.ipAddress}
        options={vmIpAddressOptions}
        control={control}
        label={t('traffic.natRules.table.headings.ipAddress')}
        name="targetItem.uid"
        required={true}
        error={errors?.targetItem?.uid?.message}
        customOnChange={({ target }) => {
          control.setValue('targetItem.uid', target.value);
        }}
        testId="natRulesVmIp"
      />
      <ControlledCheckbox
        className={styles.verticalAlign}
        control={control}
        label={t('traffic.natRules.table.headings.eastWestNat')}
        id="eastWest"
      />
    </div>
  );
}
