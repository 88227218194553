import { connect } from 'react-redux';
import {
  fetchNetworks,
  reset,
  networksSelectors,
  setNetworkToDelete,
  setShowAddModal,
  setNetworkToEdit,
  setShowEditModal,
} from '../../../../redux/configuration/networks/slice';
import {
  inventoryNetworksSelectors,
  fetchInventoryNetworks,
} from '../../../../redux/entities/inventory-networks/slice';
import { RootState } from '../../../../redux/store';
import { Networks } from '.';

export type NetworksProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const mapStateToProps = (state: RootState) => ({
  adding: networksSelectors.getAdding(state),
  fetched: networksSelectors.getFetched(state),
  inventoryNetworks: inventoryNetworksSelectors.getInventoryNetworks(state),
  inventoryNetworksFetched: inventoryNetworksSelectors.getFetched(state),
  loading: networksSelectors.getLoading(state),
  maxNumNetworksReached: inventoryNetworksSelectors.getMaxNumReached(state),
  networkToEdit: networksSelectors.getNetworkToEdit(state),
  networks: networksSelectors.selectAll(state),
  showAddModal: networksSelectors.getShowAddModal(state),
  showEditModal: networksSelectors.getShowEditModal(state),
});

const mapDispatchToProps = {
  fetchInventoryNetworks,
  fetchNetworks,
  reset,
  setNetworkToDelete,
  setNetworkToEdit,
  setShowAddModal,
  setShowEditModal,
};

export const NetworksContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Networks);
