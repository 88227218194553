import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { NetworksProps } from '../container';

export function useFetchNetworks(
  fetchNetworks: NetworksProps['fetchNetworks'],
  fetchInventoryNetworks: NetworksProps['fetchInventoryNetworks'],
  inventoryNetworksFetched: NetworksProps['inventoryNetworksFetched'],
  topologyUid: string,
  reset: ActionCreatorWithoutPayload,
): void {
  useEffect(() => {
    fetchNetworks(topologyUid);

    return () => {
      reset();
    };
  }, [fetchNetworks, reset, topologyUid]);

  useEffect(() => {
    if (!inventoryNetworksFetched) {
      fetchInventoryNetworks();
    }
  }, [fetchInventoryNetworks, inventoryNetworksFetched]);
}
