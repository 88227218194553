import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
  createAction,
} from '@reduxjs/toolkit';
import { RootState } from '../store';

interface TopologiesState {
  cloning: boolean;
  deleting: boolean;
  fetched: boolean;
  hasError: boolean;
  loading: boolean;
  topologyToClone?: Topology;
  topologyToDelete?: Topology;
  topologyToEdit?: Topology;
  topologyToSave?: Topology;
  topologyToUpdate?: Topology;
}

const topologiesAdapter = createEntityAdapter<Topology>({
  selectId: ({ uid }) => uid,
});

export const fetchTopologies = createAction('topologies/fetch');
export const cloneTopology = createAction('topologies/clone');
export const deleteTopology = createAction('topologies/delete');

export const initialState = topologiesAdapter.getInitialState<TopologiesState>({
  cloning: false,
  deleting: false,
  fetched: false,
  hasError: false,
  loading: true,
});

export const topologiesSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchTopologies.type, (state) => {
      state.hasError = false;
      state.loading = true;
    });

    builder.addCase(cloneTopology.type, (state) => {
      state.cloning = true;
    });

    builder.addCase(deleteTopology.type, (state) => {
      state.deleting = true;
    });
  },
  initialState,
  name: 'topologies',
  reducers: {
    clearTopologyToClone: (state) => {
      if (state.cloning) return;
      state.topologyToClone = undefined;
    },
    clearTopologyToDelete: (state) => {
      state.topologyToDelete = undefined;
    },
    clearTopologyToSave: (state) => {
      state.topologyToSave = undefined;
      state.topologyToUpdate = undefined;
    },
    cloneTopologyFailure: (state) => {
      state.topologyToClone = undefined;
    },
    deleteTopologyFailure: (state) => {
      state.deleting = false;
      state.topologyToDelete = undefined;
    },
    deleteTopologySuccess: (state) => {
      state.deleting = false;
      state.topologyToDelete = undefined;
    },
    fetchTopologiesError: (state) => {
      state.loading = false;
      state.hasError = true;
    },
    fetchTopologiesSuccess: (state, action: PayloadAction<Topology[]>) => {
      state.fetched = true;
      state.loading = false;
      topologiesAdapter.setAll(state, action.payload);
    },
    reset: () => initialState,
    setTopologyToClone: (state, action: PayloadAction<Topology>) => {
      state.topologyToClone = action.payload;
    },
    setTopologyToDelete: (state, action: PayloadAction<Topology>) => {
      state.topologyToDelete = action.payload;
    },
    setTopologyToEdit: (state, action: PayloadAction<Topology>) => {
      state.topologyToEdit = action.payload;
    },
    setTopologyToSave: (state, action: PayloadAction<Topology>) => {
      state.topologyToSave = action.payload;
    },
    setTopologyToUpdate: (state, action: PayloadAction<Topology>) => {
      state.topologyToUpdate = action.payload;
    },
  },
});

export const {
  reducer: topologiesReducer,
  actions: {
    clearTopologyToClone,
    clearTopologyToDelete,
    clearTopologyToSave,
    cloneTopologyFailure,
    deleteTopologyFailure,
    deleteTopologySuccess,
    fetchTopologiesError,
    fetchTopologiesSuccess,
    reset,
    setTopologyToClone,
    setTopologyToDelete,
    setTopologyToEdit,
    setTopologyToSave,
    setTopologyToUpdate,
  },
} = topologiesSlice;

const getTopologies = (state: RootState) => state.topologies;

export const topologiesSelectors = {
  ...topologiesAdapter.getSelectors<RootState>(getTopologies),
  getHasError: createSelector(getTopologies, ({ hasError }) => hasError),
  getIsCloning: createSelector(getTopologies, ({ cloning }) => cloning),
  getIsDeleting: createSelector(getTopologies, ({ deleting }) => deleting),
  getIsLoading: createSelector(getTopologies, ({ loading }) => loading),
  getTopologiesOrderedByDate: createSelector(
    topologiesAdapter.getSelectors<RootState>(getTopologies).selectAll,
    (topologies) =>
      topologies.sort(
        (a, b) =>
          new Date(b.lastUpdated).getTime() - new Date(a.lastUpdated).getTime(),
      ),
  ),
  getTopologyToClone: createSelector(
    getTopologies,
    ({ topologyToClone }) => topologyToClone,
  ),
  getTopologyToDelete: createSelector(
    getTopologies,
    ({ topologyToDelete }) => topologyToDelete,
  ),
  getTopologyToEdit: createSelector(
    getTopologies,
    ({ topologyToEdit }) => topologyToEdit,
  ),
  getTopologyToSave: createSelector(
    getTopologies,
    ({ topologyToSave }) => topologyToSave,
  ),
  getTopologyToUpdate: createSelector(
    getTopologies,
    ({ topologyToUpdate }) => topologyToUpdate,
  ),
};
