interface FilterItemsProps<T> {
  filterKeys: (keyof T)[];
  list: T[];
  searchText: string;
}

export function filterItems<T extends ObjectRecord>({
  filterKeys,
  list,
  searchText,
}: FilterItemsProps<T>): T[] {
  return list.filter((item) =>
    filterKeys
      .map((key) => item[key])
      .some((value) => {
        switch (typeof value) {
          case 'string':
            return value.toLocaleLowerCase().includes(searchText);
          case 'number':
            return String(value).toLocaleLowerCase().includes(searchText);
          default:
            return false;
        }
      }),
  );
}
