import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface ViewsState {
  isReadOnly: boolean;
}

export const initialState: ViewsState = {
  isReadOnly: false,
};

const slice = createSlice({
  initialState,
  name: 'views',
  reducers: {
    setIsReadOnly: (state, { payload }: PayloadAction<boolean>) => {
      state.isReadOnly = payload;
    },
  },
});

export const getViewsState = (state: RootState): ViewsState =>
  state[slice.name];

export const { reducer: viewsReducer } = slice;
export const { setIsReadOnly } = slice.actions;
