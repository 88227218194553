import React, { ReactElement, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionProps,
} from '../../../../../../../components/Accordion';
import { PrimaryButton } from '../../../../../../../components/Buttons/PrimaryButton';
import { PageHeading } from '../../../../../../../components/PageHeading';
import { vmEditFieldsSchema } from '../../../../../../../validation';
import { GeneralForm } from '../GeneralForm';
import { GuestAutomationForm } from '../GuestAutomationForm';
import {
  addVMNetworkInterfacesToPayload,
  checkVMNetworkInterfaceArraysMatch,
  convertMbToGb,
  prepareRDPForPayload,
  prepareSSHForPayload,
  removeDisplayCredentialsIfDisabled,
  removeDisplayDifferentCredentials,
  removeGuestAutomationIfDisabled,
  removeIsGuestAutomationEnabled,
  removeLinks,
  removeMemoryGb,
  removeUnusedInternalUrls,
  replaceRAEmptyStringsWithNulls,
} from '../../utils';
import { deepClone } from '../../../../../../../utils/helpers';
import { AdvancedSettingsForm } from '../AdvancedSettingsForm';
import { ConnectedNetworking } from '../Networking/container';
import { RemoteAccessFormContainer } from '../RemoteAccessForm/container';

export interface FormProps {
  operatingSystems: OperatingSystem[];
  isUpdating: boolean;
  updateVM: RTKMutation<VmToUpdatePayload, VirtualMachine>;
  vmNames: string[];
  vmToEdit: VirtualMachine;
  vmNetworkingToEdit?: VmNetworkingToEdit;
  rdpEnabledNic?: VMNetworkInterface;
  sshEnabledNic?: VMNetworkInterface;
}

export function Form({
  operatingSystems,
  isUpdating,
  updateVM,
  vmNames,
  vmToEdit,
  vmNetworkingToEdit,
  rdpEnabledNic,
  sshEnabledNic,
}: FormProps): ReactElement {
  const { t } = useTranslation();

  const formMethods = useForm<VmEditFormData>({
    defaultValues: {
      advancedSettings: {
        allDisksNonPersistent: vmToEdit.advancedSettings.allDisksNonPersistent,
        biosUuid: vmToEdit.advancedSettings.biosUuid || '',
        hiddenFromSession: vmToEdit.advancedSettings.hiddenFromSession,
        nameInHypervisor: vmToEdit.advancedSettings.nameInHypervisor || '',
        notStarted: vmToEdit.advancedSettings.notStarted,
      },
      cpuQty: vmToEdit.cpuQty,
      description: vmToEdit.description,
      displayDifferentCredentials:
        'displayCredentials' in vmToEdit.remoteAccess,
      guestAutomation: {
        command: vmToEdit.guestAutomation?.command || '',
        delaySecs: vmToEdit.guestAutomation?.delaySecs || 0,
      },
      isGuestAutomationEnabled: 'guestAutomation' in vmToEdit,
      memoryGb: convertMbToGb(vmToEdit.memoryMb, { roundCeil: false }),
      memoryMb: vmToEdit.memoryMb,
      name: vmToEdit.name,
      nestedHypervisor: vmToEdit.nestedHypervisor,
      osFamily: vmToEdit.osFamily || 'NONE',
      remoteAccess: {
        displayCredentials: {
          password: vmToEdit.remoteAccess.displayCredentials?.password || '',
          username: vmToEdit.remoteAccess.displayCredentials?.username || '',
        },
        internalUrls: [
          {
            description:
              vmToEdit.remoteAccess.internalUrls?.[0]?.description || '',
            location: vmToEdit.remoteAccess.internalUrls?.[0]?.location || '',
          },
          {
            description:
              vmToEdit.remoteAccess.internalUrls?.[1]?.description || '',
            location: vmToEdit.remoteAccess.internalUrls?.[1]?.location || '',
          },
        ],
        password: vmToEdit.remoteAccess.password || '',
        rdp: {
          autoLogin: rdpEnabledNic?.rdp?.autoLogin || false,
          vmNetworkInterface: {
            isEnabled: rdpEnabledNic?.rdp?.enabled || false,
            uid: rdpEnabledNic?.uid || '',
          },
        },
        ssh: {
          vmNetworkInterface: {
            isEnabled: sshEnabledNic?.ssh?.enabled || false,
            uid: sshEnabledNic?.uid || '',
          },
        },
        username: vmToEdit.remoteAccess.username || '',
        vmConsoleEnabled: vmToEdit.remoteAccess.vmConsoleEnabled,
      },
    },
    mode: 'all',
    resolver: yupResolver(vmEditFieldsSchema(vmNames)),
  });

  const handleUpdateClicked: SubmitHandler<VmEditFormData> = async (
    formData,
  ) => {
    const vmToEditCopy = deepClone(vmToEdit);
    const formDataCopy = deepClone(formData);

    let vmToUpdatePayload: VmToUpdatePayload = {
      ...vmToEditCopy,
      ...formDataCopy,
      osFamily: formData.osFamily === 'NONE' ? undefined : formData.osFamily,
    };
    removeLinks(vmToUpdatePayload);
    removeGuestAutomationIfDisabled(vmToUpdatePayload);
    removeIsGuestAutomationEnabled(vmToUpdatePayload);
    removeDisplayCredentialsIfDisabled(vmToUpdatePayload);
    removeDisplayDifferentCredentials(vmToUpdatePayload);
    replaceRAEmptyStringsWithNulls(vmToUpdatePayload);
    removeMemoryGb(vmToUpdatePayload);
    prepareRDPForPayload(vmToUpdatePayload);
    prepareSSHForPayload(vmToUpdatePayload);
    vmToUpdatePayload = removeUnusedInternalUrls(vmToUpdatePayload);
    if (vmNetworkingToEdit) {
      vmToUpdatePayload = addVMNetworkInterfacesToPayload(
        vmToUpdatePayload,
        JSON.parse(JSON.stringify(vmNetworkingToEdit)),
      );
    }

    await updateVM(vmToUpdatePayload)
      .unwrap()
      .then(() => formMethods.reset(formData))
      .catch(() => {
        return;
      });
  };

  const accordionRows = useMemo<AccordionProps['rows']>(
    () => [
      {
        children: <GeneralForm operatingSystems={operatingSystems} />,
        title: t('virtualMachines.edit.sectionTitles.general'),
      },
      {
        children: <ConnectedNetworking />,
        title: t('virtualMachines.edit.sectionTitles.networking'),
      },
      {
        children: <RemoteAccessFormContainer />,
        title: t('virtualMachines.edit.sectionTitles.remoteAccess'),
      },
      {
        children: <GuestAutomationForm />,
        title: t('virtualMachines.edit.sectionTitles.guestAutomation'),
      },
      {
        children: <AdvancedSettingsForm />,
        title: t('virtualMachines.edit.sectionTitles.advancedSettings'),
      },
    ],
    [operatingSystems, t],
  );

  const { isDirty, isValid } = formMethods.formState;

  const hasFormChanged =
    isDirty ||
    !checkVMNetworkInterfaceArraysMatch(
      vmToEdit.vmNetworkInterfaces,
      vmNetworkingToEdit,
    );

  const disableUpdateButton = !hasFormChanged || !isValid;

  return (
    <section className="half-padding-left">
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleUpdateClicked)}>
          <PageHeading
            ctaBtn={
              <PrimaryButton
                type="submit"
                colour="success"
                disabled={disableUpdateButton}
                loading={isUpdating}
              >
                {t('buttons.update')}
              </PrimaryButton>
            }
            pageTitle={t('virtualMachines.edit.title')}
            withBackBtn={true}
          />
          <Accordion rows={accordionRows} />
        </form>
      </FormProvider>
    </section>
  );
}
