import React, { ReactElement, useCallback } from 'react';
import { Alert } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { PageHeading } from '../../../../../components/PageHeading';
import { PrimaryButton } from '../../../../../components/Buttons/PrimaryButton';
import { AddVirtualMachinesRoutes } from '../Add/VirtualMachinesAddRouter';
import { VirtualMachinesRoutes } from '../VirtualMachinesRouter';
import { VirtualMachinesTable } from '../../../../../entities/virtual-machines/components/VirtualMachinesTable';
import { DeleteVMModal } from '../../../../../entities/virtual-machines/components/DeleteVMModal';
import { useFetchVms } from './hooks/use-fetch-vms';
import { VirtualMachinesProps } from './container';

export function VirtualMachines({
  setVirtualMachineToDelete,
  virtualMachineToDelete,
}: VirtualMachinesProps): ReactElement {
  const { t } = useTranslation();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { isFetching, data: virtualMachines } = useFetchVms();

  const onDeleteClick = useCallback(
    (virtualMachine: VirtualMachine) => {
      setVirtualMachineToDelete(virtualMachine);
    },
    [setVirtualMachineToDelete],
  );

  return (
    <section className="half-padding-left">
      <PageHeading
        pageTitle={t('virtualMachines.name')}
        ctaBtn={
          <PrimaryButton
            onClick={() =>
              history.push(
                `${url}${VirtualMachinesRoutes.Add}${AddVirtualMachinesRoutes.Templates}`,
              )
            }
          >
            {t('buttons.new')}
          </PrimaryButton>
        }
      />
      {(isFetching || !!virtualMachines.length) && (
        <VirtualMachinesTable
          onDeleteClick={onDeleteClick}
          virtualMachines={virtualMachines || []}
          loading={isFetching}
        />
      )}

      {!isFetching && virtualMachines.length < 1 && (
        <Alert colour="info">{t('virtualMachines.empty.alertText')}</Alert>
      )}

      {virtualMachineToDelete && (
        <DeleteVMModal
          setVirtualMachineToDelete={setVirtualMachineToDelete}
          virtualMachine={virtualMachineToDelete}
        />
      )}
    </section>
  );
}
