import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { fetchInventoryNetworks as fetchInventoryNetworksApi } from '../../../../api/networks';
import { showErrorToastWorker } from '../../../toast/sagas';
import {
  fetchInventoryNetworks,
  fetchInventoryNetworksSuccess,
} from '../slice';

export function* fetchInventoryNetworksWorker(): SagaIterator {
  try {
    const inventoryNetworks: InventoryNetwork[] = yield call(
      fetchInventoryNetworksApi,
    );
    yield put(fetchInventoryNetworksSuccess(inventoryNetworks));
  } catch (e) {
    yield call(showErrorToastWorker, 'inventoryNetworks.fetch.error');
  }
}

export function* fetchInventoryNetworksWatcher(): SagaIterator {
  yield all([
    yield takeLatest(fetchInventoryNetworks.type, fetchInventoryNetworksWorker),
  ]);
}
