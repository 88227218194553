import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CenteredSpinner, Modal } from '../../../../components';
import { ConnectedEditNetworkForm } from '../EditNetworkForm';

export interface EditNetworkModalProps {
  network?: Network;
  onClose: VoidFunction;
}

export function EditNetworkModal({
  network,
  onClose,
}: EditNetworkModalProps): ReactElement {
  const { t } = useTranslation();

  return (
    <Modal
      clickScreenToClose={true}
      show={true}
      onClose={onClose}
      title={t('networks.form.edit.title')}
    >
      {network ? (
        <ConnectedEditNetworkForm networkToEdit={network} />
      ) : (
        <CenteredSpinner />
      )}
    </Modal>
  );
}
