import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '../../../../components';

export interface SaveCustomContentModalProps {
  clearTopologyToSave: VoidFunction;
  isSaving: boolean;
  saveTopology: (topologyUid: string) => null;
  updateDemo: (demo?: DemosUpdatePayload) => null;
  topologyToSave: Topology;
  topologyToUpdate: Topology;
}

export function SaveCustomContentModal({
  clearTopologyToSave,
  isSaving,
  saveTopology,
  updateDemo,
  topologyToSave,
  topologyToUpdate,
}: SaveCustomContentModalProps): ReactElement | null {
  const { t } = useTranslation();
  const topology = topologyToSave ? topologyToSave : topologyToUpdate;
  const action: string = topologyToSave ? 'push' : 'updatePush';

  if (!topology) {
    return null;
  }

  function handleConfirm() {
    if (topologyToSave) {
      saveTopology(topology.uid);
      return;
    }
    updateDemo({ id: topology.demoId!, topology: topology });
  }

  return (
    <ConfirmModal
      heading={t(`topologies.${action}.modalHeading`)}
      subHeading={t(`topologies.${action}.modalSubheading`).replace(
        ':topology',
        topology.name,
      )}
      loading={isSaving}
      onCancel={clearTopologyToSave}
      onClose={clearTopologyToSave}
      onConfirm={handleConfirm}
      confirmButtonLabel={
        topologyToSave ? t('buttons.save') : t('buttons.push')
      }
      confirmButtonColour="success"
      show={!!topology}
      size="small"
      testId="topology-save-modal"
    />
  );
}
