import { yupResolver } from '@hookform/resolvers/yup';
import { SelectOption } from 'dcloud-shared-ui';
import React, { ReactElement, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { PLEASE_SELECT_OPTION } from '../../../../../../../constants/form';
import {
  useCreateIpNatRuleMutation,
  useCreateVmNatRuleMutation,
} from '../../../../../../../redux/configuration/traffic/nat-rules/api';
import { useGetVmNicTargetsQuery } from '../../../../../../../redux/configuration/traffic/api';
import { natRulesSchema } from '../../../../../../../validation';
import { buildNatRulePayload } from '../../utils';
import { NatRulesAddForm } from '.';

export function NatRulesAddFormContainer(): ReactElement {
  const { uid: topologyUid } = useParams<TopologyUid>();
  const {
    control,
    errors,
    formState,
    handleSubmit,
    reset: resetForm,
    watch,
  } = useForm<NatRuleFormData>({
    defaultValues: {
      eastWest: true,
      ipAddress: '',
      name: '',
      targetItem: { uid: '' },
      type: '',
    },
    mode: 'all',
    resolver: yupResolver(natRulesSchema),
  });
  const [vmIpAddressOptions, setVmIpAddressOptions] = useState<
    SelectOption<string>[]
  >([PLEASE_SELECT_OPTION]);
  const [
    createIpNat,
    { error: ipError, isLoading: isCreatingIp },
  ] = useCreateIpNatRuleMutation();
  const [
    createVmNat,
    { error: vmError, isLoading: isCreatingVm },
  ] = useCreateVmNatRuleMutation();
  const { data: vmTargetsData } = useGetVmNicTargetsQuery(topologyUid);

  const error = ipError || vmError;
  const isCreating = isCreatingIp || isCreatingVm;
  const natType = watch('type');

  const handleCreate: SubmitHandler<NatRuleFormData> = async (formData) => {
    const payload = buildNatRulePayload({ formData, topologyUid });
    if (formData.type === 'IP') {
      await createIpNat(payload);
    } else if (formData.type === 'VM_NIC') {
      await createVmNat(payload);
    }

    !error && resetForm();
  };

  return (
    <NatRulesAddForm
      control={control}
      errors={errors}
      formState={formState}
      handleCreate={handleCreate}
      handleSubmit={handleSubmit}
      isCreating={isCreating}
      natType={natType}
      vmTargets={vmTargetsData?.vmNicTargets}
      vmIpAddressOptions={vmIpAddressOptions}
      setVmIpAddressOptions={setVmIpAddressOptions}
    />
  );
}
