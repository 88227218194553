import { Alert, SelectOption } from 'dcloud-shared-ui';
import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { Control, SubmitHandler, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledSelect } from '../../../../../../../components';
import { PrimaryButton } from '../../../../../../../components/Buttons/PrimaryButton';
import { FormWrapper } from '../../../../../../../components/FormWrapper';
import { buildNatTypesOptions } from '../../utils';
import { NatRulesIpInfo } from '../NatRulesIpInfo';
import { NatRulesVmInfo } from '../NatRulesVmInfo';
import styles from './NatRulesAddForm.module.css';

export interface NatRulesAddFormProps {
  control: Control<NatRuleFormData>;
  errors: UseFormMethods['errors'];
  formState: UseFormMethods['formState'];
  isCreating: boolean;
  natType?: NatRuleFormData['type'];
  handleCreate: SubmitHandler<NatRuleFormData>;
  handleSubmit: UseFormMethods['handleSubmit'];
  vmIpAddressOptions: SelectOption<string>[];
  vmTargets?: VmNicTarget[];
  setVmIpAddressOptions: Dispatch<SetStateAction<SelectOption<string>[]>>;
}

export function NatRulesAddForm({
  control,
  errors,
  formState,
  handleCreate,
  handleSubmit,
  isCreating,
  natType,
  setVmIpAddressOptions,
  vmIpAddressOptions,
  vmTargets,
}: NatRulesAddFormProps): ReactElement | null {
  const { t } = useTranslation();
  const { isDirty, isValid } = formState;

  return (
    <FormWrapper className={styles.root} onSubmit={handleSubmit(handleCreate)}>
      <ControlledSelect
        className={styles.natTypeInput}
        control={control}
        options={buildNatTypesOptions()}
        label={t('traffic.natRules.table.headings.type')}
        name="type"
        required={true}
        error={errors?.type?.message}
        testId="natRulesSelect"
      />
      {natType === 'IP' && <NatRulesIpInfo control={control} errors={errors} />}
      {natType === 'VM_NIC' && !!vmTargets?.length && (
        <NatRulesVmInfo
          control={control}
          errors={errors}
          setVmIpAddressOptions={setVmIpAddressOptions}
          vmIpAddressOptions={vmIpAddressOptions}
          vmTargets={vmTargets}
        />
      )}
      {natType === 'VM_NIC' && !vmTargets?.length && (
        <Alert className={styles.alert} colour="info">
          {t('traffic.natRules.noConfiguredVms')}
        </Alert>
      )}
      {natType === 'VM_NIC' && !!vmTargets?.length && (
        <PrimaryButton
          disabled={!isDirty || !isValid}
          loading={isCreating}
          type="submit"
          testId="natRulesAddButton"
        >
          {t('buttons.add')}
        </PrimaryButton>
      )}
    </FormWrapper>
  );
}
