import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useErrorToast } from '../../../../../../hooks/toasts/use-error-toast';
import { useGetDemoVmsQuery } from '../../../../../../redux/configuration/virtual-machines/api';

interface UseFetchDemoVms {
  data: InventoryVirtualMachine[];
  isFetching: boolean;
}

export function useFetchDemoVms(demoId: string): UseFetchDemoVms {
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const { t } = useTranslation();
  const { showErrorToast } = useErrorToast();

  const { data = [], isError, isFetching } = useGetDemoVmsQuery({
    demoId,
    topologyUid,
  });

  isError && showErrorToast(t('virtualMachines.fetch.errors.many'));

  return {
    data,
    isFetching,
  };
}
