import React, { ReactElement, useMemo, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, PluginHook, CellProps, Hooks } from 'react-table';
import { SelectOption } from 'dcloud-shared-ui';
import { Table } from '../../../../../../../components/Table';
import { NetworkSelect } from '../NetworkSelect';
import { Actions } from '../Actions';
import styles from './ConfigureTable.module.css';

export interface ConfigureTableProps {
  interfaces: NetworkToConfigure[];
  setNetwork: (interfaceName: string, networkUid: string) => void;
  deleteNIC: (interfaceName: string) => void;
  options: SelectOption<string>[];
}

export function ConfigureTable({
  interfaces,
  setNetwork,
  deleteNIC,
  options = [],
}: ConfigureTableProps): ReactElement {
  const { t } = useTranslation();
  const columns = useMemo<Column<NetworkToConfigure>[]>(
    () => [
      {
        Header: () => t('virtualMachines.configure.table.headings.name'),
        accessor: 'name',
        minWidth: 150,
      },
      {
        Header: () => t('virtualMachines.configure.table.headings.ipAddress'),
        accessor: 'ipAddress',
      },
    ],
    [t],
  );

  const pluginHooks = useMemo<PluginHook<NetworkToConfigure>[]>(
    () => [
      (hooks: Hooks<NetworkToConfigure>) => {
        hooks.visibleColumns.push((columns) => [
          ...columns,
          {
            // eslint-disable-next-line react/display-name
            Cell: ({ row: { original } }: CellProps<NetworkToConfigure>) => (
              <NetworkSelect
                options={options}
                onChange={({ target }: ChangeEvent<HTMLSelectElement>) => {
                  setNetwork(original.name, target.value);
                }}
                value={original.network?.uid}
              />
            ),
            Header: () => t('virtualMachines.configure.table.headings.network'),
            accessor: 'inventoryNetworkId',
          },
        ]);
      },
    ],
    [options, setNetwork, t],
  );

  function renderActions(networkConfig: NetworkToConfigure): ReactElement {
    return (
      <Actions
        canDelete={interfaces.length > 1}
        name={networkConfig.name}
        onDelete={() => deleteNIC(networkConfig.name)}
      />
    );
  }

  return (
    <Table<NetworkToConfigure>
      className={styles.root}
      columns={columns}
      loading={false}
      data={interfaces}
      initialState={{ sortBy: [{ desc: false, id: 'name' }] }}
      pluginHooks={pluginHooks}
      actions={renderActions}
    />
  );
}
