import React, { ReactElement } from 'react';
import { useScrollToTop } from '../../hooks/use-scroll-to-top';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { Hero } from './components/Hero';
import { Tiles } from './components/Tiles';

export function Home(): ReactElement {
  useScrollToTop();

  return (
    <DefaultLayout>
      <Hero />
      <Tiles />
    </DefaultLayout>
  );
}
