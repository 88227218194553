import React, { ReactElement, useMemo } from 'react';
import { TableDraggableRows, TableDraggableRowsProps } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import styles from './VMStopTable.module.css';

export interface VMStopTableProps {
  handleOnRowDrag: TableDraggableRowsProps<
    StartStopOrderPosition
  >['handleOnRowDrag'];
  positions: StartStopOrderPosition[];
}

export function VMStopTable({
  handleOnRowDrag,
  positions,
}: VMStopTableProps): ReactElement {
  const { t } = useTranslation();

  const columns = useMemo<Column<StartStopOrderPosition>[]>(
    () => [
      {
        Header: () => t('startStopOrder.vmStop.table.headings.name'),
        accessor: (row) => row.vm?.name,
        disableSortBy: true,
        id: 'name',
      },
    ],
    [t],
  );

  return (
    <TableDraggableRows<StartStopOrderPosition>
      className={styles.root}
      columns={columns}
      data={positions}
      handleOnRowDrag={handleOnRowDrag}
    />
  );
}
