import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { selectIPAddressOptionsFromVM } from '../../../../pages/EditTopology/configuration/Traffic/InboundProxyRules/utils';
import { RootState } from '../../../store';

interface InboundProxyRulesState {
  ipAddressOptionsByVM?: VmsWithValidIPAddressOptions;
  selectedVM?: string;
  inboundProxyRuleToDelete?: InboundProxyRule;
}

export const initialState: InboundProxyRulesState = {
  inboundProxyRuleToDelete: undefined,
  ipAddressOptionsByVM: undefined,
  selectedVM: undefined,
};

const slice = createSlice({
  initialState,
  name: 'inboundProxyRules',
  reducers: {
    setInboundProxyRuleToDelete: (
      state,
      action: PayloadAction<InboundProxyRule | undefined>,
    ) => {
      state.inboundProxyRuleToDelete = action.payload;
    },
    setIpAddressOptionsByVm: (
      state,
      action: PayloadAction<VmsWithValidIPAddressOptions | undefined>,
    ) => {
      state.ipAddressOptionsByVM = action.payload;
    },
    setSelectedVM: (state, action: PayloadAction<string | undefined>) => {
      state.selectedVM = action.payload;
    },
  },
});

export const getInboundProxyRules = (
  state: RootState,
): InboundProxyRulesState => state.configuration[slice.name];

export const { reducer: inboundProxyRulesReducer } = slice;
export const {
  setInboundProxyRuleToDelete,
  setIpAddressOptionsByVm,
  setSelectedVM,
} = slice.actions;

export const inboundProxyRulesSelectors = {
  getIpAddressOptionsForVM: createSelector(
    getInboundProxyRules,
    ({ ipAddressOptionsByVM, selectedVM }) =>
      selectIPAddressOptionsFromVM(ipAddressOptionsByVM, selectedVM),
  ),
};
