import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { fetchLicence } from '../../../../api/licences';
import { showErrorToastWorker } from '../../../toast/sagas';
import { setEtag } from '../../etag/slice';
import { fetchLicenceFailure, fetchLicenceSuccess } from '../slice';

export function* fetchLicenceWorker({
  payload,
}: PayloadAction<string>): SagaIterator {
  try {
    const { data, etag }: ApiDataResponse<Licence> = yield call(
      fetchLicence,
      payload,
    );
    yield put(fetchLicenceSuccess(data));
    yield put(setEtag(etag));
  } catch (e) {
    yield put(fetchLicenceFailure());
    yield call(showErrorToastWorker, 'licences.fetch.error');
  }
}
