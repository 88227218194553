import { Alert } from 'dcloud-shared-ui';
import React, { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AddNetworkModal } from '../../../../entities/networks/components/AddNetworkModal';
import { ConnectedDeleteNetworkModal } from '../../../../entities/networks/components/DeleteNetworkModal/container';
import { EditNetworkModal } from '../../../../entities/networks/components/EditNetworkModal';
import { NetworksTable } from '../../../../entities/networks/components/NetworksTable';
import { PageHeadingWithCTA } from '../../components/PageHeadingWithCTA/PageHeadingWithCTA';
import { NetworksProps } from './container';
import { useFetchNetworks } from './hooks/use-fetch-networks';

export function Networks({
  fetchNetworks,
  fetchInventoryNetworks,
  fetched,
  inventoryNetworksFetched,
  loading,
  maxNumNetworksReached,
  networkToEdit,
  networks,
  reset,
  setNetworkToDelete,
  setNetworkToEdit,
  setShowAddModal,
  setShowEditModal,
  showAddModal,
  showEditModal,
}: NetworksProps): ReactElement {
  const { t } = useTranslation();
  const { uid: topologyUid } = useParams<EditTopologyParams>();

  useFetchNetworks(
    fetchNetworks,
    fetchInventoryNetworks,
    inventoryNetworksFetched,
    topologyUid,
    reset,
  );

  const onDeleteClick = useCallback(
    (network: Network) => {
      setNetworkToDelete(network);
    },
    [setNetworkToDelete],
  );

  const onEditClick = useCallback(
    (uid: string) => {
      setNetworkToEdit(uid);
    },
    [setNetworkToEdit],
  );

  return (
    <div className="half-padding-left">
      <PageHeadingWithCTA
        buttonLabel={t('buttons.add')}
        buttonAriaLabel="Add Button"
        disableButton={maxNumNetworksReached}
        onButtonClick={() => setShowAddModal(true)}
        pageTitle={t('networks.name')}
      />
      {maxNumNetworksReached && inventoryNetworksFetched && (
        <Alert colour="info">{t('networks.form.maxNumNetworksReached')}</Alert>
      )}
      <NetworksTable
        networks={networks}
        loading={!fetched || loading}
        onDeleteClick={onDeleteClick}
        onEditClick={onEditClick}
      />
      {showAddModal && (
        <AddNetworkModal onClose={() => setShowAddModal(false)} />
      )}
      {showEditModal && (
        <EditNetworkModal
          network={networkToEdit}
          onClose={() => setShowEditModal(false)}
        />
      )}
      <ConnectedDeleteNetworkModal />
    </div>
  );
}
