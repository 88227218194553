import React, { ReactElement, useState } from 'react';
import { Alert, LinkButton } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { TopologiesList } from '../TopologiesList';
import { TopologiesTable } from '../TopologiesTable';
import { TOPOLOGIES_LIMIT, TopologyViewTypes } from '../../constants';
import { ViewToggle } from '../ViewToggle';
import { useFilter } from '../../../../hooks/use-filter';
import { Search } from '../../../../components/Search';
import { MainRoutes } from '../../../../constants/routes';
import { PropsFromRedux } from './container';
import styles from './TopologiesView.module.css';

export interface TopologiesViewProps extends PropsFromRedux {
  topologies: Topology[];
  onClone: TopologyAction;
  onDelete: TopologyAction;
  onPush: TopologyAction;
  onUpdate: TopologyAction;
}

export function TopologiesView({
  onClone,
  onDelete,
  onPush,
  onUpdate,
  topologies,
}: TopologiesViewProps): ReactElement {
  const { t } = useTranslation();
  console.log(process.env.REACT_APP_API_URL);

  const { filteredItems, handleSearchChange, searchValue } = useFilter({
    filterKeys: ['name', 'description', 'datacenter'],
    items: topologies,
  });

  const [viewType, setViewType] = useState(
    filteredItems.length > TOPOLOGIES_LIMIT
      ? TopologyViewTypes.Table
      : TopologyViewTypes.List,
  );

  const Component =
    viewType === TopologyViewTypes.List ? TopologiesList : TopologiesTable;

  return (
    <>
      <div className={classnames('container dbl-padding-bottom', styles.root)}>
        <div className="flex flex-between flex-middle">
          <LinkButton
            to={MainRoutes.CreateTopology}
            className="base-margin-top base-margin-bottom"
            size="large"
            colour="success"
            testId="topologies-create"
          >
            {t('buttons.create')}
          </LinkButton>
          <div className="flex flex-right">
            <Search
              className={classnames('base-padding', styles.search)}
              id="topologies-search-input"
              onChange={(e) => handleSearchChange(e.target.value)}
              placeholder={t('common.search')}
              value={searchValue}
            />
            <ViewToggle view={viewType} toggleView={setViewType} />
          </div>
        </div>

        {filteredItems.length ? (
          <Component
            topologies={filteredItems}
            onDelete={onDelete}
            onClone={onClone}
            onPush={onPush}
            onUpdate={onUpdate}
          />
        ) : (
          <Alert>{t('topologies.filter.empty')}</Alert>
        )}
      </div>
    </>
  );
}
