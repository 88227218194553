import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { generalFieldsSchema } from '../../../../../../validation';
import {
  ControlledTextarea,
  ControlledTextfield,
} from '../../../../../../components/Form';
import { PrimaryButton } from '../../../../../../components/Buttons/PrimaryButton';

export interface GeneralFormProps {
  defaultValues?: TopologyGeneralFields;
  onHandleSubmit: (data: TopologyGeneralFields) => void;
  updating: boolean;
}

const DEFAULT_VALUES: TopologyGeneralFields = {
  description: '',
  name: '',
  notes: '',
};

export function GeneralForm({
  defaultValues = DEFAULT_VALUES,
  onHandleSubmit,
  updating,
}: GeneralFormProps): ReactElement {
  const { t } = useTranslation();
  const { handleSubmit, errors, control, formState } = useForm<
    TopologyGeneralFields
  >({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(generalFieldsSchema),
  });

  const onSubmit = handleSubmit((data) => {
    onHandleSubmit(data);
  });

  const { isDirty, isValid } = formState;

  return (
    <form onSubmit={onSubmit}>
      <ControlledTextfield<TopologyGeneralFields>
        control={control}
        defaultValue={defaultValues.name}
        error={errors?.name?.message}
        label={t('general.form.name.label')}
        labelClass="col-3"
        name="name"
        required={true}
      />
      <ControlledTextarea<TopologyGeneralFields>
        control={control}
        defaultValue={defaultValues.description}
        error={errors?.description?.message}
        label={t('general.form.description.label')}
        labelClass="col-3"
        name="description"
        rows={4}
        required={true}
      />
      <ControlledTextarea<TopologyGeneralFields>
        defaultValue={defaultValues.notes}
        control={control}
        label={t('general.form.notes.label')}
        labelClass="col-3"
        name="notes"
        rows={4}
      />
      <PrimaryButton
        disabled={!isDirty || !isValid}
        className="pull-right dbl-margin-top"
        testId="general-form-submit"
        loading={updating}
        type="submit"
        colour="success"
      >
        {t('buttons.save')}
      </PrimaryButton>
    </form>
  );
}
