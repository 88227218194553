import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetExternalDnsRecordsQuery } from '../../../../../redux/configuration/traffic/external-dns/api';
import { useGetNatRulesQuery } from '../../../../../redux/configuration/traffic/nat-rules/api';
import { useGetInventoryDnsAssetsQuery } from '../../../../../redux/configuration/traffic/api';
import { ExternalDnsLoader } from './ExternalDnsLoader';
import { ExternalDNS } from '.';

export function ExternalDnsContainer(): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const { data, isError, isLoading } = useGetExternalDnsRecordsQuery(
    topologyUid,
  );
  const {
    data: natRules,
    isError: isNatError,
    isLoading: isLoadingNat,
  } = useGetNatRulesQuery(topologyUid);
  const {
    data: dnsAssets,
    isLoading: isLoadingDns,
  } = useGetInventoryDnsAssetsQuery(topologyUid);

  if (isLoading || isLoadingNat || isLoadingDns) {
    return <ExternalDnsLoader />;
  }

  return (
    <ExternalDNS
      externalDNSRecord={data?.externalDnsRecords}
      isError={isError || isNatError}
      isLoading={isLoading}
      natRules={natRules?.natRules}
      dnsAssets={dnsAssets?.inventoryDnsAssets}
    />
  );
}

export interface ExternalDnsRTKProps {
  isError: boolean;
  isLoading: boolean;
  externalDNSRecord?: ExternalDnsRecord[];
  natRules?: NatRule[];
  dnsAssets?: InventoryDnsAsset[];
}

export const ConnectExternalDns = connect()(ExternalDnsContainer);
