import React, { ReactElement } from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { CodeSplittedDiagram } from '../Views/Diagram/LazyLoad';
import { Configuration } from './configuration';

export enum EditTopologyRoutes {
  Configuration = '/configuration',
  Diagram = '/diagram',
}

export function EditTopologyRouter(): ReactElement {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${match.path}${EditTopologyRoutes.Configuration}`}
        component={Configuration}
      />
      <Route
        path={`${match.path}${EditTopologyRoutes.Diagram}`}
        component={CodeSplittedDiagram}
      />
      <Redirect to={`${match.path}${EditTopologyRoutes.Configuration}`} />
    </Switch>
  );
}
