import { connect } from 'react-redux';
import { virtualMachinesSelectors } from '../../../../../redux/configuration/virtual-machines/slice';
import { RootState } from '../../../../../redux/store';
import { VirtualMachinesConfigure } from './Configure';

const mapStateToProps = (state: RootState) => ({
  interfaces: virtualMachinesSelectors.getVirtualMachineToConfigure(state)
    ?.networkInterfaces,
});

const mapDispatchToProps = {};

export const ConfigureContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VirtualMachinesConfigure);

export type VirtualMachinesConfigureProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
