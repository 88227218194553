import React, { ReactElement } from 'react';
import { InventoryVirtualMachinesTable } from '../../../../../../../entities/virtual-machines/components/InventoryVirtualMachinesTable';

interface TemplateTabContentProps {
  isFetching: boolean;
  inventoryTemplateVirtualMachines: InventoryVirtualMachine[];
}

export function TemplateTabContent({
  isFetching,
  inventoryTemplateVirtualMachines,
}: TemplateTabContentProps): ReactElement {
  return (
    <InventoryVirtualMachinesTable
      loading={isFetching}
      inventoryTemplateVirtualMachines={inventoryTemplateVirtualMachines}
    />
  );
}
