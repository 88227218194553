import { connect } from 'react-redux';
import {
  setTopologyToClone,
  setTopologyToDelete,
  setTopologyToSave,
  setTopologyToUpdate,
  topologiesSelectors,
} from '../../../../redux/topologies/slice';
import { RootState } from '../../../../redux/store';
import { TopologiesView } from '.';

export type PropsFromRedux = ReturnType<typeof mapStateToProps>;

const mapStateToProps = (state: RootState) => ({
  topologies: topologiesSelectors.getTopologiesOrderedByDate(state),
});

const mapDispatchToProps = {
  onClone: setTopologyToClone,
  onDelete: setTopologyToDelete,
  onPush: setTopologyToSave,
  onUpdate: setTopologyToUpdate,
};

export const ConnectedTopologiesView = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopologiesView);
