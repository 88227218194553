import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../components';
import { ConnectedAddNetworkForm } from '../AddNetworkForm';

export interface AddNetworkModalProps {
  onClose: VoidFunction;
}

export function AddNetworkModal({
  onClose,
}: AddNetworkModalProps): ReactElement {
  const { t } = useTranslation();

  return (
    <Modal
      clickScreenToClose={true}
      show={true}
      onClose={onClose}
      title={t('networks.form.add.title')}
    >
      <ConnectedAddNetworkForm />
    </Modal>
  );
}
