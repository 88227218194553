import { SelectOption } from 'dcloud-shared-ui';
import { ChangeEvent } from 'react';
import { PLEASE_SELECT_OPTION } from '../../../../../constants/form';
import { GB_TO_MB_CONVERSION_RATE } from '../../../../../constants/units';
import i18n from '../../../../../i18n';
import { sortObjectKeys } from '../../../../../utils/helpers';

interface convertMbToGbOptions {
  roundCeil: boolean;
}

export const createVmNamesArray = (
  data: VirtualMachine[] | undefined,
  virtualMachineUid: VirtualMachine['uid'],
): VirtualMachine['name'][] | undefined => {
  if (!data) {
    return undefined;
  }
  return data
    .filter(({ uid }) => uid !== virtualMachineUid)
    .map(({ name }) => name);
};

export const removeGuestAutomationIfDisabled = (
  vmToUpdatePayload: VmToUpdatePayload,
): void => {
  if (
    !vmToUpdatePayload.isGuestAutomationEnabled &&
    vmToUpdatePayload.guestAutomation
  ) {
    delete vmToUpdatePayload.guestAutomation;
  }
};

export const removeDisplayCredentialsIfDisabled = (
  vmToUpdatePayload: VmToUpdatePayload,
): void => {
  if (
    !vmToUpdatePayload.displayDifferentCredentials &&
    vmToUpdatePayload.remoteAccess.displayCredentials
  ) {
    delete vmToUpdatePayload.remoteAccess.displayCredentials;
  }
};

export const removeDisplayDifferentCredentials = (
  vmToUpdatePayload: VmToUpdatePayload,
): void => {
  delete vmToUpdatePayload.displayDifferentCredentials;
};

export const removeLinks = (vmToUpdatePayload: VmToUpdatePayload): void => {
  delete vmToUpdatePayload._links;
};

export const removeIsGuestAutomationEnabled = (
  vmToUpdatePayload: VmToUpdatePayload,
): void => {
  delete vmToUpdatePayload.isGuestAutomationEnabled;
};

export const replaceRAEmptyStringsWithNulls = (
  vmToUpdatePayload: VmToUpdatePayload,
): void => {
  if (vmToUpdatePayload.remoteAccess.username === '') {
    vmToUpdatePayload.remoteAccess.username = null;
  }

  if (vmToUpdatePayload.remoteAccess.password === '') {
    vmToUpdatePayload.remoteAccess.password = null;
  }
};

export const createOsOptions = (
  osArray: OperatingSystem[],
): SelectOption<string>[] => [
  { name: i18n.t('common.none'), value: 'NONE' },
  ...osArray.map(({ id, name }) => ({ name, value: id })),
];

export const parseNumberToString = (value: number): string =>
  isNaN(value) ? '' : value.toString();

export const parseChangeEventToNumber = (
  event: ChangeEvent<HTMLInputElement>,
): number => parseInt(event.target.value, 10);

export const convertMbToGb = (
  mbValue: number,
  { roundCeil }: convertMbToGbOptions = { roundCeil: true },
): number => {
  const gbValue = mbValue / GB_TO_MB_CONVERSION_RATE;

  if (roundCeil) {
    return Math.ceil(gbValue);
  }

  return gbValue;
};

export const convertGbToMb = (gbValue: number): number =>
  gbValue * GB_TO_MB_CONVERSION_RATE;

export const getDefaultMemoryUnit = (value: number): VmMemoryUnits =>
  value % GB_TO_MB_CONVERSION_RATE === 0 ? 'Gb' : 'Mb';

export const removeMemoryGb = (vmToUpdatePayload: VmToUpdatePayload): void => {
  delete vmToUpdatePayload.memoryGb;
};

export const addVMNetworkInterfacesToPayload = (
  vmToUpdatePayload: VmToUpdatePayload,
  vmNetworkInterfaces: VmNetworkInterfacesToEdit,
): VmToUpdatePayload => {
  removeNetworkNamePropertyFromNetworking(vmNetworkInterfaces);
  removeTempUidsFromNetworking(vmNetworkInterfaces);
  removeIPAddressIfEmptyString(vmNetworkInterfaces);
  removeMACAddressIfEmptyString(vmNetworkInterfaces);
  return {
    ...vmToUpdatePayload,
    vmNetworkInterfaces,
  };
};

export const removeNetworkNamePropertyFromNetworking = (
  vmNetworkInterfaces: VmNetworkInterfacesToEdit,
): void => {
  vmNetworkInterfaces.map(
    (vmNetworkInterface) =>
      'networkName' in vmNetworkInterface &&
      delete vmNetworkInterface.networkName,
  );
};

export const removeTempUidsFromNetworking = (
  vmNetworkInterfaces: VmNetworkInterfacesToEdit,
): void => {
  vmNetworkInterfaces.map(
    (vmNetworkInterface) =>
      vmNetworkInterface.uid &&
      vmNetworkInterface.uid.substring(0, 5) === 'temp-' &&
      delete vmNetworkInterface.uid,
  );
};

export const removeIPAddressIfEmptyString = (
  vmNetworkInterfaces: VmNetworkInterfacesToEdit,
): void => {
  vmNetworkInterfaces.map(
    (vmNetworkInterface) =>
      vmNetworkInterface.ipAddress?.length === 0 &&
      delete vmNetworkInterface.ipAddress,
  );
};

export const removeMACAddressIfEmptyString = (
  vmNetworkInterfaces: VmNetworkInterfacesToEdit,
): void => {
  vmNetworkInterfaces.map(
    (vmNetworkInterface) =>
      vmNetworkInterface.macAddress?.length === 0 &&
      delete vmNetworkInterface.macAddress,
  );
};

export const removeUnusedInternalUrls = (
  vmToUpdatePayload: VmToUpdatePayload,
): VmToUpdatePayload => {
  return {
    ...vmToUpdatePayload,
    remoteAccess: {
      ...vmToUpdatePayload.remoteAccess,
      internalUrls: vmToUpdatePayload.remoteAccess.internalUrls.filter(
        (internalUrl) => internalUrl.location.length > 0,
      ),
    },
  };
};

export function getAdapterOptions(
  validAdapters: VMRemoteAccessValidAdapter[],
): SelectOption<string>[] {
  return [
    PLEASE_SELECT_OPTION,
    ...validAdapters.map((adapter: VMRemoteAccessValidAdapter) => ({
      name: adapter.ipAddress,
      value: adapter.uid,
    })),
  ];
}

export const prepareRDPForPayload = (
  vmToUpdatePayload: VmToUpdatePayload,
): void => {
  if (vmToUpdatePayload.remoteAccess?.rdp) {
    delete vmToUpdatePayload.remoteAccess.rdp;
  }
};

export const prepareSSHForPayload = (
  vmToUpdatePayload: VmToUpdatePayload,
): void => {
  if (vmToUpdatePayload.remoteAccess?.ssh) {
    delete vmToUpdatePayload.remoteAccess.ssh;
  }
};

export const checkVMNetworkInterfaceArraysMatch = <
  T extends VMNetworkInterface[] | undefined
>(
  arr1: T,
  arr2: T,
): boolean => {
  const arr1WithSortedRDPKeys = arr1?.map((item) =>
    item.rdp ? { ...item, rdp: sortObjectKeys(item?.rdp) } : { ...item },
  );
  const arr2WithSortedRDPKeys = arr2?.map((item) =>
    item.rdp ? { ...item, rdp: sortObjectKeys(item?.rdp) } : { ...item },
  );
  return (
    JSON.stringify(arr1WithSortedRDPKeys) ===
    JSON.stringify(arr2WithSortedRDPKeys)
  );
};

export const determineRDPEnabledNIC = (
  interfaces: VMNetworkInterface[],
): VMNetworkInterface | undefined =>
  interfaces?.find((item) => item.rdp?.enabled);

export const determineSSHEnabledNIC = (
  interfaces: VMNetworkInterface[],
): VMNetworkInterface | undefined =>
  interfaces?.find((item) => item.ssh?.enabled);
