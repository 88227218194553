import React, { ReactElement, ReactNode, useCallback, useState } from 'react';
import { AccordionRow } from './AccordionRow';

interface AccordionRowConfig {
  title: string;
  children: ReactNode;
}

export interface AccordionProps {
  rows: AccordionRowConfig[];
}

export function Accordion({ rows }: AccordionProps): ReactElement {
  const [currentRowIndicies, setCurrentRowIndicies] = useState<number[]>([0]);

  const onRowClick = useCallback((newIndex: number) => {
    setCurrentRowIndicies((prevState) =>
      prevState.includes(newIndex)
        ? prevState.filter((num) => num !== newIndex)
        : [...prevState, newIndex],
    );
  }, []);

  return (
    <div>
      {rows.map(({ title, children }, i) => (
        <AccordionRow
          title={title}
          key={title}
          onRowClick={() => onRowClick(i)}
          isOpen={currentRowIndicies.includes(i)}
          id={`accordion-row-${i}`}
        >
          {children}
        </AccordionRow>
      ))}
    </div>
  );
}
