import { Button } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export interface PushActionPushButtonProps {
  onClick: VoidFunction;
}

export function PushActionPushButton({
  onClick,
}: PushActionPushButtonProps): ReactElement {
  const { t } = useTranslation();

  return (
    <Button colour="primary" onClick={onClick} size="small">
      {t('topologies.push.pushButtonText')}
    </Button>
  );
}
