import classnames from 'classnames';
import { Button } from 'dcloud-shared-ui';
import React, { ReactElement, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ButtonGroup,
  ControlledTextfield,
} from '../../../../../../../components';
import {
  convertGbToMb,
  convertMbToGb,
  getDefaultMemoryUnit,
  parseChangeEventToNumber,
  parseNumberToString,
} from '../../utils';
import styles from './MemoryInput.module.css';

export function MemoryInput(): ReactElement {
  const { t } = useTranslation();
  const { control, errors, setValue, watch } = useFormContext();
  const [unit, setUnit] = useState<VmMemoryUnits>(
    getDefaultMemoryUnit(control.watchInternal('memoryMb') as number),
  );
  const handleUnitConversionOnChange = (toUnit: VmMemoryUnits) => {
    const memoryGb = convertMbToGb(control.watchInternal('memoryMb') as number);
    const memoryMb = convertGbToMb(control.watchInternal('memoryGb') as number);
    setValue('memoryGb', memoryGb, { shouldDirty: true, shouldValidate: true });
    setValue('memoryMb', memoryMb, { shouldDirty: true, shouldValidate: true });
    setUnit(toUnit);
  };
  const watchMemoryGb = watch('memoryGb');

  useEffect(() => {
    setValue('memoryMb', convertGbToMb(watchMemoryGb));
  }, [setValue, watchMemoryGb]);

  return (
    <div className={styles.root}>
      <ControlledTextfield
        className={classnames(styles.memoryInput, { show: unit === 'Mb' })}
        transform={{
          input: parseNumberToString,
          output: parseChangeEventToNumber,
        }}
        control={control}
        error={errors?.memoryMb?.message}
        label={t('virtualMachines.edit.memoryMb')}
        name="memoryMb"
        id="memoryMb"
        required={true}
        testId="edit-vm-memoryMb"
        isNumber={true}
      />
      <ControlledTextfield
        className={classnames(styles.memoryInput, { show: unit === 'Gb' })}
        transform={{
          input: parseNumberToString,
          output: parseChangeEventToNumber,
        }}
        control={control}
        error={errors?.memoryGb?.message}
        label={t('virtualMachines.edit.memoryMb')}
        name="memoryGb"
        id="memoryGb"
        required={true}
        testId="edit-vm-memoryGb"
        isNumber={true}
      />
      <div className={styles.unitSwitchContainer}>
        <ButtonGroup className={styles.unitSwitch}>
          <Button
            type="button"
            className={classnames({ selected: unit === 'Gb' })}
            disabled={unit === 'Gb'}
            onClick={() => handleUnitConversionOnChange('Gb')}
          >
            GB
          </Button>
          <Button
            type="button"
            className={classnames({ selected: unit === 'Mb' })}
            disabled={unit === 'Mb'}
            onClick={() => handleUnitConversionOnChange('Mb')}
          >
            MB
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
}
