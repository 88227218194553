import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, CellProps, Hooks, PluginHook } from 'react-table';
import { Table as SharedTable } from '../../../../../../components';
import { RowCheckbox } from '../../../../../../components/Table/components/RowCheckbox';
import { UseSelectHardwareItemsProps } from '../use-select-hardware-inventory-items';

export interface TableProps extends UseSelectHardwareItemsProps {
  inventoryHardwareItems: InventoryHardwareItem[];
  isLoading: boolean;
}

export const INVENTORY_HARDWARE_ITEMS_LIMIT = 15;

export function Table({
  handleOnSelect,
  isLoading,
  inventoryHardwareItems,
}: TableProps): ReactElement {
  const { t } = useTranslation();

  const columns = useMemo<Column<InventoryHardwareItem>[]>(
    () => [
      {
        Header: () => t('inventoryHardware.table.headings.name'),
        accessor: 'name',
        minWidth: 200,
      },
      {
        Header: () => t('inventoryHardware.table.headings.description'),
        accessor: 'description',
        minWidth: 200,
      },
      {
        Cell: ({ value }: CellProps<InventoryHardwareItem>) => value.length,
        Header: () => t('inventoryHardware.table.headings.interfaces'),
        accessor: 'networkInterfaces',
        width: 60,
      },
      {
        Cell: ({ value }: CellProps<InventoryHardwareItem>) =>
          value ? t('common.yes') : t('common.no'),
        Header: () =>
          t('inventoryHardware.table.headings.powerControlAvailable'),
        accessor: 'powerControlAvailable',
        width: 110,
      },
      {
        Cell: ({ value }: CellProps<InventoryHardwareItem>) =>
          value ? t('common.yes') : t('common.no'),
        Header: () =>
          t('inventoryHardware.table.headings.hardwareConsoleAvailable'),
        accessor: 'hardwareConsoleAvailable',
        width: 100,
      },
    ],
    [t],
  );

  const pluginHooks = useMemo<PluginHook<InventoryHardwareItem>[]>(
    () => [
      (hooks: Hooks<InventoryHardwareItem>) => {
        hooks.visibleColumns.push((columns) => [
          {
            // eslint-disable-next-line react/display-name
            Cell: ({ row }: CellProps<InventoryHardwareItem>) => (
              <RowCheckbox onChange={handleOnSelect} row={row} />
            ),
            Header: '',
            id: 'selection',
            width: 20,
          },
          ...columns,
        ]);
      },
    ],
    [handleOnSelect],
  );

  return (
    <SharedTable<InventoryHardwareItem>
      columns={columns}
      loading={isLoading}
      data={inventoryHardwareItems}
      initialState={{
        pageSize: INVENTORY_HARDWARE_ITEMS_LIMIT,
        sortBy: [{ desc: false, id: 'name' }],
      }}
      pluginHooks={pluginHooks}
    />
  );
}
