import { Alert } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeading } from '../../../../../components/PageHeading';
import { MailServerAddFormContainer } from './components/MailServerAddForm/container';
import { MailServerDeleteModal } from './components/MailServerDeleteModal';
import { MailServersTable } from './components/MailServerTable';
import { MailServerProps } from './container';

export function MailServers({
  isError,
  isLoading,
  mailServers,
  dnsAssets,
  vmTargets,
  mailServerToDelete,
  setMailServerToDelete,
}: MailServerProps): ReactElement | null {
  const { t } = useTranslation();

  return (
    <section className="half-padding-left">
      <PageHeading pageTitle={t('traffic.mailServer.name')} />
      {!mailServers?.length && !isLoading && vmTargets?.length ? (
        <MailServerAddFormContainer
          dnsAssets={dnsAssets}
          vmTargets={vmTargets}
        />
      ) : null}
      {!mailServers?.length && !isLoading ? (
        <Alert colour="info">{t('traffic.mailServer.empty')}</Alert>
      ) : (
        <MailServersTable
          mailServers={mailServers || []}
          isError={isError}
          isLoading={isLoading}
          setMailServerToDelete={setMailServerToDelete}
        />
      )}
      {mailServers?.length
        ? null
        : !vmTargets?.length && (
            <Alert colour="info">
              {t('traffic.mailServer.noConfiguredMailserver')}
            </Alert>
          )}
      {mailServerToDelete && (
        <MailServerDeleteModal
          setMailServerToDelete={setMailServerToDelete}
          mailServer={mailServerToDelete}
        />
      )}
    </section>
  );
}
