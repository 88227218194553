import { NavigationTabs } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageHeading } from '../../../../../components/PageHeading';
import { routes, VirtualMachinesAddRouter } from './VirtualMachinesAddRouter';

export function VirtualMachinesAdd(): ReactElement {
  const { uid } = useParams<EditTopologyParams>();
  const { t } = useTranslation();
  const { url } = useRouteMatch();

  return (
    <>
      <PageHeading
        pageTitle={t('virtualMachines.add.title')}
        withBackBtn={true}
      />
      <NavigationTabs
        tabs={routes.map((tab) => ({
          ...tab,
          pathname: `${url.replace(':uid', uid)}${tab.pathname}`,
        }))}
      />
      <VirtualMachinesAddRouter />
    </>
  );
}
