import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { documentationFieldsSchema } from '../../../../../../validation';
import { ControlledTextfield } from '../../../../../../components';
import { updateDocumentation } from '../../../../../../redux/configuration/documentation/slice';
import { PrimaryButton } from '../../../../../../components/Buttons/PrimaryButton';
import styles from './Documentation.module.css';

export interface DocumentationFormProps {
  documentation: DocumentationFields;
}

export function DocumentationForm({
  documentation,
}: DocumentationFormProps): ReactElement {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const defaultValues: DocumentationFields = {
    demoProfileUrl: documentation.demoProfileUrl || '',
    documentationUrl: documentation.documentationUrl || '',
  };
  const {
    handleSubmit,
    errors,
    control,
    formState: { dirtyFields, isValid, isSubmitting },
    reset,
  } = useForm<DocumentationFields>({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(documentationFieldsSchema),
  });

  const onSubmit = (data: DocumentationFields): void => {
    dispatch(updateDocumentation(data));
    reset(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <ControlledTextfield
        defaultValue={defaultValues.documentationUrl}
        control={control}
        error={errors.documentationUrl?.message}
        name="documentationUrl"
        label={t('documentation.documentationUrl')}
        labelClass="col-3"
      />
      <ControlledTextfield
        control={control}
        defaultValue={defaultValues.demoProfileUrl}
        error={errors.demoProfileUrl?.message}
        name="demoProfileUrl"
        label={t('documentation.demoProfileUrl')}
        labelClass="col-3"
      />
      <PrimaryButton
        loading={isSubmitting}
        disabled={!Object.keys(dirtyFields).length || !isValid}
        className="pull-right dbl-margin-top"
        type="submit"
        colour="success"
      >
        {t('buttons.save')}
      </PrimaryButton>
    </form>
  );
}
