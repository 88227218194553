import { SelectOption } from 'dcloud-shared-ui/dist/components/Select';
import {
  NONE_OPTION,
  PLEASE_SELECT_OPTION,
} from '../../../../../constants/form';

interface BuildMailServerPayloadProps {
  formData: MailServerFormData;
  topologyUid: TopologyUid['uid'];
}

export const buildMailServerPayload = ({
  formData,
  topologyUid,
}: BuildMailServerPayloadProps): MailServersPostPayload => {
  const { ipAddress, asset } = formData;
  let payload;
  asset === ''
    ? (payload = {
        topology: { uid: topologyUid },
        vmNicTarget: {
          uid: ipAddress,
        },
      })
    : (payload = {
        inventoryDnsAsset: {
          id: asset,
        },
        topology: { uid: topologyUid },
        vmNicTarget: {
          uid: ipAddress,
        },
      });
  return payload;
};

export const buildAssetOptions = (
  assets?: InventoryDnsAsset[],
): SelectOption<string>[] => {
  if (assets?.length) {
    const options = assets.map(({ name, id }) => ({ name, value: id }));
    return [NONE_OPTION, ...options];
  }
  return [NONE_OPTION];
};

export function buildIPAddressOptionsNicTarget(
  selectedVm?: string,
  vmNetworkInterfaces?: VmNicTarget[],
): SelectOption<string>[] {
  return vmNetworkInterfaces
    ? vmNetworkInterfaces?.reduce(
        (options: SelectOption<string>[], nicTargets) => {
          nicTargets.ipAddress &&
            nicTargets.vm.name === selectedVm &&
            options.push({
              name: nicTargets.ipAddress,
              value: nicTargets.uid as string,
            });
          return options;
        },
        [PLEASE_SELECT_OPTION],
      )
    : [PLEASE_SELECT_OPTION];
}
