import { dcloudApiAxios } from '../api';
import i18n from '../../i18n';
import { TOPOLOGIES_PATH } from '../../api/paths';
import { clearTopologyToDelete, fetchTopologies } from './slice';

export const topologiesApi = dcloudApiAxios.injectEndpoints({
  endpoints: (build) => ({
    deleteTopology: build.mutation<undefined, string>({
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(clearTopologyToDelete());
          dispatch(fetchTopologies());
        } catch (err) {
          dispatch(clearTopologyToDelete());
        }
      },
      query: (topologyUid) => ({
        errorMessage: i18n.t('topologies.delete.error'),
        method: 'delete',
        successMessage: i18n.t('topologies.delete.success'),
        url: `${TOPOLOGIES_PATH}/${topologyUid}`,
      }),
    }),
  }),
});

export const { useDeleteTopologyMutation } = topologiesApi;
