import { connect } from 'react-redux';
import {
  addNetwork,
  networksSelectors,
} from '../../../../redux/configuration/networks/slice';
import { inventoryNetworksSelectors } from '../../../../redux/entities/inventory-networks/slice';
import { RootState } from '../../../../redux/store';
import { AddNetworkForm } from './AddNetworkForm';

export type AddNetworkFormProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const mapStateToProps = (state: RootState) => ({
  adding: networksSelectors.getAdding(state),
  availableRouted: inventoryNetworksSelectors.getAvailableRouted(state),
  availableUnrouted: inventoryNetworksSelectors.getAvailableUnRouted(state),
  networkNames: networksSelectors.getNetworkNames(state),
});

const mapDispatchToProps = {
  addNetwork,
};

export const ConnectedAddNetworkForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddNetworkForm);
