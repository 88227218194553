import React, { ReactElement } from 'react';
import { ConnectionLine } from './ConnectionLine';

export interface ConnectionsProps {
  connections: DiagramConnection[];
}

export function Connections({ connections }: ConnectionsProps): ReactElement {
  return (
    <g id="connections">
      {connections.map((connection) => {
        return <ConnectionLine key={connection.uid} connection={connection} />;
      })}
    </g>
  );
}
