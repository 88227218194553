import * as d3 from 'd3';
import { useCallback } from 'react';
import { HEADER_OFFSET_VAR } from '../../../constants';

export interface UseConnection {
  redrawNodeConnections: (uid: string) => void;
}

export function useConnection(): UseConnection {
  const redrawNodeConnections = useCallback((uid) => {
    const currentNode = d3
      .select<HTMLDivElement, unknown>(`[data-component="${uid}"]`)
      .node();

    if (!currentNode) return;

    const currentNodeBounds = currentNode.getBoundingClientRect();
    const [headerHeightStr] = getComputedStyle(document.body)
      .getPropertyValue(HEADER_OFFSET_VAR)
      .split('px');
    const xMid = currentNodeBounds.x + currentNodeBounds.width / 2;
    const yMid =
      currentNodeBounds.y +
      currentNodeBounds.height / 2 -
      Number(headerHeightStr);

    d3.selectAll<HTMLDivElement, unknown>(`[data-connection-from="${uid}"]`)
      .attr('x1', xMid)
      .attr('y1', yMid);
    d3.selectAll<HTMLDivElement, unknown>(`[data-connection-to="${uid}"]`)
      .attr('x2', xMid)
      .attr('y2', yMid);
  }, []);

  return {
    redrawNodeConnections,
  };
}
