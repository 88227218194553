import { dcloudApi, dcloudApiAxios, TAGS } from '../../api';
import { TOPOLOGIES_PATH, NETWORKS_PATH } from '../../../api/paths';
import i18n from '../../../i18n';

export const networksApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    getNetworks: build.query<Network[], string>({
      providesTags: [TAGS.NETWORKS],
      query: (topologyUid) => ({
        url: `${TOPOLOGIES_PATH}/${topologyUid}${NETWORKS_PATH}`,
      }),
      transformResponse: (response: FetchNetworksResponse) =>
        response._embedded.networks,
    }),
  }),
});

export const networksApiAxios = dcloudApiAxios.injectEndpoints({
  endpoints: (build) => ({
    getNetworksAxios: build.query<Network[], string>({
      providesTags: [TAGS.NETWORKS],
      query: (topologyUid) => ({
        errorMessage: i18n.t('networks.fetch.error.errors.many'),
        method: 'get',
        url: `${TOPOLOGIES_PATH}/${topologyUid}${NETWORKS_PATH}`,
      }),
      transformResponse: (response: FetchAxiosNetworksResponse) =>
        response.networks,
    }),
  }),
});

export const { useGetNetworksQuery } = networksApi;
export const { useGetNetworksAxiosQuery } = networksApiAxios;
