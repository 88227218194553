import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PageHeading } from '../../../../../components/PageHeading';
import { PrimaryButton } from '../../../../../components/Buttons/PrimaryButton';
import { startStopOrderSelectors } from '../../../../../redux/configuration/start-stop-order/slice';
import { getEtag } from '../../../../../redux/configuration/etag/selectors';
import {
  useGetHardwareStartOrderQuery,
  useUpdateHWStartOrderMutation,
} from '../../../../../redux/configuration/start-stop-order/hardware-start/api';
import { StartStopOrderContent } from '../components/StartStopOrderContent';
import { HardwareStartTable } from './components/HardwareStartTable';

export function HardwareStart(): ReactElement {
  const { t } = useTranslation();
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const {
    isFetching,
    isError,
    refetch,
    isLoading,
  } = useGetHardwareStartOrderQuery(topologyUid, { refetchOnFocus: false });
  const [
    updateHWStartOrder,
    { isLoading: isUpdating },
  ] = useUpdateHWStartOrderMutation();
  const etag = useSelector(getEtag);
  const isDirty = useSelector(startStopOrderSelectors.getStartStopOrderIsDirty);
  const startStopOrder = useSelector(startStopOrderSelectors.getStartStopOrder);

  return (
    <section className="half-padding-left">
      <PageHeading
        ctaBtn={
          <PrimaryButton
            className="pull-right dbl-margin-top"
            testId="hw-start-order-submit"
            type="submit"
            colour="success"
            onClick={() =>
              updateHWStartOrder({ etag, payload: startStopOrder! })
            }
            disabled={!isDirty}
            loading={isUpdating}
          >
            {t('buttons.update')}
          </PrimaryButton>
        }
        pageTitle={t('startStopOrder.hardwareStart.name')}
      />
      {!isError && (
        <StartStopOrderContent
          isFetching={isFetching}
          isLoading={isLoading}
          buttonUnorderedLabel={t('buttons.unordered')}
          buttonOrderedLabel={t('buttons.ordered')}
          ordered={startStopOrder?.ordered}
          positions={startStopOrder?.positions}
          alertText={t('startStopOrder.hardwareStart.table.noResults')}
          table={HardwareStartTable}
          refetch={refetch}
        />
      )}
    </section>
  );
}
