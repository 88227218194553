import { select, call, put } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import { deleteTopology } from '../../../api/topologies';
import {
  showErrorToastWorker,
  showSuccessToastWorker,
} from '../../toast/sagas';
import {
  topologiesSelectors,
  deleteTopologySuccess,
  deleteTopologyFailure,
  fetchTopologies,
} from '../slice';

export function* deleteTopologyWorker(): SagaIterator {
  const topologyToDelete: Topology | undefined = yield select(
    topologiesSelectors.getTopologyToDelete,
  );

  if (!topologyToDelete) {
    return;
  }

  try {
    yield call(deleteTopology, topologyToDelete.uid);
    yield put(deleteTopologySuccess());
    yield put(fetchTopologies());
    yield call(showSuccessToastWorker, 'topologies.delete.success');
  } catch (e) {
    yield call(showErrorToastWorker);
    yield put(deleteTopologyFailure());
  }
}
