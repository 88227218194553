import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { history } from './history';
import { entitiesReducer } from './entities/reducer';
import { toastReducer } from './toast/slice';
import { configurationReducer } from './configuration/reducer';
import { topologiesReducer } from './topologies/slice';
import { dcloudApi, dcloudApiAxios } from './api';
import { viewsReducer } from './views/slice';

export const rootReducer = combineReducers({
  [dcloudApi.reducerPath]: dcloudApi.reducer,
  [dcloudApiAxios.reducerPath]: dcloudApiAxios.reducer,
  configuration: configurationReducer,
  entities: entitiesReducer,
  router: connectRouter(history),
  toast: toastReducer,
  topologies: topologiesReducer,
  views: viewsReducer,
});
