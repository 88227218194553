import { TruncatedCell } from 'dcloud-shared-ui';
import React, { ReactElement, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { Table } from '../../../../components/Table';
import { NetworksTableActions } from './NetworksTableActions';

export interface NetworksTableProps {
  loading: boolean;
  networks: Network[];
  onDeleteClick: (network: Network) => void;
  onEditClick: (uid: string) => void;
}

export function NetworksTable({
  loading,
  networks,
  onDeleteClick,
  onEditClick,
}: NetworksTableProps): ReactElement {
  const { t } = useTranslation();

  const columns = useMemo<Column<Network>[]>(
    () => [
      {
        Cell: TruncatedCell,
        Header: () => t('networks.table.headings.name'),
        accessor: 'name',
        minWidth: 100,
      },
      {
        Cell: TruncatedCell,
        Header: () => t('networks.table.headings.description'),
        accessor: 'description',
        minWidth: 100,
      },
      {
        Cell: TruncatedCell,
        Header: () => t('networks.table.headings.routed'),
        accessor: (row) =>
          row.inventoryNetwork?.type === 'ROUTED'
            ? t('common.yes')
            : t('common.no'),
        id: 'routed',
      },
      {
        Cell: TruncatedCell,
        Header: () => t('networks.table.headings.subnet'),
        accessor: (row) => row.inventoryNetwork?.subnet,
        id: 'subnet',
      },
    ],
    [t],
  );

  const renderActions = (network: Network): ReactNode => (
    <NetworksTableActions
      canDelete={!!network._links.delete}
      onDeleteClick={() => onDeleteClick(network)}
      onEditClick={() => onEditClick(network.uid)}
    />
  );

  return (
    <Table<Network>
      columns={columns}
      data={networks}
      loading={loading}
      actions={renderActions}
    />
  );
}
