import { Alert } from 'dcloud-shared-ui';
import React, { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeading } from '../../../../../components/PageHeading';
import { InboundProxyRuleDeleteModal } from './components/InboundProxyRuleDeleteModal';
import { InboundProxyRulesAddForm } from './components/InboundProxyRulesAddForm';
import { InboundProxyRulesTable } from './components/InboundProxyRulesTable';
import { InboundProxyRulesProps } from './container';

export function InboundProxyRules({
  createInboundProxyRule,
  inboundProxyRuleToDelete,
  inboundProxyRules,
  ipAddressOptions,
  isCreating,
  isError,
  isLoading,
  setInboundProxyRuleToDelete,
  setSelectedVM,
  targetOptions,
}: InboundProxyRulesProps): ReactElement | null {
  const { t } = useTranslation();

  const onDeleteClick = useCallback(
    (inboundProxyRule: InboundProxyRule) => {
      setInboundProxyRuleToDelete(inboundProxyRule);
    },
    [setInboundProxyRuleToDelete],
  );

  return (
    <section className="half-padding-left">
      <PageHeading pageTitle={t('traffic.inboundProxyRules.name')} />
      {targetOptions?.length === 1 && !isLoading ? (
        <Alert colour="info">
          {t('traffic.inboundProxyRules.noConfiguredVMs')}
        </Alert>
      ) : (
        <InboundProxyRulesAddForm
          ipAddressOptions={ipAddressOptions}
          setSelectedVM={setSelectedVM}
          targetOptions={targetOptions}
          isCreating={isCreating}
          createInboundProxyRule={createInboundProxyRule}
        />
      )}
      {!inboundProxyRules?.length && !isLoading ? (
        <Alert colour="info">{t('traffic.inboundProxyRules.empty')}</Alert>
      ) : (
        <InboundProxyRulesTable
          inboundProxyRules={inboundProxyRules || []}
          isError={isError}
          isLoading={isLoading}
          onDeleteClick={onDeleteClick}
        />
      )}
      {inboundProxyRuleToDelete && (
        <InboundProxyRuleDeleteModal
          inboundProxyRule={inboundProxyRuleToDelete}
          setInboundProxyRuleToDelete={setInboundProxyRuleToDelete}
        />
      )}
    </section>
  );
}
