import React, { ReactElement } from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { VirtualMachinesContainer } from './All/container';
import { VirtualMachinesAdd } from './Add';
import { ConfigureContainer } from './Configure';
import { ConnectedVirtualMachinesEdit } from './Edit/container';

export enum VirtualMachinesRoutes {
  Add = '/add',
  ConfigureTemplateVM = '/templates/configure',
  ConfigureDemoVM = '/demos/vms/configure',
  Edit = '/:virtualMachineUid',
}

export function VirtualMachinesRouter(): ReactElement {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={match.path} component={VirtualMachinesContainer} />
      <Route
        path={`${match.path}${VirtualMachinesRoutes.Add}${VirtualMachinesRoutes.ConfigureTemplateVM}`}
        component={ConfigureContainer}
      />
      <Route
        path={`${match.path}${VirtualMachinesRoutes.Add}${VirtualMachinesRoutes.ConfigureDemoVM}`}
        component={ConfigureContainer}
      />
      <Route
        path={`${match.path}${VirtualMachinesRoutes.Add}`}
        component={VirtualMachinesAdd}
      />
      <Route
        path={`${match.path}${VirtualMachinesRoutes.Edit}`}
        component={ConnectedVirtualMachinesEdit}
      />
      <Redirect to={match.url} />
    </Switch>
  );
}
