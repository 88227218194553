import { TableDraggableRows, TableDraggableRowsProps } from 'dcloud-shared-ui';
import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CellProps, Column } from 'react-table';
import {
  setDelaySecondsOnBlur,
  setDelaySecondsOnChange,
} from '../../../../../../redux/configuration/start-stop-order/slice';
import { StartStopDelayInput } from '../../components/StartStopDelayInput';
import styles from './VMStartTable.module.css';

export interface VMStartTableProps {
  handleOnRowDrag: TableDraggableRowsProps<
    StartStopOrderPosition
  >['handleOnRowDrag'];
  positions: StartStopOrderPosition[];
}

export function VMStartTable({
  handleOnRowDrag,
  positions,
}: VMStartTableProps): ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const columns = useMemo<Column<StartStopOrderPosition>[]>(
    () => [
      {
        Header: () => t('startStopOrder.vmStart.table.headings.name'),
        accessor: (row) => row.vm?.name,
        disableSortBy: true,
        id: 'name',
      },
      {
        // eslint-disable-next-line react/display-name
        Cell: ({
          row: { original },
          value,
        }: CellProps<StartStopOrderPosition>) => (
          <StartStopDelayInput
            onChange={(e) => {
              dispatch(
                setDelaySecondsOnChange({
                  delaySeconds: e.target.value,
                  position: original.position,
                }),
              );
            }}
            onBlur={(e) => {
              dispatch(
                setDelaySecondsOnBlur({
                  delaySeconds: e.target.value,
                  position: original.position,
                }),
              );
            }}
            value={value}
          />
        ),
        Header: () => t('startStopOrder.vmStart.table.headings.delay'),
        accessor: (row) => row.delaySeconds,
        id: 'delay',
      },
    ],
    [dispatch, t],
  );

  return (
    <TableDraggableRows<StartStopOrderPosition>
      className={styles.root}
      columns={columns}
      data={positions}
      handleOnRowDrag={handleOnRowDrag}
    />
  );
}
