import React, { ReactElement, useRef } from 'react';
import { ReactComponent as WindowsIcon } from '../../../../assets/images/icons/Windows.svg';
import { ReactComponent as LinuxIcon } from '../../../../assets/images/icons/Linux.svg';
import { ReactComponent as VmwareIcon } from '../../../../assets/images/icons/Vmware.svg';
import { ReactComponent as UCSMIcon } from '../../../../assets/images//icons/UCSM.svg';
import { Node } from '../Node';
import { useConnection } from '../Connections/hooks/use-connection';
import { useDrag } from '../Node/hooks/use-drag';
import { usePosition } from '../Node/hooks/use-position';
import { ReactComponent as DefaultVMIcon } from './images/DefaultVM.svg';
import { DefaultVmNodeDisplay as defaultPosition } from './constants';
import styles from './VmNode.module.css';

interface VmNodeProps extends BaseNodeProps {
  asset?: VirtualMachine;
}

export function VmNode({
  uid,
  asset,
  isReadOnly,
  ...node
}: VmNodeProps): ReactElement {
  const nodeRef = useRef<HTMLDivElement>(null);
  const { redrawNodeConnections } = useConnection();

  const hookProps = {
    defaultPosition,
    display: node.display,
    nodeRef,
    redrawNodeConnections,
    uid,
  };

  usePosition({ ...hookProps });
  useDrag({ ...hookProps, isReadOnly });

  const renderOSIcon = () => {
    switch (asset?.osFamily) {
      case 'WINDOWS':
        return <WindowsIcon className={styles.osIcon} />;
      case 'LINUX':
        return <LinuxIcon className={styles.osIcon} />;
      case 'VMWARE':
        return <VmwareIcon className={styles.osIcon} />;
      case 'UCSM':
        return <UCSMIcon className={styles.osIcon} />;
      default:
    }
  };

  return (
    <Node className={styles.root} ref={nodeRef} isReadOnly={isReadOnly}>
      <p className={styles.title}>{asset?.name}</p>
      <DefaultVMIcon data-component={uid} />
      {renderOSIcon()}
    </Node>
  );
}
