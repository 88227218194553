import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { call, delay, put } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';
import i18n from '../../../../i18n';
import { deleteLicence as deleteLicenceApi } from '../../../../api/licences';
import { deleteLicenceFailure } from '../slice';
import {
  showErrorToastWorker,
  showSuccessToastWorker,
} from '../../../toast/sagas';
import { addToast, removeToast } from '../../../toast/slice';

export const INFO_DELAY = 1000;

export function* deleteLicenceWorker(
  action: PayloadAction<Licence>,
): SagaIterator {
  const infoToastUid = yield call(uuid);

  try {
    yield put(
      addToast({
        message: i18n.t('common.deleting'),
        title: '',
        type: 'info',
        uid: infoToastUid,
      }),
    );
    yield call(deleteLicenceApi, action.payload.uid);
    yield delay(INFO_DELAY);
    yield put(removeToast(infoToastUid));
    yield call(showSuccessToastWorker, 'licences.delete.success');
  } catch (e) {
    yield put(removeToast(infoToastUid));
    yield put(deleteLicenceFailure(action.payload));
    yield call(showErrorToastWorker, i18n.t('licences.delete.error'));
  }
}
