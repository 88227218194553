import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TableLoader } from '../../../../../components/Table/TableLoader';
import {
  useFetchAllHardwareQuery,
  useGetHardwareItemQuery,
  useUpdateHardwareItemMutation,
} from '../../../../../redux/configuration/hardware/api';
import {
  getHardware,
  setNetworkInterfaceToDelete,
} from '../../../../../redux/configuration/hardware/slice';
import { RootState } from '../../../../../redux/store';
import { Form } from './Form';

function EditHardwareContainer({
  networkInterfaceToDelete,
  setNetworkInterfaceToDelete,
}: HardwareContainerProps): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const { data: allHardware, isLoading } = useFetchAllHardwareQuery(
    topologyUid,
  );

  const { hardwareUid } = useParams<{ hardwareUid: string }>();
  const { data: hardwareItem, isFetching } = useGetHardwareItemQuery(
    hardwareUid,
    {
      refetchOnFocus: false,
    },
  );

  const [
    updateHardwareItem,
    { isLoading: isUpdating, isSuccess },
  ] = useUpdateHardwareItemMutation();

  if (!allHardware || !hardwareItem || isLoading) {
    return <TableLoader />;
  }

  return (
    <section>
      <Form
        allHardware={allHardware}
        updateHardwareItem={updateHardwareItem}
        hardwareItem={hardwareItem}
        isSuccess={isSuccess}
        isUpdating={isUpdating || isFetching}
        setNetworkInterfaceToDelete={setNetworkInterfaceToDelete}
        networkInterfaceToDelete={networkInterfaceToDelete}
      />
    </section>
  );
}

type HardwareContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export interface HardwareRTKProps {
  allHardware: Hardware[];
  hardwareItem: Hardware;
  isUpdating: boolean;
  isSuccess: boolean;
  updateHardwareItem: RTKMutation<UpdateHardwarePayload, Hardware>;
}

export type HardwareProps = HardwareContainerProps & HardwareRTKProps;

const mapStateToProps = (state: RootState) => ({
  networkInterfaceToDelete: getHardware(state).networkInterfaceToDelete,
});

const mapDispatchToProps = {
  setNetworkInterfaceToDelete,
};

export const ConnectedEditHardware = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditHardwareContainer);
