import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeading } from '../../components';
import { GeneralForm } from './components/GeneralForm';
import { GeneralFormSkeleton } from './components/GeneralFormSkeleton';
import styles from './General.module.css';

export interface GeneralProps {
  etag: string;
  isUpdating: boolean;
  topologyToEdit?: Topology;
  updateTopology: (args: TopologyUpdatePayload) => void;
}

export function General({
  etag,
  isUpdating,
  topologyToEdit,
  updateTopology,
}: GeneralProps): ReactElement {
  const { t } = useTranslation();

  const onHandleSubmit = (data: TopologyGeneralFields) => {
    if (topologyToEdit) {
      updateTopology({ etag, topology: { ...topologyToEdit, ...data } });
    }
  };

  return (
    <div className="half-padding-left">
      <div className="dbl-margin-bottom">
        <PageHeading text={t('general.name')} />
      </div>
      <div className={styles.root}>
        {!topologyToEdit ? (
          <GeneralFormSkeleton />
        ) : (
          <GeneralForm
            defaultValues={topologyToEdit}
            onHandleSubmit={onHandleSubmit}
            updating={isUpdating}
          />
        )}
      </div>
    </div>
  );
}
