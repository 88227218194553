import { api } from '..';
import { DOCUMENTATION_PATH } from '../paths';
import { stripGzipFromEtag } from '../utils';

export async function fetchDocumentation(
  topologyUid: string,
): Promise<DocumentationApiResponse> {
  const { data, headers } = await api.get<Documentation>(
    `${DOCUMENTATION_PATH}/${topologyUid}`,
  );

  return { data, etag: stripGzipFromEtag(headers.etag) };
}

export async function updateDocumentation(
  documentation: DocumentationRequestData,
  etag: string,
): Promise<DocumentationApiResponse> {
  const { data, headers } = await api.put<Documentation>(
    `${DOCUMENTATION_PATH}/${documentation.uid}`,
    documentation,
    { headers: { 'If-Match': etag } },
  );

  return {
    data,
    etag: headers.etag,
  };
}
