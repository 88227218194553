import { Alert } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeading } from '../../../../../components/PageHeading';
import { ExternalDnsRTKProps } from './container';
import { ExternalDnsAddFormContainer } from './ExternalDnsAddform/container';
import { ExternalDnsTable } from './ExternalDnsTable';

export function ExternalDNS({
  isError,
  isLoading,
  externalDNSRecord,
  natRules,
  dnsAssets,
}: ExternalDnsRTKProps): ReactElement | null {
  const { t } = useTranslation();

  return (
    <section className="half-padding-left">
      <PageHeading pageTitle={t('traffic.externalDns.name')} />
      {!isLoading && natRules?.length ? (
        <ExternalDnsAddFormContainer
          natTargets={natRules}
          dnsAssets={dnsAssets}
        />
      ) : null}
      {!externalDNSRecord?.length && !isLoading ? (
        <Alert colour="info">{t('traffic.externalDns.empty')}</Alert>
      ) : (
        <ExternalDnsTable
          dnsRecords={externalDNSRecord || []}
          isError={isError}
          isLoading={isLoading}
        />
      )}
      {externalDNSRecord?.length
        ? null
        : !natRules?.length && (
            <Alert colour="info">
              {t('traffic.externalDns.noConfiguredNatRules')}
            </Alert>
          )}
    </section>
  );
}
