import { dcloudApiAxios, TAGS } from '../api';
import { DIAGRAM_PATH, NODES_PATH, TOPOLOGIES_PATH } from '../../api/paths';
import i18n from '../../i18n';
import { store } from '../store';
import { topologiesSelectors } from '../topologies/slice';
import { updateNodeDisplayReducer } from './utils';

export const diagramApi = dcloudApiAxios.injectEndpoints({
  endpoints: (build) => ({
    getDiagram: build.query<DiagramResponse, Topology['uid']>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.DIAGRAM],
      query: (topologyUid) => ({
        errorMessage: i18n.t('diagram.fetch.error'),
        method: 'get',
        url: `${TOPOLOGIES_PATH}/${topologyUid}${DIAGRAM_PATH}`,
      }),
    }),
    updateNode: build.mutation<DiagramNode, UpdateDiagramNodeProps>({
      async onQueryStarted({ nodeUid, display }, { dispatch, queryFulfilled }) {
        const topology = topologiesSelectors.getTopologyToEdit(
          store.getState(),
        );
        dispatch(
          diagramApi.util.updateQueryData(
            'getDiagram',
            topology!.uid,
            (response: DiagramResponse) =>
              updateNodeDisplayReducer({
                diagramResponse: response,
                display,
                nodeUid,
              }),
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          dispatch(diagramApi.util.invalidateTags([TAGS.DIAGRAM]));
        }
      },
      query: ({ display, nodeUid }) => ({
        data: { display },
        errorMessage: i18n.t('diagram.update.error'),
        method: 'patch',
        url: `${NODES_PATH}/${nodeUid}`,
      }),
    }),
  }),
});

export const { useGetDiagramQuery, useUpdateNodeMutation } = diagramApi;
