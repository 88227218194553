import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dataCentersSelectors, fetchDataCenters } from '../slice';

export function useLoadDataCenters(): boolean {
  const dispatch = useDispatch();

  const fetchedDataCenters = useSelector(
    dataCentersSelectors.getDataCentersFetched,
  );

  useEffect(() => {
    if (!fetchedDataCenters) {
      dispatch(fetchDataCenters());
    }
  }, [fetchedDataCenters, dispatch]);

  return !fetchedDataCenters;
}
