import {
  createApi,
  fetchBaseQuery,
  BaseQueryFn,
} from '@reduxjs/toolkit/query/react';
import { AxiosRequestConfig, AxiosError } from 'axios';
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { api as instance } from '../api';
import {
  createHeaders,
  dispatchErrorToast,
  dispatchSuccessToast,
  stripAndsetEtag,
} from './utils';

export const TAGS = {
  DEMOS_BY_DC: 'DemosByDC',
  DIAGRAM: 'Diagram',
  DNS_ASSETS: 'DnsAssets',
  EXTERNAL_DNS: 'ExternalDnsRecords',
  GENERAL: 'General',
  HARDWARE_INVENTORY_SCRIPTS: 'HardwareInventoryScripts',
  HARDWARE_ITEM: 'HardwareItem',
  HARDWARE_ITEMS: 'HardwareItems',
  HARDWARE_START_ORDER: 'HardwareStartOrder',
  INBOUND_PROXY_RULES: 'InboundProxyRules',
  MAIL_SERVER: 'MailServer',
  NAT_RULES: 'NatRules',
  NETWORKS: 'Networks',
  OPERATING_SYSTEMS: 'OperatingSystems',
  REMOTE_ACCESS: 'RemoteAccess',
  SESSIONS: 'Sessions',
  TOPOLOGIES: 'Topologies',
  VIRTUAL_MACHINE: 'VirtualMachine',
  VIRTUAL_MACHINES: 'VirtualMachines',
  VIRTUAL_MACHINES_DEMOS_INVENTORY: 'VirtualMachinesDemosInventory',
  VIRTUAL_MACHINES_NETWORK_INTERFACE_TYPES:
    'VirtualMachinesNetworkInterfaceTypes',
  VIRTUAL_MACHINES_TEMPLATES_INVENTORY: 'VirtualMachinesTemplatesInventory',
  VIRTUAL_MACHINE_TARGETS: 'VirtualMachine',
  VM_START_ORDER: 'VMStartOrder',
  VM_STOP_ORDER: 'VMStopOrder',
};

const tagTypes = [
  TAGS.DEMOS_BY_DC,
  TAGS.EXTERNAL_DNS,
  TAGS.DIAGRAM,
  TAGS.DNS_ASSETS,
  TAGS.GENERAL,
  TAGS.INBOUND_PROXY_RULES,
  TAGS.VIRTUAL_MACHINE,
  TAGS.VIRTUAL_MACHINES,
  TAGS.VIRTUAL_MACHINES_DEMOS_INVENTORY,
  TAGS.VIRTUAL_MACHINES_TEMPLATES_INVENTORY,
  TAGS.VIRTUAL_MACHINE_TARGETS,
  TAGS.NAT_RULES,
  TAGS.MAIL_SERVER,
  TAGS.NETWORKS,
  TAGS.OPERATING_SYSTEMS,
  TAGS.VM_START_ORDER,
  TAGS.VM_STOP_ORDER,
  TAGS.HARDWARE_ITEM,
  TAGS.HARDWARE_ITEMS,
  TAGS.HARDWARE_START_ORDER,
  TAGS.HARDWARE_INVENTORY_SCRIPTS,
  TAGS.REMOTE_ACCESS,
  TAGS.SESSIONS,
  TAGS.TOPOLOGIES,
  TAGS.VIRTUAL_MACHINES_NETWORK_INTERFACE_TYPES,
];

const axiosBaseQuery = (
  { baseUrl }: { baseUrl: string } = { baseUrl: '' },
): BaseQueryFn<
  {
    url: string;
    method: AxiosRequestConfig['method'];
    data?: AxiosRequestConfig['data'];
    successMessage?: string;
    errorMessage?: string;
  },
  BaseQueryApi
> => async ({ url, method, data, successMessage, errorMessage }, api) => {
  try {
    console.log({ baseUrl });
    const result = await instance({
      data,
      headers: createHeaders(method, api),
      method,
      url: `${baseUrl}${url}`,
    });

    stripAndsetEtag(result, api);
    successMessage && dispatchSuccessToast(successMessage, api);

    return {
      data:
        result.data && '_embedded' in result.data
          ? result.data._embedded
          : result.data,
    };
  } catch (axiosError) {
    const error = axiosError as AxiosError;
    dispatchErrorToast(error, api, errorMessage);

    return {
      error: { data: error.response?.data, status: error.response?.status },
    };
  }
};

export const dcloudApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL || '/api',
  }),
  endpoints: () => ({}),
  reducerPath: 'dcloudApi',
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  tagTypes,
});

export const dcloudApiAxios = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL || '/api',
  }),
  endpoints: () => ({}),
  reducerPath: 'dcloudApiAxios',
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  tagTypes,
});
