import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  IconNames,
  Label,
  LabelColours,
} from 'dcloud-shared-ui';
import { MainRoutes } from '../../../../constants/routes';
import { TopologyActions } from '../TopologyActions';
import { determinePushAction, isTopologyInSyncWithDCloud } from '../../utils';
import { TopologiesInSyncLabel } from '../TopologiesInSyncLabel';
import styles from './TopologyCard.module.css';

export interface TopologyAction {
  icon: IconNames;
  onClick: VoidFunction;
}

export interface TopologyLabel {
  colour?: LabelColours;
  text: DataCenter | TopologyStatus;
}

export interface TopologyCardProps {
  className?: string;
  onDelete: VoidFunction;
  onClone: VoidFunction;
  onPush: VoidFunction;
  onUpdate: VoidFunction;
  lastUpdatedDate?: string;
  topology: Topology;
}

export function TopologyCard({
  className,
  onDelete,
  onClone,
  onPush,
  onUpdate,
  lastUpdatedDate,
  topology,
}: TopologyCardProps): ReactElement {
  const { datacenter, description, name, uid } = topology;
  const TOPOLOGY_PATH = MainRoutes.Edit.replace(':uid', uid);

  const subtitleClasses = classnames(
    styles.subTitle,
    'text-size-14',
    'base-margin-bottom',
  );

  return (
    <div
      data-testid={`card-${topology.name.replace(/\s/g, '')}`}
      className={classnames(styles.root, className)}
    >
      <Card className="dbl-padding-left dbl-padding-right">
        <CardHeader>
          <h3 className="text-size-24 text-weight-700 base-margin-bottom">
            <Link className="truncate" to={TOPOLOGY_PATH}>
              {name}
            </Link>
          </h3>
          <h4 className={subtitleClasses}>
            {topology.demoId && (
              <span
                className={'dbl-margin-right'}
              >{`Demo ID: ${topology.demoId}`}</span>
            )}
            {lastUpdatedDate && lastUpdatedDate}
          </h4>
        </CardHeader>
        <CardContent className={styles.truncateOneLineCard}>
          {description}
        </CardContent>
        <CardFooter className="flex flex-between base-margin-top">
          <span className="flex flex-start flex-middle">
            {determinePushAction(topology, onPush, onUpdate)}
            <span className="base-margin-left">
              <TopologyActions
                className={styles.actions}
                onDelete={onDelete}
                onClone={onClone}
                uid={topology.uid}
                canClone={!!topology._links.clones}
              />
            </span>
          </span>
          <div aria-label="Card labels" className={styles.labels}>
            {isTopologyInSyncWithDCloud(topology) && <TopologiesInSyncLabel />}
            <Label key={datacenter} size="small" colour="tertiary">
              {datacenter}
            </Label>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
}
