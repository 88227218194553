import classnames from 'classnames';
import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ControlledCheckbox,
  ControlledTextfield,
} from '../../../../../../../components';
import styles from './AdvancedSettingsForm.module.css';

export function AdvancedSettingsForm(): ReactElement {
  const { control, errors } = useFormContext();
  const { t } = useTranslation();
  const formSectionClasses = classnames('col-6', styles.formSection);

  return (
    <div className={styles.root}>
      <div className={formSectionClasses}>
        <ControlledTextfield
          control={control}
          error={errors?.advancedSettings?.nameInHypervisor?.message}
          label={t('virtualMachines.edit.advancedSettings.nameInHypervisor')}
          name="advancedSettings.nameInHypervisor"
          required={true}
          testId="edit-vm-advancedSettings-nameInHypervisor"
        />
        <ControlledTextfield
          control={control}
          error={errors?.advancedSettings?.biosUuid?.message}
          label={t('virtualMachines.edit.advancedSettings.biosUuid')}
          name="advancedSettings.biosUuid"
          required={true}
          testId="edit-vm-advancedSettings-biosUuid"
        />
        <ControlledCheckbox
          className={styles.checkbox}
          control={control}
          id="advancedSettings.notStarted"
          label={t('virtualMachines.edit.advancedSettings.notStarted')}
          testId="edit-vm-remote-advancedSettings-notStarted-checkbox"
        />
        <ControlledCheckbox
          className={styles.checkbox}
          control={control}
          id="advancedSettings.allDisksNonPersistent"
          label={t(
            'virtualMachines.edit.advancedSettings.allDisksNonPersistent',
          )}
          testId="edit-vm-remote-advancedSettings-allDisksNonPersistent-checkbox"
        />
        <ControlledCheckbox
          className={styles.checkbox}
          control={control}
          id="advancedSettings.hiddenFromSession"
          label={t('virtualMachines.edit.advancedSettings.hiddenFromSession')}
          testId="edit-vm-remote-advancedSettings-hiddenFromSession-checkbox"
        />
      </div>
    </div>
  );
}
