import { connect } from 'react-redux';
import {
  getLicences,
  addLicences,
} from '../../../../redux/configuration/licences/slice';
import {
  fetchInventoryLicences,
  inventoryLicenceSelectors,
} from '../../../../redux/entities/inventory-licences/slice';
import { RootState } from '../../../../redux/store';
import { InventoryLicencesModal } from './InventoryLicencesModal';

export type InventoryLicencesProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const {
  getAvailableLicences,
  getInventoryLicences,
} = inventoryLicenceSelectors;

const mapStateToProps = (state: RootState) => ({
  adding: getLicences(state).adding,
  fetched: getInventoryLicences(state).fetched,
  inventoryLicences: getAvailableLicences(state),
  loading: getInventoryLicences(state).loading,
});

const mapDispatchToProps = {
  addLicences,
  fetchInventoryLicences,
};

export const ConnectedInventoryLicencesModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InventoryLicencesModal);
