import { connect } from 'react-redux';
import {
  topologiesSelectors,
  fetchTopologies,
} from '../../../../redux/topologies/slice';
import { RootState } from '../../../../redux/store';
import { TopologiesContent } from '.';

export type PropsFromRedux = ReturnType<typeof mapStateToProps>;

const mapStateToProps = (state: RootState) => ({
  hasError: topologiesSelectors.getHasError(state),
  topologies: topologiesSelectors.selectAll(state),
});

const mapDispatchToProps = {
  refetch: fetchTopologies,
};

export const ConnectedTopologiesContent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopologiesContent);
