import { useEffect } from 'react';
import * as d3 from 'd3';
import { UseConnection } from '../../Connections/hooks/use-connection';
import { useUpdateNodeMutation } from '../../../../../redux/diagram/api';
import { calculateDefaultPositionOnGraphBasedOnType, x, y } from '../../../d3';

interface UseResize extends BaseNodeProps {
  nodeRef: React.RefObject<HTMLDivElement>;
  redrawNodeConnections?: UseConnection['redrawNodeConnections'];
}

const ELEMENT_MIN_WIDTH = 200;
const ELEMENT_MAX_WIDTH = 800;

export function useResize({
  display,
  isReadOnly,
  nodeRef,
  redrawNodeConnections,
  uid,
}: UseResize): void {
  const [updateNode] = useUpdateNodeMutation();

  useEffect(() => {
    if (isReadOnly) return;

    const currentRef = nodeRef.current;

    const drag = d3
      .drag<HTMLSpanElement, DiagramDisplay>()
      .on('drag', function (event) {
        if (!currentRef) return;

        const defaultXY = calculateDefaultPositionOnGraphBasedOnType(
          currentRef,
        );

        const rect = currentRef.getBoundingClientRect();
        const transform = d3.zoomTransform(this);
        const [pointerX] = d3.pointer(event, currentRef);

        if (this.dataset.handle === 'right') {
          const width = Math.min(
            Math.max(pointerX / transform.k, ELEMENT_MIN_WIDTH),
            ELEMENT_MAX_WIDTH,
          );

          currentRef.style.width = `${width}px`;
        } else {
          const zy = transform.rescaleY(y);
          const component = d3.select(currentRef);
          const ty = zy(display?.y1 || defaultXY.y);
          const left = rect.left + pointerX / transform.k;

          const width = Math.min(
            Math.max((rect.right - left) / transform.k, ELEMENT_MIN_WIDTH),
            ELEMENT_MAX_WIDTH,
          );

          if (width === ELEMENT_MIN_WIDTH || width === ELEMENT_MAX_WIDTH)
            return;

          component.attr(
            'style',
            `transform: translate(${left}px, ${ty}px) scale(${transform.k}); width: ${width}px;`,
          );
        }

        redrawNodeConnections && redrawNodeConnections(uid);
      })
      .on('end', function () {
        if (!currentRef) return;

        const transform = d3.zoomTransform(this);
        const rect = currentRef.getBoundingClientRect();

        const newDisplay = {
          ...(display || {}),
          x1: x.invert(transform.invertX(rect.left)),
          x2: x.invert(transform.invertX(rect.right)),
        };

        updateNode({ display: newDisplay, nodeUid: uid });
      });

    if (currentRef) {
      d3.select<HTMLDivElement, DiagramDisplay>(currentRef)
        .selectAll<HTMLSpanElement, DiagramDisplay>(`[data-handle]`)
        .call(drag);
    }

    return () => {
      if (currentRef) {
        d3.select<HTMLDivElement, DiagramDisplay>(currentRef)
          .selectAll<HTMLSpanElement, DiagramDisplay>(`[data-handle]`)
          .on('mousedown.drag', null);
      }
    };
  }, [display, isReadOnly, nodeRef, redrawNodeConnections, updateNode, uid]);
}
