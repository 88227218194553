import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useURLParams } from '../../../../hooks/use-url-params';
import { RootState } from '../../../../redux/store';
import { sessionApi } from '../../../../redux/views/session-view/session/api';
import { diagramApi } from '../../../../redux/diagram/api';
import { getViewsState } from '../../../../redux/views/slice';
import { Nodes } from '.';

export type NodesContainerProps = ReturnType<typeof mapStateToProps>;

function DiagramNodesContainer({
  isReadOnly,
}: NodesContainerProps): ReactElement {
  const { uid: topologyUid } = useParams<TopologyUid>();
  const { data } = diagramApi.endpoints.getDiagram.useQueryState(topologyUid);

  return <Nodes nodes={data!.nodes} isReadOnly={isReadOnly} />;
}

function SessionNodesContainer({
  isReadOnly,
}: NodesContainerProps): ReactElement {
  const {
    path: { sessionUid },
    query: { versionUid },
  } = useURLParams({
    queryParams: ['versionUid'],
  });
  const { data } = sessionApi.endpoints.getSession.useQueryState({
    sessionUid,
    versionUid,
  });

  return <Nodes nodes={data!.diagram.nodes} isReadOnly={isReadOnly} />;
}

const mapStateToProps = (state: RootState) => ({
  isReadOnly: getViewsState(state).isReadOnly,
});

export const ConnectedSessionNodes = connect(mapStateToProps)(
  SessionNodesContainer,
);

export const ConnectedDiagramNodes = connect(mapStateToProps)(
  DiagramNodesContainer,
);
