import classnames from 'classnames';
import { Toast as SharedUIToast } from 'dcloud-shared-ui';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { removeToast } from '../../redux/toast/slice';
import styles from './Toast.module.css';

enum TOAST_ICON {
  error = 'error-outline',
  info = 'info',
  success = 'check',
}

enum ICON_COLOUR {
  error = 'danger',
  info = 'info',
  success = 'success',
}

export interface ToastProps extends Toast {
  className?: string;
  duration?: number;
}

export function Toast({
  className,
  duration = 0,
  message,
  uid,
  title,
  type,
}: ToastProps): ReactElement {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!duration) return;

    const timer = setTimeout(() => dispatch(removeToast(uid)), duration);

    return () => clearTimeout(timer);
  }, [dispatch, duration, uid]);

  return (
    <SharedUIToast
      className={classnames(styles.root, className)}
      iconColour={ICON_COLOUR[type]}
      iconName={TOAST_ICON[type]}
      message={message}
      onClose={() => dispatch(removeToast(uid))}
      title={title}
    />
  );
}
