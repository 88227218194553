import { connect } from 'react-redux';
import {
  closeEditModal,
  editLicence,
  licencesSelectors,
} from '../../../../redux/configuration/licences/slice';
import { RootState } from '../../../../redux/store';
import { EditLicenceModal } from '.';

export type EditLicenceModalReduxProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const mapStateToProps = (state: RootState) => ({
  licence: licencesSelectors.getLicenceToEdit(state),
  updating: licencesSelectors.getIsUpdating(state),
});

const mapDispatchToProps = {
  closeModal: closeEditModal,
  editLicence,
};

export const ConnectedEditLicenceModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditLicenceModal);
