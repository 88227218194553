import React, { ReactElement } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Home } from '../pages/Home';
import { EditTopology } from '../pages/EditTopology';
import { MainRoutes } from '../constants/routes';
import { ConnectedTopologies } from '../pages/Topologies/container';
import { CreateTopology } from '../pages/CreateTopology';
import { SessionView } from '../pages/Views/SessionView';

export function MainRouter(): ReactElement {
  return (
    <Switch>
      <Route path={MainRoutes.Edit} component={EditTopology} />
      <Route path={MainRoutes.Main} component={Home} exact={true} />
      <Route
        path={MainRoutes.Topologies}
        component={ConnectedTopologies}
        exact={true}
      />
      <Route path={MainRoutes.CreateTopology} component={CreateTopology} />
      <Route path={MainRoutes.Sessions} component={SessionView} />
    </Switch>
  );
}
