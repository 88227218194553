import React, { ReactElement, useEffect, useReducer } from 'react';
import {
  useRouteMatch,
  useParams,
  Redirect,
  useHistory,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PageHeading } from '../../../../../components/PageHeading';
import { PrimaryButton } from '../../../../../components/Buttons/PrimaryButton';
import { useCreateVmMutation } from '../../../../../redux/configuration/virtual-machines/api';
import { virtualMachinesSelectors } from '../../../../../redux/configuration/virtual-machines/slice';
import { getNetworkOptions } from './Utils';
import { ConfigureTable } from './components/Table';
import { reducer } from './Reducer';
import { useFetchNetworks } from './hooks/use-fetch-networks';
import { useCreateInitialState } from './hooks/use-create-initial-state';
import { useClearSelectedOnUnmount } from './hooks/use-clear-selected-on-unmount';
import { VirtualMachinesConfigureProps } from '.';

export function VirtualMachinesConfigure({
  interfaces,
}: VirtualMachinesConfigureProps): ReactElement {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const history = useHistory();
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const [createVm, { isLoading, isSuccess }] = useCreateVmMutation();
  const [{ isUnconfiguredNetwork, mappings }, dispatch] = useReducer(reducer, {
    isUnconfiguredNetwork: true,
    mappings: [],
  });
  const inventoryVm = useSelector(
    virtualMachinesSelectors.getVirtualMachineToConfigure,
  );

  useClearSelectedOnUnmount();
  const { data: networks } = useFetchNetworks(topologyUid);
  useCreateInitialState(dispatch, networks, interfaces);

  useEffect(() => {
    if (isSuccess) {
      history.push(url.substring(0, url.lastIndexOf('/add')));
    }
  }, [history, isSuccess, url]);

  if (!interfaces) {
    return <Redirect to={url.substring(0, url.lastIndexOf('/'))} />;
  }

  const onSubmit = () => {
    createVm({
      payload: {
        inventoryVmId: inventoryVm!.id,
        topology: { uid: topologyUid },
        vmNetworkInterfaces: mappings,
      },
    });
  };

  return (
    <>
      <PageHeading
        withBackBtn={true}
        ctaBtn={
          <PrimaryButton
            testId="add-vm"
            disabled={isUnconfiguredNetwork}
            loading={isLoading}
            onClick={onSubmit}
          >
            {t('buttons.add')}
          </PrimaryButton>
        }
        pageTitle={t('virtualMachines.configure.title')}
      />
      <ConfigureTable
        options={getNetworkOptions(networks || [])}
        interfaces={mappings}
        setNetwork={(interfaceName: string, networkUid: string) =>
          dispatch({ interfaceName, networkUid, type: 'SET_NETWORK' })
        }
        deleteNIC={(interfaceName: string) =>
          dispatch({ interfaceName, type: 'DELETE_NIC' })
        }
      />
    </>
  );
}
