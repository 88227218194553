/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import { useErrorToast } from '../../../../../../hooks/toasts/use-error-toast';
import { useGetNetworksQuery } from '../../../../../../redux/configuration/networks/api';

interface UseFetchNetworks {
  data?: Network[];
  isFetching: boolean;
}

export function useFetchNetworks(topologyUid: string): UseFetchNetworks {
  const { t } = useTranslation();
  const { showErrorToast } = useErrorToast();

  const { data, isFetching, isError } = useGetNetworksQuery(topologyUid);

  isError && showErrorToast(t('networks.fetch.errors.many'));

  return {
    data,
    isFetching,
  };
}
