import React, { ReactElement, useEffect } from 'react';
import { TopologiesLayout } from '../../layouts/TopologiesLayout';
import { ConnectedTopologiesContent } from './components/TopologiesContent/container';
import { ConnectedCloneTopologyModal } from './components/CloneTopologyModal/container';
import { ConnectedDeleteTopologyModal } from './components/DeleteTopologyModal/container';

interface TopologiesProps {
  fetchTopologies: VoidFunction;
  isLoading: boolean;
  reset: VoidFunction;
}

export function Topologies({
  fetchTopologies,
  isLoading,
  reset,
}: TopologiesProps): ReactElement {
  useEffect(() => {
    fetchTopologies();

    return () => {
      reset();
    };
  }, [fetchTopologies, reset]);

  return (
    <TopologiesLayout showLoadingSpinner={isLoading}>
      <ConnectedTopologiesContent />
      <ConnectedCloneTopologyModal />
      <ConnectedDeleteTopologyModal />
    </TopologiesLayout>
  );
}
