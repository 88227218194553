import { combineReducers } from 'redux';
import { documentationReducer } from './documentation/slice';
import { editEtagReducer } from './etag/slice';
import { hardwareReducer } from './hardware/slice';
import { licencesReducer } from './licences/slice';
import { networksReducer } from './networks/slice';
import { startStopOrderReducer } from './start-stop-order/slice';
import { inboundProxyRulesReducer } from './traffic/inbound-proxy-rules/slice';
import { mailServersReducer } from './traffic/mail-server/slice';
import { natRulesReducer } from './traffic/nat-rules/slice';
import { virtualMachinesReducer } from './virtual-machines/slice';

export const configurationReducer = combineReducers({
  documentation: documentationReducer,
  etag: editEtagReducer,
  hardware: hardwareReducer,
  inboundProxyRules: inboundProxyRulesReducer,
  licences: licencesReducer,
  mailServers: mailServersReducer,
  natRules: natRulesReducer,
  networks: networksReducer,
  startStopOrder: startStopOrderReducer,
  virtualMachines: virtualMachinesReducer,
});
