import * as yup from 'yup';
import i18n from '../i18n';
import { NetworkingFormData } from '../pages/EditTopology/configuration/VirtualMachines/Edit/components/Networking/components/NetworkingForm';
import {
  HOSTNAME_REGEX,
  IP_ADDRESS_REGEX,
  URL_PATH_REGEX,
} from '../utils/constants';

export enum MaxLengths {
  TopologyName = 255,
  NatName = 50,
  NetworkName = 255,
  NetworkDescription = 500,
  CloneName = 255,
  VirtualMachineName = 255,
  VirtualMachineDescription = 255,
  VirtualMachineCommandToExecute = 255,
  VirtualMachineRemoteAccessUsername = 255,
  VirtualMachineRemoteAccessPassword = 255,
  VirtualMachineNameInHypervisor = 255,
  VirtualMachineInternalUrlDescription = 255,
  InboundProxyRuleHyperLinkText = 15,
  InboundProxyRuleURLPath = 255,
  Hostname = 63,
}

export enum MinLengths {
  Adapter = 1,
  Required = 1,
}

export enum MaxValues {
  VirtualMachineGADelaySecs = 7200,
  VirtualMachineCpuQty = 999,
  VirtualMachineMemoryMb = 1048576,
  VirtualMachineMemoryGb = 1024,
  InboundProxyRuleTCPPort = 65535,
}

export enum MinValues {
  VirtualMachineGADelaySecs = 0,
  VirtualMachineCpuQty = 1,
  VirtualMachineMemoryMb = 4,
  VirtualMachineMemoryGb = 1,
  InboundProxyRuleTCPPort = 0,
}

export const MAC_ADDRESS_PREFIX = '00:50:56';

export const generalFieldsSchema: yup.SchemaOf<Omit<
  TopologyGeneralFields,
  'notes'
>> = yup.object().shape({
  description: yup
    .string()
    .required(i18n.t('general.form.description.validation.required')),
  name: yup
    .string()
    .required(i18n.t('general.form.name.validation.required'))
    .max(
      MaxLengths.TopologyName,
      i18n.t('general.form.name.validation.maxLength'),
    ),
});

export const primaryVlanFieldsSchema: yup.SchemaOf<Pick<
  Network,
  'name' | 'description'
>> = yup.object().shape({
  description: yup
    .string()
    .required(i18n.t('networks.form.description.validation.required')),
  name: yup
    .string()
    .required(i18n.t('networks.form.name.validation.required'))
    .max(
      MaxLengths.NetworkName,
      i18n.t('networks.form.name.validation.maxLength'),
    ),
});

export const cloneTopologyFieldsSchema: yup.SchemaOf<CloneFields> = yup
  .object()
  .shape({
    description: yup
      .string()
      .required(
        i18n.t('topologies.clone.form.description.validation.required'),
      ),
    name: yup
      .string()
      .required(i18n.t('topologies.clone.form.name.validation.required'))
      .max(
        MaxLengths.TopologyName,
        i18n.t('topologies.clone.form.name.validation.maxLength'),
      ),
  });

export const documentationFieldsSchema: yup.SchemaOf<DocumentationFields> = yup
  .object()
  .shape({
    demoProfileUrl: yup
      .string()
      .url(i18n.t('documentation.error.demoProfileUrl')),
    documentationUrl: yup
      .string()
      .url(i18n.t('documentation.error.documentationUrl')),
  });

export const networkFieldsSchema = (
  names: string[],
): yup.SchemaOf<NetworkFormFields> => {
  return yup.object().shape({
    description: yup
      .string()
      .required(i18n.t('networks.form.description.validation.required'))
      .max(
        MaxLengths.NetworkDescription,
        i18n.t('networks.form.description.validation.maxLength'),
      ),
    name: yup
      .string()
      .required(i18n.t('networks.form.name.validation.required'))
      .max(
        MaxLengths.NetworkName,
        i18n.t('networks.form.name.validation.maxLength'),
      )
      .test(
        'unique',
        i18n.t('networks.form.name.validation.unique'),
        (value) => !names.includes(value as string),
      )
      .matches(
        /^[a-zA-Z0-9\s]+$/,
        i18n.t('networks.form.name.validation.noSpecialCharacters'),
      ),
    subnet: yup
      .string()
      .required(i18n.t('networks.form.subnet.validation.required'))
      .min(1),
  });
};

export const vmEditFieldsSchema = (
  takenNames: string[],
): yup.SchemaOf<Pick<VirtualMachine, 'name' | 'description'>> =>
  yup.object().shape({
    advancedSettings: yup.object().shape({
      biosUuid: yup
        .string()
        .required(
          i18n.t(
            'virtualMachines.edit.formErrors.advancedSettings.biosUuid.required',
          ),
        )
        .matches(
          /^([\da-fA-F][\da-fA-F][\s]){7}([\da-fA-F][\da-fA-F])[-]([\da-fA-F][\da-fA-F][\s]){7}([\da-fA-F][\da-fA-F])$/,
          i18n.t(
            'virtualMachines.edit.formErrors.advancedSettings.biosUuid.patternMatch',
          ),
        ),
      nameInHypervisor: yup
        .string()
        .required(
          i18n.t(
            'virtualMachines.edit.formErrors.advancedSettings.nameInHypervisor.required',
          ),
        )
        .max(
          MaxLengths.VirtualMachineNameInHypervisor,
          i18n.t(
            'virtualMachines.edit.formErrors.advancedSettings.nameInHypervisor.maxLength',
          ),
        )
        .matches(
          /^[a-zA-Z0-9_-]+$/,
          i18n.t(
            'virtualMachines.edit.formErrors.advancedSettings.nameInHypervisor.allowedChars',
          ),
        ),
    }),
    cpuQty: yup
      .number()
      .typeError(i18n.t('virtualMachines.edit.formErrors.cpuQty'))
      .max(
        MaxValues.VirtualMachineCpuQty,
        i18n.t('virtualMachines.edit.formErrors.cpuQty'),
      )
      .min(
        MinValues.VirtualMachineCpuQty,
        i18n.t('virtualMachines.edit.formErrors.cpuQty'),
      ),
    description: yup
      .string()
      .required(i18n.t('virtualMachines.edit.formErrors.descriptionRequired'))
      .max(
        MaxLengths.VirtualMachineDescription,
        i18n.t('virtualMachines.edit.formErrors.descriptionMaxLength'),
      ),
    guestAutomation: yup.object().shape({
      command: yup
        .string()
        .max(
          MaxLengths.VirtualMachineCommandToExecute,
          i18n.t('virtualMachines.edit.formErrors.commandMaxLength'),
        ),
      delaySecs: yup
        .number()
        .typeError(i18n.t('virtualMachines.edit.formErrors.delayTypeError'))
        .max(
          MaxValues.VirtualMachineGADelaySecs,
          i18n.t('virtualMachines.edit.formErrors.delayMax'),
        )
        .min(
          MinValues.VirtualMachineGADelaySecs,
          i18n.t('virtualMachines.edit.formErrors.delayMin'),
        )
        .test(
          'divisibleByFive',
          i18n.t('virtualMachines.edit.formErrors.delayIncrementsOf5'),
          (value) => {
            if (value === 0) return true;
            if (value && value % 5 === 0) return true;
            return false;
          },
        ),
    }),
    memoryGb: yup
      .number()
      .typeError(i18n.t('virtualMachines.edit.formErrors.memoryGbLimits'))
      .max(
        MaxValues.VirtualMachineMemoryGb,
        i18n.t('virtualMachines.edit.formErrors.memoryGbLimits'),
      )
      .min(
        MinValues.VirtualMachineMemoryGb,
        i18n.t('virtualMachines.edit.formErrors.memoryGbLimits'),
      ),
    memoryMb: yup
      .number()
      .typeError(i18n.t('virtualMachines.edit.formErrors.memoryMbLimits'))
      .max(
        MaxValues.VirtualMachineMemoryMb,
        i18n.t('virtualMachines.edit.formErrors.memoryMbLimits'),
      )
      .min(
        MinValues.VirtualMachineMemoryMb,
        i18n.t('virtualMachines.edit.formErrors.memoryMbLimits'),
      )
      .test(
        'divisibleByFour',
        i18n.t('virtualMachines.edit.formErrors.memoryMbMultipleOf4'),
        (value) => {
          if (value === 0) return false;
          if (value && value % 4 === 0) return true;
          return false;
        },
      ),
    name: yup
      .string()
      .required(i18n.t('virtualMachines.edit.formErrors.nameRequired'))
      .max(
        MaxLengths.VirtualMachineName,
        i18n.t('virtualMachines.edit.formErrors.nameMaxLength'),
      )
      .notOneOf(
        takenNames,
        i18n.t('virtualMachines.edit.formErrors.nameUnique'),
      ),
    remoteAccess: yup.object().shape({
      displayCredentials: yup.object().shape({
        password: yup
          .string()
          .max(
            MaxLengths.VirtualMachineRemoteAccessPassword,
            i18n.t(
              'virtualMachines.edit.formErrors.remoteAccess.displayCredentials.passwordMaxLength',
            ),
          ),
        username: yup
          .string()
          .max(
            MaxLengths.VirtualMachineRemoteAccessUsername,
            i18n.t(
              'virtualMachines.edit.formErrors.remoteAccess.displayCredentials.usernameMaxLength',
            ),
          ),
      }),
      internalUrls: yup.array().of(RemoteAccessInternalUrlSchema),
      password: yup
        .string()
        .max(
          MaxLengths.VirtualMachineRemoteAccessPassword,
          i18n.t(
            'virtualMachines.edit.formErrors.remoteAccess.passwordMaxLength',
          ),
        ),
      rdp: yup.object().shape({
        isAutoLogin: yup.boolean(),
        vmNetworkInterface: yup.object().shape({
          isEnabled: yup.boolean(),
          uid: yup.string().when('isEnabled', {
            is: true,
            then: yup
              .string()
              .min(
                MinLengths.Adapter,
                i18n.t('virtualMachines.edit.formErrors.remoteAccess.adapter'),
              ),
          }),
        }),
      }),
      ssh: yup.object().shape({
        vmNetworkInterface: yup.object().shape({
          isEnabled: yup.boolean(),
          uid: yup.string().when('isEnabled', {
            is: true,
            then: yup
              .string()
              .min(
                MinLengths.Adapter,
                i18n.t('virtualMachines.edit.formErrors.remoteAccess.adapter'),
              ),
          }),
        }),
      }),
      username: yup
        .string()
        .max(
          MaxLengths.VirtualMachineRemoteAccessUsername,
          i18n.t(
            'virtualMachines.edit.formErrors.remoteAccess.usernameMaxLength',
          ),
        ),
    }),
  });

export const vmNetworkInterfaceFieldsSchema: yup.SchemaOf<NetworkingFormData> = yup
  .object()
  .shape({
    ipAddress: yup
      .string()
      .matches(
        new RegExp(`^$|${IP_ADDRESS_REGEX.source}`),
        i18n.t('virtualMachines.edit.formErrors.networking.ipAddress'),
      ),
    macAddress: yup
      .string()
      .matches(
        /^$|^([\da-fA-F]{2}[:]){5}([\da-fA-F]{2})$/,
        i18n.t(
          'virtualMachines.edit.formErrors.networking.macAddress.patternMatch',
        ),
      )
      .test(
        'prefixPatternMatch',
        i18n.t(
          'virtualMachines.edit.formErrors.networking.macAddress.prefixPatternMatch',
        ),
        (value) => {
          if (value && value.length > MAC_ADDRESS_PREFIX.length) {
            return (
              value.substring(0, MAC_ADDRESS_PREFIX.length) ===
              MAC_ADDRESS_PREFIX
            );
          } else {
            return true;
          }
        },
      ),
    networkName: yup
      .string()
      .required(
        i18n.t('virtualMachines.edit.formErrors.networking.networkName'),
      ),
    type: yup
      .string()
      .required(i18n.t('virtualMachines.edit.formErrors.networking.type')),
  });

const RemoteAccessInternalUrlSchema = yup.object().shape({
  description: yup
    .string()
    .max(
      MaxLengths.VirtualMachineInternalUrlDescription,
      i18n.t(
        'virtualMachines.edit.formErrors.internalUrls.description.maxLength',
      ),
    )
    .test(
      'locationEmptyCheck',
      i18n.t(
        'virtualMachines.edit.formErrors.internalUrls.description.emptyUrlWarning',
      ),
      function (value) {
        return !(value?.length === 0 && this?.parent.location.length > 0);
      },
    ),
  location: yup
    .string()
    .url(i18n.t('virtualMachines.edit.formErrors.internalUrls.location.url'))
    .test(
      'descriptionEmptyCheck',
      i18n.t(
        'virtualMachines.edit.formErrors.internalUrls.location.emptyDescriptionWarning',
      ),
      function (value) {
        return !(value?.length === 0 && this?.parent.description.length > 0);
      },
    ),
});

export const natRulesSchema: yup.SchemaOf<Omit<
  NatRuleFormData,
  'eastWest'
>> = yup.object().shape({
  ipAddress: yup.string().when('type', {
    is: 'IP',
    then: yup
      .string()
      .matches(
        IP_ADDRESS_REGEX,
        i18n.t('traffic.natRules.formErrors.ipAddressInvalid'),
      )
      .required(i18n.t('traffic.natRules.formErrors.ipAddressInvalid')),
    // eslint-disable-next-line sort-keys
    otherwise: yup
      .string()
      .min(
        MinLengths.Required,
        i18n.t('traffic.natRules.formErrors.ipAddressNotSelected'),
      ),
  }),
  name: yup.string().when('type', {
    is: 'IP',
    then: yup
      .string()
      .required(i18n.t('traffic.natRules.formErrors.targetRequired'))
      .max(
        MaxLengths.TopologyName,
        i18n.t('traffic.natRules.formErrors.targetMax'),
      ),
    // eslint-disable-next-line sort-keys
    otherwise: yup
      .string()
      .required(i18n.t('traffic.natRules.formErrors.targetNotSelected')),
  }),
  targetItem: yup.object().shape({
    uid: yup
      .string()
      .min(
        MinLengths.Required,
        i18n.t('traffic.natRules.formErrors.ipAddressNotSelected'),
      ),
  }),
  type: yup
    .mixed<NatRuleTarget['type'] | ''>()
    .oneOf(
      ['IP', 'VM_NIC'],
      i18n.t('traffic.natRules.formErrors.typeRequired'),
    ),
});

export const inboundProxyRulesSchema: yup.SchemaOf<Omit<
  InboundProxyRuleFormData,
  'ssl' | 'showHyperlink'
>> = yup.object().shape({
  hyperLinkText: yup
    .string()
    .required(
      i18n.t('traffic.inboundProxyRules.formErrors.hyperLinkTextRequired'),
    )
    .max(
      MaxLengths.InboundProxyRuleHyperLinkText,
      i18n.t('traffic.inboundProxyRules.formErrors.hyperLinkTextMax'),
    ),
  ipAddress: yup
    .string()
    .required(i18n.t('traffic.inboundProxyRules.formErrors.ipAddressRequired')),
  target: yup
    .string()
    .required(i18n.t('traffic.inboundProxyRules.formErrors.targetRequired'))
    .test(
      'targetRequiredTest',
      i18n.t('traffic.inboundProxyRules.formErrors.targetRequired'),
      (value) => {
        if (!value) return false;
        return value.length >= 1;
      },
    ),
  tcpPort: yup
    .number()
    .typeError(i18n.t('traffic.inboundProxyRules.formErrors.tcpPortRequired'))
    .min(
      MinValues.InboundProxyRuleTCPPort,
      i18n.t('traffic.inboundProxyRules.formErrors.tcpPortMin'),
    )
    .max(
      MaxValues.InboundProxyRuleTCPPort,
      i18n.t('traffic.inboundProxyRules.formErrors.tcpPortMax'),
    )
    .required(i18n.t('traffic.inboundProxyRules.formErrors.tcpPortRequired')),
  urlPath: yup
    .string()
    .matches(
      URL_PATH_REGEX,
      i18n.t('traffic.inboundProxyRules.formErrors.urlPathMatch'),
    )
    .required(i18n.t('traffic.inboundProxyRules.formErrors.urlPathRequired'))
    .max(
      MaxLengths.InboundProxyRuleURLPath,
      i18n.t('traffic.inboundProxyRules.formErrors.urlPathMax'),
    ),
});

export const mailServerSchema: yup.SchemaOf<MailServerFormData> = yup
  .object()
  .shape({
    asset: yup.string().optional(),
    ipAddress: yup
      .string()
      .required(i18n.t('traffic.mailServer.formErrors.ipAddressNotSelected')),
    virtualMachine: yup
      .string()
      .required(i18n.t('traffic.mailServer.formErrors.vmNotSelected'))
      .test(
        'targetRequiredTest',
        i18n.t('traffic.mailServer.formErrors.vmNotSelected'),
        (value) => {
          if (!value) return false;
          return value.length >= 1;
        },
      ),
  });

export const externalDnsScheme: yup.SchemaOf<ExternalDnsFormData> = yup
  .object()
  .shape({
    asset: yup.string().optional(),
    hostname: yup
      .string()
      .matches(
        HOSTNAME_REGEX,
        i18n.t('traffic.externalDns.formErrors.hostnameMatch'),
      )
      .required(i18n.t('traffic.externalDns.formErrors.hostnameRequired'))
      .max(
        MaxLengths.Hostname,
        i18n.t('traffic.externalDns.formErrors.hostnameMaxLength'),
      ),
    natRule: yup
      .string()
      .required(i18n.t('traffic.externalDns.formErrors.natRuleNotSelected'))
      .test(
        'natRequiredTest',
        i18n.t('traffic.externalDns.formErrors.natRuleNotSelected'),
        (value) => {
          if (!value) return false;
          return value.length >= 1;
        },
      ),
  });
