export type NetworkConfigureState = {
  isUnconfiguredNetwork: boolean;
  mappings: NetworkToConfigure[];
};

export type Actions =
  | { type: 'SET_NETWORK'; interfaceName: string; networkUid: string }
  | { type: 'DELETE_NIC'; interfaceName: string }
  | {
      type: 'SET_INITIAL_STATE';
      interfaces: InventoryVMNetworkInterface[];
      networks: Network[];
    };

export function reducer(
  state: NetworkConfigureState,
  action: Actions,
): NetworkConfigureState {
  switch (action.type) {
    case 'SET_INITIAL_STATE': {
      const mappings: NetworkToConfigure[] = action.interfaces.map(
        (networkInterface) => {
          let networkUid: string | undefined;
          action.networks.forEach((network) =>
            network.inventoryNetwork.id === networkInterface.inventoryNetworkId
              ? (networkUid = network.uid)
              : null,
          );
          return networkUid
            ? {
                ...networkInterface,
                network: { uid: networkUid },
              }
            : { ...networkInterface };
        },
      );
      return {
        ...state,
        isUnconfiguredNetwork: !!mappings.find((mapping) => !mapping.network),
        mappings,
      };
    }

    case 'SET_NETWORK': {
      const newMappings = state.mappings.map((mapping) =>
        mapping.name === action.interfaceName
          ? { ...mapping, network: { uid: action.networkUid } }
          : mapping,
      );
      return {
        ...state,
        isUnconfiguredNetwork: !!newMappings.find(
          (mapping) => !mapping.network || mapping.network.uid === '',
        ),
        mappings: newMappings,
      };
    }

    case 'DELETE_NIC': {
      const newDeleteMappings = state.mappings.filter(
        (mapping) => mapping.name !== action.interfaceName,
      );
      return {
        ...state,
        isUnconfiguredNetwork: !!newDeleteMappings.find(
          (mapping) => !mapping.network || mapping.network.uid === '',
        ),
        mappings: newDeleteMappings,
      };
    }

    default:
      return state;
  }
}
