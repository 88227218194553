import { connect } from 'react-redux';
import {
  getVirtualMachines,
  setVirtualMachineToDelete,
} from '../../../../../redux/configuration/virtual-machines/slice';
import { RootState } from '../../../../../redux/store';
import { VirtualMachines } from '.';

export type VirtualMachinesProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const mapStateToProps = (state: RootState) => ({
  virtualMachineToDelete: getVirtualMachines(state).virtualMachineToDelete,
});

const mapDispatchToProps = {
  setVirtualMachineToDelete,
};

export const VirtualMachinesContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VirtualMachines);
