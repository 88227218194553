import React, { ReactElement } from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ControlledCheckbox,
  ControlledTextfield,
} from '../../../../../../../components';
import styles from './NatRulesIpInfo.module.css';

interface NatRulesIpInfoProps {
  control: Control<NatRuleFormData>;
  errors: DeepMap<NatRuleFormData, FieldError>;
}

export function NatRulesIpInfo({
  control,
  errors,
}: NatRulesIpInfoProps): ReactElement {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <ControlledTextfield
        className={styles.targetField}
        control={control}
        label={t('traffic.natRules.table.headings.target')}
        name="name"
        required={true}
        error={errors?.name?.message}
      />
      <ControlledTextfield
        control={control}
        label={t('traffic.natRules.table.headings.ipAddress')}
        name="ipAddress"
        required={true}
        error={errors?.ipAddress?.message}
      />
      <ControlledCheckbox
        className={styles.verticalAlign}
        control={control}
        label={t('traffic.natRules.table.headings.eastWestNat')}
        id="eastWest"
      />
    </div>
  );
}
