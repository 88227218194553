import { useEffect } from 'react';
import { Actions } from '../Reducer';

export interface NetworkConfigureState {
  isUnconfiguredNetwork: boolean;
  mappings: NetworkToConfigure[];
}

export function useCreateInitialState(
  dispatch: React.Dispatch<Actions>,
  data?: Network[],
  interfaces?: InventoryVMNetworkInterface[],
): void {
  useEffect(() => {
    if (interfaces && data) {
      dispatch({
        interfaces: interfaces,
        networks: data,
        type: 'SET_INITIAL_STATE',
      });
    }
  }, [data, dispatch, interfaces]);
}
