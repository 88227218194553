import i18n from '../../../../i18n';
import blankIcon from '../../../../assets/images/icons/blank.svg';
import importIcon from '../../../../assets/images/icons/import.svg';
import { CreateTopologyRoutes } from '../../constants';
import { MainRoutes } from '../../../../constants/routes';

interface DemoTypeItem {
  icon: string;
  title: string;
  subTitle: string;
  type: string;
  path: string;
}

export const DEMO_TYPES: DemoTypeItem[] = [
  {
    icon: blankIcon,
    path: `${MainRoutes.CreateTopology}${CreateTopologyRoutes.Details}`,
    subTitle: i18n.t('createTopology.demo.blank.subTitle'),
    title: i18n.t('createTopology.demo.blank.title'),
    type: 'BLANK',
  },
  {
    icon: importIcon,
    path: `${MainRoutes.CreateTopology}${CreateTopologyRoutes.DemoImport}`,
    subTitle: i18n.t('createTopology.demo.import.subTitle'),
    title: i18n.t('createTopology.demo.import.title'),
    type: 'IMPORT',
  },
];
