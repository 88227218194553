import React, { ReactElement } from 'react';
import { LinkButton } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import { MainRoutes } from '../../../../constants/routes';
import { CreateTopologyRoutes } from '../../constants';
import { ImportDemosTable } from './components/DemosTable';

export interface DemoImportProps {
  demos: InventoryDemo[];
  isLoading: boolean;
  setDemoToImport: (demo: InventoryDemo) => void;
}

export function DemoImport({
  demos,
  isLoading,
  setDemoToImport,
}: DemoImportProps): ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <div className="row flex-center">
        <ImportDemosTable
          setDemoToImport={setDemoToImport}
          demos={demos}
          isLoading={isLoading}
        />
      </div>
      <div className="text-center dbl-margin-top">
        <LinkButton to={MainRoutes.Topologies} colour="ghost" size="large">
          {t('buttons.cancel')}
        </LinkButton>
        <LinkButton
          to={`${MainRoutes.CreateTopology}${CreateTopologyRoutes.DemoType}`}
          colour="ghost"
          size="large"
          testId="create-topology-demo-type-back-button"
        >
          {t('buttons.back')}
        </LinkButton>
      </div>
    </>
  );
}
