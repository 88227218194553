import { SelectOption } from 'dcloud-shared-ui';
import { PLEASE_SELECT_OPTION } from '../../../../../constants/form';

interface BuildMailServerPayloadProps {
  formData: ExternalDnsFormData;
  topologyUid: TopologyUid['uid'];
}
export const buildExternalDnsPayload = ({
  formData,
  topologyUid,
}: BuildMailServerPayloadProps): ExternalDnsPostPayload => {
  const { natRule, asset, hostname } = formData;
  let payload;
  asset === ''
    ? (payload = {
        hostname: hostname,
        natRule: {
          uid: natRule,
        },
        topology: { uid: topologyUid },
      })
    : (payload = {
        hostname: hostname,
        inventoryDnsAsset: {
          id: asset,
        },
        natRule: {
          uid: natRule,
        },
        topology: { uid: topologyUid },
      });
  return payload;
};

export const buildNatOptions = (
  natRules: NatRule[],
): SelectOption<string>[] => {
  const options = natRules.map(({ uid, target }: NatRule) => ({
    name: `${target.name} ( ${target.ipAddress} )`,
    value: uid!,
  }));

  return [PLEASE_SELECT_OPTION, ...options];
};
