import React, { ReactElement, useEffect } from 'react';
import { LinkButton } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import { LinkImageCard } from '../../../../components/Cards/LinkImageCard';
import { MainRoutes } from '../../../../constants/routes';
import { DEMO_TYPES } from './constants';

export interface DemoTypeProps {
  setDemoType: (demoType: string) => void;
  resetDemoToImport: VoidFunction;
}

export function DemoType({
  setDemoType,
  resetDemoToImport,
}: DemoTypeProps): ReactElement {
  const { t } = useTranslation();

  useEffect(() => {
    resetDemoToImport();
  }, [resetDemoToImport]);

  return (
    <>
      <div className="row flex-center">
        {DEMO_TYPES.map((demoType) => (
          <div key={demoType.title} className="col-md-3">
            <LinkImageCard
              path={demoType.path}
              imageAltText={demoType.title}
              imageSrc={demoType.icon}
              title={demoType.title}
              subTitle={demoType.subTitle}
              testId={`demo-type-${demoType.type.toLowerCase()}`}
              onClick={() => setDemoType(demoType.type)}
            />
          </div>
        ))}
      </div>
      <div className="flex flex-center dbl-margin-top">
        <LinkButton to={MainRoutes.Topologies} colour="ghost" size="large">
          {t('buttons.cancel')}
        </LinkButton>
        <LinkButton
          to={MainRoutes.CreateTopology}
          colour="ghost"
          size="large"
          testId="create-topology-demo-type-back-button"
        >
          {t('buttons.back')}
        </LinkButton>
      </div>
    </>
  );
}
