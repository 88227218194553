import React, { ReactElement, useMemo } from 'react';
import { format } from 'date-fns';
import { Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import { Table } from '../../../../components/Table';
import { TopologyActions } from '../TopologyActions';
import {
  TOPOLOGIES_LAST_UPDATED_FORMAT,
  TOPOLOGIES_LIMIT,
} from '../../constants';
import { addOnPushActionToTopologies } from '../../utils';
import { LinkToTopology } from './LinkToTopology';
import styles from './TopologiesTable.module.css';
import { TwoLineTruncatedCell } from './TwoLineTruncatedCell';
import { StatusCell } from './StatusCell';

export interface TopologiesTableProps {
  onClone: TopologyAction;
  onDelete: TopologyAction;
  onPush: TopologyAction;
  onUpdate: TopologyAction;
  topologies: Topology[];
}

export function TopologiesTable({
  onClone,
  onDelete,
  onPush,
  onUpdate,
  topologies,
}: TopologiesTableProps): ReactElement {
  const { t } = useTranslation();

  const columns = useMemo<Column<TopologyWithOnPushActions>[]>(
    () => [
      {
        Cell: LinkToTopology,
        Header: () => t('common.name'),
        accessor: 'name',
        minWidth: 250,
      },
      {
        Cell: TwoLineTruncatedCell,
        Header: () => t('common.description'),
        accessor: 'description',
        minWidth: 250,
      },
      {
        Cell: ({ value }) => value || '',
        Header: () => t('common.demoId'),
        accessor: 'demoId',
        width: 100,
      },
      {
        Header: () => t('common.dataCenter'),
        accessor: 'datacenter',
        width: 100,
      },
      {
        Cell: ({ value }) =>
          format(new Date(value), TOPOLOGIES_LAST_UPDATED_FORMAT),
        Header: () => t('common.lastUpdated'),
        accessor: 'lastUpdated',
        id: 'lastUpdated',
        minWidth: 100,
      },
      {
        Cell: StatusCell,
        Header: () => t('common.status'),
        accessor: 'status',
        id: 'status',
        minWidth: 200,
      },
    ],
    [t],
  );

  const topologiesWithOnPushActions = useMemo<TopologyWithOnPushActions[]>(
    () => addOnPushActionToTopologies(topologies, onPush, onUpdate),
    [onPush, onUpdate, topologies],
  );

  function renderActions(topology: Topology): ReactElement {
    return (
      <TopologyActions
        uid={topology.uid}
        onDelete={() => onDelete(topology)}
        canClone={!!topology._links.clones}
        onClone={() => onClone(topology)}
      />
    );
  }

  return (
    <div className={styles.root} data-testid="topologies-table">
      <Table<TopologyWithOnPushActions>
        className={styles.table}
        actions={renderActions}
        columns={columns}
        data={topologiesWithOnPushActions}
        initialState={{
          pageSize: TOPOLOGIES_LIMIT,
          sortBy: [{ desc: true, id: 'lastUpdated' }],
        }}
      />
    </div>
  );
}
