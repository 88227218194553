import React, { ReactElement } from 'react';
import { AnyconnectNode } from '../AnyconnectNode';
import { EndpointKitNode } from '../EndpointKitNode';
import { HardwareNode } from '../HardwareNode';
import { InternetCloudNode } from '../InternetCloudNode';
import { NetworkNode } from '../NetworkNode';
import { VmNode } from '../VmNode';
import { VPodGateway } from '../VPodGateway';
import styles from './Nodes.module.css';

interface NodesProps {
  isReadOnly: boolean;
  nodes: DiagramNodes;
}

export function Nodes({ isReadOnly, nodes }: NodesProps): ReactElement {
  return (
    <div className={styles.root} data-clickablearea>
      {nodes.map((node) => {
        switch (node.assetType) {
          case 'NETWORK':
            return (
              <NetworkNode {...node} key={node.uid} isReadOnly={isReadOnly} />
            );

          case 'HARDWARE_ITEM':
            return (
              <HardwareNode {...node} key={node.uid} isReadOnly={isReadOnly} />
            );

          case 'INTERNET_CLOUD':
            return (
              <InternetCloudNode
                {...node}
                key={node.uid}
                isReadOnly={isReadOnly}
              />
            );

          case 'VPOD_GATEWAY':
            return (
              <VPodGateway
                display={node.display}
                key={node.uid}
                uid={node.uid}
                isReadOnly={isReadOnly}
              />
            );

          case 'VM':
            return <VmNode {...node} key={node.uid} isReadOnly={isReadOnly} />;

          case 'ANY_CONNECT':
            return (
              <AnyconnectNode
                {...node}
                key={node.uid}
                isReadOnly={isReadOnly}
              />
            );

          case 'ENDPOINT_KIT':
            return (
              <EndpointKitNode
                {...node}
                key={node.uid}
                isReadOnly={isReadOnly}
              />
            );

          default:
            return null;
        }
      })}
    </div>
  );
}
