import { v4 as uuid } from 'uuid';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import i18n from '../../i18n';

export type ToastToAdd = Omit<Toast, 'uid'> & { uid?: string };
export type TemplateToastToAdd = Omit<ToastToAdd, 'title' | 'type'> & {
  title?: string;
  type?: ToastTypes;
};

export const initialState: ToastState = {
  toasts: [],
};

const slice = createSlice({
  initialState,
  name: 'toast',
  reducers: {
    addErrorToast(state, { payload }: PayloadAction<TemplateToastToAdd>) {
      const newToast: Toast = {
        message: payload.message || i18n.t('common.errorMessage'),
        title: payload.title || i18n.t('common.error'),
        type: 'error',
        uid: uuid(),
      };
      state.toasts = [...state.toasts, newToast];
    },
    addSuccessToast(state, { payload }: PayloadAction<TemplateToastToAdd>) {
      const newToast: Toast = {
        message: payload.message,
        title: payload.title || i18n.t('common.success'),
        type: 'success',
        uid: uuid(),
      };
      state.toasts = [...state.toasts, newToast];
    },
    addToast(state, { payload }: PayloadAction<ToastToAdd>) {
      state.toasts = [...state.toasts, { uid: uuid(), ...payload }];
    },
    removeToast(state, { payload }: PayloadAction<string>) {
      state.toasts = state.toasts.filter(({ uid }) => uid !== payload);
    },
  },
});

export const getToasts = (state: RootState): Toast[] =>
  state[slice.name].toasts;

export const toastReducer = slice.reducer;

export const {
  addErrorToast,
  addSuccessToast,
  addToast,
  removeToast,
} = slice.actions;
