import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ControlledCheckbox,
  ControlledTextfield,
  ReadonlyField,
} from '../../../../../../components';

interface FormGeneralProps {
  hardware: Hardware;
}

export function FormGeneral({ hardware }: FormGeneralProps): ReactElement {
  const { t } = useTranslation();
  const { control, errors } = useFormContext();

  return (
    <div className="row">
      <div className="col-6">
        <ReadonlyField
          label={t('hardware.edit.inventoryNameLabel')}
          value={hardware.inventoryHardwareItem.name}
        />
        <ControlledTextfield
          label={t('hardware.edit.nameLabel')}
          name="name"
          control={control}
          horizontal={false}
          required={true}
          error={errors?.name?.message}
          className="dbl-margin-bottom"
        />
        <div className="base-margin-top base-margin-bottom">
          <ControlledCheckbox
            control={control}
            label={t('hardware.edit.enablePowerControl')}
            defaultValue={hardware.powerControlEnabled}
            disabled={!hardware.inventoryHardwareItem.powerControlAvailable}
            id="powerControlEnabled"
          />
        </div>
        <div>
          <ControlledCheckbox
            control={control}
            defaultValue={hardware.hardwareConsoleEnabled}
            disabled={!hardware.inventoryHardwareItem.hardwareConsoleAvailable}
            id="hardwareConsoleEnabled"
            label={t('hardware.edit.enableHardwareConsole')}
          />
        </div>
      </div>
    </div>
  );
}
