import React, { ReactElement } from 'react';
import classnames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Alert } from 'dcloud-shared-ui';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import {
  ControlledCheckbox,
  ControlledSelect,
  ControlledTextfield,
} from '../../../../../../../components';
import { getAdapterOptions } from '../../utils';
import styles from './RemoteAccessForm.module.css';
import { useHandleFormChanges } from './hooks/use-handle-form-changes';

export const TEST_IDS = {
  isRDPEnabledCheckbox: 'edit-vm-remote-access-web-rdp-checkbox',
  isSSHEnabledCheckbox: 'edit-vm-remote-access-vm-ssh-access-checkbox',
  rdpAdapterSelect: 'edit-vm-remote-access-web-rdp-adapter',
  rdpAutoLoginCheckbox: 'edit-vm-remote-access-web-rdp-auto-login-checkbox',
  sshAdapterSelect: 'edit-vm-remote-access-ssh-adapter',
  sshInputs: 'sshInputs',
  webRDPInputs: 'webRDPInputs',
};

export interface RemoteAccessFormProps {
  validAdapters?: VMRemoteAccessValidAdapter[];
  setWebRDPAdapter: ActionCreatorWithPayload<VMSetRDPAdapterActionPayload>;
  setSSHAdapter: ActionCreatorWithPayload<string>;
  setAutoLogin: ActionCreatorWithPayload<boolean>;
}

export function RemoteAccessForm({
  validAdapters = [],
  setWebRDPAdapter,
  setSSHAdapter,
  setAutoLogin,
}: RemoteAccessFormProps): ReactElement {
  const { control, errors, watch } = useFormContext();
  const { t } = useTranslation();
  const formSectionClasses = classnames('col-6', styles.formSection);

  const watchDisplayDifferentCredentials = watch('displayDifferentCredentials');
  const watchIsRDPEnabled = watch(
    'remoteAccess.rdp.vmNetworkInterface.isEnabled',
  );
  const watchIsSSHEnabled = watch(
    'remoteAccess.ssh.vmNetworkInterface.isEnabled',
  );
  useHandleFormChanges({ setAutoLogin, setSSHAdapter, setWebRDPAdapter });

  return (
    <>
      <div className={styles.root}>
        <div className={formSectionClasses}>
          <ControlledTextfield
            control={control}
            error={errors?.remoteAccess?.username?.message}
            label={t('virtualMachines.edit.remoteAccess.username')}
            name="remoteAccess.username"
            required={false}
            testId="edit-vm-remote-access-username"
          />
          <ControlledTextfield
            control={control}
            error={errors?.remoteAccess?.password?.message}
            label={t('virtualMachines.edit.remoteAccess.password')}
            name="remoteAccess.password"
            required={false}
            testId="edit-vm-remote-access-password"
          />
          <div className="dbl-margin-top dbl-margin-bottom">
            <ControlledCheckbox
              control={control}
              id="displayDifferentCredentials"
              label={t(
                'virtualMachines.edit.remoteAccess.displayCredentials.enableDisplayDifferentCredentials',
              )}
              testId="edit-vm-remote-access-display-different-credentials-checkbox"
            />
          </div>

          <div
            data-testid="displayDifferentCredentialsInputs"
            className={watchDisplayDifferentCredentials ? '' : styles.hide}
          >
            <ControlledTextfield
              control={control}
              error={
                errors?.remoteAccess?.displayCredentials?.username?.message
              }
              label={t(
                'virtualMachines.edit.remoteAccess.displayCredentials.username',
              )}
              name="remoteAccess.displayCredentials.username"
              required={false}
              testId="edit-vm-remote-access-display-different-credentials-username"
            />
            <ControlledTextfield
              control={control}
              error={
                errors?.remoteAccess?.displayCredentials?.password?.message
              }
              label={t(
                'virtualMachines.edit.remoteAccess.displayCredentials.password',
              )}
              name="remoteAccess.displayCredentials.password"
              required={false}
              testId="edit-vm-remote-access-display-different-credentials-password"
            />
          </div>
        </div>
        <div className={formSectionClasses}>
          <div className={watchIsRDPEnabled ? 'dbl-margin-bottom' : ''}>
            {validAdapters.length === 0 && (
              <Alert>
                {t('virtualMachines.edit.remoteAccess.noValidAdaptersMessage')}
              </Alert>
            )}
            <div className="base-margin-bottom">
              <ControlledCheckbox
                className={styles.checkbox}
                control={control}
                id="remoteAccess.rdp.vmNetworkInterface.isEnabled"
                label={t('virtualMachines.edit.remoteAccess.webRDP')}
                testId={TEST_IDS.isRDPEnabledCheckbox}
                disabled={validAdapters.length === 0}
              />
            </div>
            <div
              data-testid={TEST_IDS.webRDPInputs}
              className={watchIsRDPEnabled ? '' : styles.hide}
            >
              <ControlledSelect
                control={control}
                label={t('virtualMachines.edit.remoteAccess.adapterToConnect')}
                horizontal={false}
                options={getAdapterOptions(validAdapters)}
                name="remoteAccess.rdp.vmNetworkInterface.uid"
                loading={false}
                testId={TEST_IDS.rdpAdapterSelect}
                required={true}
                error={
                  errors?.remoteAccess?.rdp?.vmNetworkInterface.uid?.message
                }
              />
              <ControlledCheckbox
                className={styles.checkbox}
                control={control}
                id="remoteAccess.rdp.autoLogin"
                label={t('virtualMachines.edit.remoteAccess.autoLogin')}
                testId={TEST_IDS.rdpAutoLoginCheckbox}
              />
            </div>
          </div>
          <div className={watchIsSSHEnabled ? 'dbl-margin-bottom' : ''}>
            <div className="base-margin-bottom">
              <ControlledCheckbox
                className={styles.checkbox}
                control={control}
                id="remoteAccess.ssh.vmNetworkInterface.isEnabled"
                label={t('virtualMachines.edit.remoteAccess.sshAccess')}
                testId={TEST_IDS.isSSHEnabledCheckbox}
                disabled={validAdapters.length === 0}
              />
            </div>
            <div
              data-testid={TEST_IDS.sshInputs}
              className={watchIsSSHEnabled ? '' : styles.hide}
            >
              <ControlledSelect
                control={control}
                label={t('virtualMachines.edit.remoteAccess.adapterToConnect')}
                horizontal={false}
                options={getAdapterOptions(validAdapters)}
                name="remoteAccess.ssh.vmNetworkInterface.uid"
                loading={false}
                testId={TEST_IDS.sshAdapterSelect}
                required={true}
                error={
                  errors?.remoteAccess?.ssh?.vmNetworkInterface.uid?.message
                }
              />
            </div>
          </div>
          <ControlledCheckbox
            className={styles.checkbox}
            control={control}
            id="remoteAccess.vmConsoleEnabled"
            label={t('virtualMachines.edit.remoteAccess.vmConsole')}
            testId="edit-vm-remote-access-vm-console-checkbox"
          />
        </div>
      </div>

      <div className="flex dbl-margin-top">
        <div className="col-6">
          <ControlledTextfield
            control={control}
            error={errors?.remoteAccess?.internalUrls?.[0]?.location?.message}
            label={t('virtualMachines.edit.remoteAccess.internalUrls.url1')}
            name="remoteAccess.internalUrls[0].location"
            required={false}
            testId="edit-vm-remote-access-internal-urls-url1"
          />
        </div>
        <div className="col-6">
          <ControlledTextfield
            control={control}
            error={
              errors?.remoteAccess?.internalUrls?.[0]?.description?.message
            }
            label={t(
              'virtualMachines.edit.remoteAccess.internalUrls.description1',
            )}
            name="remoteAccess.internalUrls[0].description"
            required={false}
            testId="edit-vm-remote-access-internal-urls-description1"
          />
        </div>
      </div>

      <div className="flex base-margin-top">
        <div className="col-6">
          <ControlledTextfield
            control={control}
            error={errors?.remoteAccess?.internalUrls?.[1]?.location?.message}
            label={t('virtualMachines.edit.remoteAccess.internalUrls.url2')}
            name="remoteAccess.internalUrls[1].location"
            required={false}
            testId="edit-vm-remote-access-internal-urls-url2"
          />
        </div>
        <div className="col-6">
          <ControlledTextfield
            control={control}
            error={
              errors?.remoteAccess?.internalUrls?.[1]?.description?.message
            }
            label={t(
              'virtualMachines.edit.remoteAccess.internalUrls.description2',
            )}
            name="remoteAccess.internalUrls[1].description"
            required={false}
            testId="edit-vm-remote-access-internal-urls-description2"
          />
        </div>
      </div>
    </>
  );
}
