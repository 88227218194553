import { SelectOption } from 'dcloud-shared-ui';
import React, { ReactElement, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { ControlledSelect } from '../../../../../../components';
import { convertScriptsToOptions } from './utils';

interface FormScriptsProps {
  isLoading: boolean;
  scripts?: HardwareScript[];
}

export function FormScripts({
  isLoading,
  scripts = [],
}: FormScriptsProps): ReactElement {
  const { control } = useFormContext();

  const scriptOptions = useMemo<SelectOption<string>[]>(
    () => [{ name: 'None', value: '' }, ...convertScriptsToOptions(scripts)],
    [scripts],
  );

  return (
    <div className="row">
      <div className="col-6">
        <ControlledSelect
          control={control}
          label="Startup"
          horizontal={false}
          options={scriptOptions}
          name="inventoryStartupScript.uid"
          loading={isLoading}
        />
        <ControlledSelect
          control={control}
          label="Custom"
          horizontal={false}
          options={scriptOptions}
          name="inventoryCustomScript.uid"
          loading={isLoading}
        />
        <ControlledSelect
          control={control}
          label="Shutdown"
          horizontal={false}
          options={scriptOptions}
          name="inventoryShutdownScript.uid"
          loading={isLoading}
        />
      </div>
    </div>
  );
}
