import { SagaIterator } from 'redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import { fetchNetwork } from '../../../../api/networks';
import { showErrorToastWorker } from '../../../toast/sagas';
import { setEtag } from '../../etag/slice';
import { fetchNetworkFailure, fetchNetworkSuccess } from '../slice';

export function* setNetworkToEditWorker({
  payload,
}: PayloadAction<string>): SagaIterator {
  try {
    const { data, etag }: FetchNetworkResponse = yield call(
      fetchNetwork,
      payload,
    );
    yield put(fetchNetworkSuccess(data));
    yield put(setEtag(etag));
  } catch (e) {
    yield put(fetchNetworkFailure());
    yield call(showErrorToastWorker, 'networks.fetch.errors.one');
  }
}
