import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { call, delay, put } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';
import i18n from '../../../../i18n';
import { addToast, removeToast } from '../../../toast/slice';
import { deleteNetwork as deleteNetworkApi } from '../../../../api/networks';
import { deleteNetworkFailure, fetchNetworks } from '../slice';
import {
  showErrorToastWorker,
  showSuccessToastWorker,
} from '../../../toast/sagas';

export const INFO_DELAY = 1000;

export function* deleteNetworkWorker(
  action: PayloadAction<Network>,
): SagaIterator {
  const infoToastUid = yield call(uuid);

  try {
    yield put(
      addToast({
        message: i18n.t('common.deleting'),
        title: '',
        type: 'info',
        uid: infoToastUid,
      }),
    );
    yield call(deleteNetworkApi, action.payload.uid);
    yield delay(INFO_DELAY);
    yield put(removeToast(infoToastUid));
    yield call(showSuccessToastWorker, 'networks.delete.success');
    yield put(fetchNetworks(action.payload.topology.uid));
  } catch (e) {
    yield put(removeToast(infoToastUid));
    yield put(deleteNetworkFailure(action.payload));
    yield call(showErrorToastWorker, i18n.t('networks.delete.error'));
  }
}
