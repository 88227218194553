import { dcloudApiAxios, TAGS } from '../../../api';
import { SESSIONS_PATH } from '../../../../api/paths';
import i18n from '../../../../i18n';

export const sessionApi = dcloudApiAxios.injectEndpoints({
  endpoints: (build) => ({
    getSession: build.query<Session, FetchSessionArgs>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.SESSIONS],
      query: ({ sessionUid, versionUid }) => ({
        errorMessage: i18n.t('sessions.fetch.error'),
        method: 'get',
        url: `${SESSIONS_PATH}/${sessionUid}?versionUid=${versionUid}`,
      }),
    }),
  }),
});

export const { useGetSessionQuery } = sessionApi;
