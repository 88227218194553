import React, { ReactElement } from 'react';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import {
  getVirtualMachines,
  addVMNetworkInterface,
  deleteVMNetworkInterface,
  setInterfaceToConfirmDelete,
} from '../../../../../../../redux/configuration/virtual-machines/slice';
import { RootState } from '../../../../../../../redux/store';
import { useGetNetworksQuery } from '../../../../../../../redux/configuration/networks/api';
import { useGetVmNetworkInterfaceTypesQuery } from '../../../../../../../redux/configuration/virtual-machines/api';
import { getNicTypes } from '../../../../../../../redux/entities/nic-types/slice';
import { addErrorToast } from '../../../../../../../redux/toast/slice';
import { Networking } from '.';

function NetworkingContainer({
  addErrorToast,
  setInterfaceToConfirmDelete,
  interfaceToConfirmDelete,
  networkingToEdit,
  addVMNetworkInterface,
  deleteVMNetworkInterface,
  nicTypes,
}: NetworkingContainerProps): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const {
    isLoading: isLoadingNicTypes,
    isError: isErrorLoadingNicTypes,
  } = useGetVmNetworkInterfaceTypesQuery(undefined, {
    skip: nicTypes !== undefined,
  });

  const {
    data: networks,
    isLoading: isLoadingNetworks,
    isError: isErrorLoadingNetworks,
  } = useGetNetworksQuery(topologyUid, {
    refetchOnFocus: false,
  });

  return (
    <Networking
      networkingToEdit={networkingToEdit}
      isErrorLoadingNicTypes={isErrorLoadingNicTypes}
      isErrorLoadingNetworks={isErrorLoadingNetworks}
      addErrorToast={addErrorToast}
      addVMNetworkInterface={addVMNetworkInterface}
      deleteVMNetworkInterface={deleteVMNetworkInterface}
      networks={networks}
      nicTypes={nicTypes}
      isLoadingNicTypes={isLoadingNicTypes}
      isLoadingNetworks={isLoadingNetworks}
      interfaceToConfirmDelete={interfaceToConfirmDelete}
      setInterfaceToConfirmDelete={setInterfaceToConfirmDelete}
    />
  );
}

type NetworkingContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

interface NetworkingRTKProps {
  isErrorLoadingNicTypes: boolean;
  isErrorLoadingNetworks: boolean;
  networks?: Network[];
  nicTypes?: VmNetworkInterfaceType[];
  isLoadingNicTypes: boolean;
  isLoadingNetworks: boolean;
}

export type NetworkingProps = NetworkingContainerProps & NetworkingRTKProps;

const mapStateToProps = (state: RootState) => ({
  interfaceToConfirmDelete: getVirtualMachines(state).interfaceToConfirmDelete,
  networkingToEdit: getVirtualMachines(state).networkingToEdit,
  nicTypes: getNicTypes(state).nicTypes,
});

const mapDispatchToProps = {
  addErrorToast,
  addVMNetworkInterface,
  deleteVMNetworkInterface,
  setInterfaceToConfirmDelete,
};

export const ConnectedNetworking = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NetworkingContainer);
