import { Select, SelectOption } from 'dcloud-shared-ui';
import classnames from 'classnames';
import React, { ComponentPropsWithoutRef, ReactElement } from 'react';
import styles from './NetworkSelect.module.css';

export interface NetworkSelectProps extends ComponentPropsWithoutRef<'select'> {
  options: SelectOption<string>[];
}

export function NetworkSelect({
  options = [],
  value,
  ...selectProps
}: NetworkSelectProps): ReactElement | null {
  return (
    <div
      className={classnames(styles.root, {
        [styles.unConfigured]: !value,
        [styles.configured]: value,
      })}
      data-testid="network-select"
    >
      <Select {...selectProps} value={value} label="" options={options} />
    </div>
  );
}
