import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { updateDocumentation } from '../../../../api/documentation';
import {
  showErrorToastWorker,
  showSuccessToastWorker,
} from '../../../toast/sagas';
import { getEtag } from '../../etag/selectors';
import { setEtag } from '../../etag/slice';
import { getDocumentation, updateDocumentationSuccess } from '../slice';

export function* updateDocumentationWorker(
  action: PayloadAction<DocumentationFields>,
): SagaIterator {
  try {
    const { documentation } = yield select(getDocumentation);
    const payload: DocumentationRequestData = {
      uid: documentation.uid,
      ...action.payload,
    };
    const etag: string = yield select(getEtag);

    const response: DocumentationApiResponse = yield call(
      updateDocumentation,
      payload,
      etag,
    );

    yield put(setEtag(response.etag));
    yield put(updateDocumentationSuccess(response.data));
    yield call(showSuccessToastWorker, 'documentation.success');
  } catch (error) {
    yield call(showErrorToastWorker);
  }
}
