import classnames from 'classnames';
import React, { ReactElement } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { SideTabs } from 'dcloud-shared-ui';
import styles from './Configuration.module.css';
import {
  routes,
  ConfigurationRouter,
  ConfigurationRoutes,
} from './ConfigurationRouter';

export function Configuration(): ReactElement {
  const { uid } = useParams<{ uid: string }>();
  const match = useRouteMatch();

  const buildLinks = (routes: ConfigurationRoutes): SideTabs =>
    routes.map((route) =>
      'path' in route
        ? {
            label: route.label,
            pathname: `${match.url}${route.path?.replace(':uid', uid)}`,
          }
        : {
            label: route.label,
            sideTabs: route.nestedRoutes.map((nestedRoute) => ({
              label: nestedRoute.label,
              pathname: `${match.url}${nestedRoute.path?.replace(':uid', uid)}`,
            })),
          },
    );

  return (
    <div className={classnames('content', styles.root)}>
      <aside className={classnames(styles.sidebar)}>
        <SideTabs tabs={buildLinks(routes)} />
      </aside>
      <main className={classnames('dbl-padding', styles.content)}>
        <ConfigurationRouter />
      </main>
    </div>
  );
}
