import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '../../../../../../../components';
import { useDeleteNatRuleMutation } from '../../../../../../../redux/configuration/traffic/nat-rules/api';

interface NatRuleDeleteModalProps {
  setNatRuleToDelete: (natRule?: NatRule) => void;
  natRule: NatRule;
}
export function NatRuleDeleteModal({
  setNatRuleToDelete,
  natRule,
}: NatRuleDeleteModalProps): ReactElement {
  const { t } = useTranslation();
  const [
    deleteNatRule,
    { isLoading, isSuccess, isError },
  ] = useDeleteNatRuleMutation();

  useEffect(() => {
    if (isSuccess || isError) {
      setNatRuleToDelete(undefined);
    }
  }, [isError, isSuccess, setNatRuleToDelete]);

  return (
    <ConfirmModal
      heading={t('traffic.natRules.delete.heading')}
      subHeading={t('traffic.natRules.delete.subHeading')}
      loading={isLoading}
      onCancel={() => setNatRuleToDelete(undefined)}
      onClose={() => setNatRuleToDelete(undefined)}
      onConfirm={() => deleteNatRule(natRule)}
      confirmButtonLabel={t('buttons.delete')}
      show={true}
      size="small"
      testId="nat-rule-delete-modal"
    />
  );
}
