import { AxiosPromise } from 'axios';
import { api } from '../../api';
import { get, stripGzipFromEtag } from '../utils';
import { TOPOLOGIES_PATH } from '../paths';

export enum Endpoints {
  Clone = '/clones',
  Topologies = '/topologies',
}

export async function fetchTopologies(): Promise<Topology[]> {
  const { data } = await get<{ topologies: Topology[] }>(Endpoints.Topologies);
  return data.topologies;
}

export async function fetchTopology(uid: string): Promise<FetchTopology> {
  const { data, headers } = await api.get<Topology>(
    `${Endpoints.Topologies}/${uid}`,
  );

  return { data, etag: stripGzipFromEtag(headers.etag) };
}

export async function updateTopology(
  topology: Omit<Topology, '_links'>,
  etag: string,
): Promise<FetchTopology> {
  const { data, headers } = await api.put<Topology>(
    `${Endpoints.Topologies}/${topology.uid}`,
    topology,
    {
      headers: {
        'If-Match': etag,
      },
    },
  );

  return { data, etag: stripGzipFromEtag(headers.etag) };
}

export async function createTopology(
  topology: TopologyToCreate,
): Promise<Topology> {
  const { data } = await api.post(Endpoints.Topologies, topology);
  return data;
}

export const deleteTopology = (uid: string): AxiosPromise<void> =>
  api.delete(`${Endpoints.Topologies}/${uid}`);

export async function cloneTopology(
  uid: string,
  topology: CloneTopologyPayload,
): Promise<CloneTopology> {
  const { data, headers } = await api.post<CloneTopologyResponse>(
    `${TOPOLOGIES_PATH}/${uid}${Endpoints.Clone}`,
    topology,
  );

  return { data, etag: stripGzipFromEtag(headers.etag) };
}
