import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../../../components/Buttons/PrimaryButton';
import { PageHeading } from '../../../../../components/PageHeading';
import { AddHardwareRTKProps } from './container';
import { Table } from './Table';

export function Add({
  addHardware,
  hardware,
  isLoading,
  handleOnSelect,
  selectedHardwareItems,
}: AddHardwareRTKProps): ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <PageHeading
        withBackBtn={true}
        pageTitle={t('hardware.add.title')}
        ctaBtn={
          <PrimaryButton
            colour="success"
            disabled={isLoading || !selectedHardwareItems.length}
            onClick={addHardware}
            loading={isLoading}
            testId="hardware-inventory-table-actions-add"
          >
            {t('buttons.add')}
          </PrimaryButton>
        }
      />
      <Table
        inventoryHardwareItems={hardware || []}
        isLoading={isLoading}
        handleOnSelect={handleOnSelect}
        selectedHardwareItems={selectedHardwareItems}
      />
    </>
  );
}
