export const TOPOLOGIES_LIMIT = 8;

export enum TopologyViewTypes {
  List = 'list',
  Table = 'table',
}

export enum TopologyStatusColours {
  CUSTOM_CONTENT = 'success',
  DRAFT = 'dark',
  SAVED_CONTENT = 'success',
}

export const TOPOLOGY_STATUS_LABELS = {
  CUSTOM_CONTENT: 'Custom Content',
  DRAFT: 'Draft',
  SAVED_CONTENT: 'Saved Content',
};

export const TOPOLOGIES_LAST_UPDATED_FORMAT = 'MM/dd/yyyy HH:mm:ss';
