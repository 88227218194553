import React, { ReactElement } from 'react';
import { TwoHandleSideNode } from '../TwoHandleSideNode';
import styles from './NetworkNode.module.css';

interface NetworkNodeProps extends BaseNodeProps {
  asset?: Network;
}

export function NetworkNode({
  asset,
  isReadOnly,
  ...node
}: NetworkNodeProps): ReactElement {
  return (
    <TwoHandleSideNode
      display={node.display}
      uid={node.uid}
      className={styles.root}
      isReadOnly={isReadOnly}
    >
      <span className={styles.line}></span>
      <p className={styles.name}>{asset?.name}</p>
    </TwoHandleSideNode>
  );
}
