import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useFetchTopologyQuery } from '../../../redux/configuration/general/api';
import { EditHeader } from '.';

export function ConnectedEditHeader(): ReactElement {
  const { uid: topologyUid } = useParams<TopologyUid>();
  useFetchTopologyQuery(topologyUid, { refetchOnFocus: false });

  return <EditHeader />;
}
