import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDocumentation } from '../../../../redux/configuration/documentation/hooks/use-documentation';
import { PageHeading } from '../../components';
import { DocumentationForm } from './components/DocumentationForm';
import { DocumentationFormSkeleton } from './components/DocumentationFormSkeleton';
import styles from './Documentation.module.css';

export function Documentation(): ReactElement {
  const { uid } = useParams<{ uid: string }>();
  const { documentation } = useDocumentation(uid);
  const { t } = useTranslation();

  return (
    <div className="half-padding-left">
      <div className="dbl-margin-bottom">
        <PageHeading text={t('documentation.name')} />
      </div>
      <div className={styles.container}>
        {documentation ? (
          <DocumentationForm documentation={documentation} />
        ) : (
          <DocumentationFormSkeleton />
        )}
      </div>
    </div>
  );
}
