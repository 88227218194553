import { format } from 'date-fns';
import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import { Table } from '../../../../components/Table';
import { setSelectedDemoId } from '../../../../redux/configuration/virtual-machines/slice';
import { PUBLISHED_DATE_FORMAT } from './constants';
import styles from './DemosTable.module.css';

export interface DemosTableProps {
  loading: boolean;
  demos: InventoryDemo[];
}

const DEMOS_PAGE_SIZE_LIMIT = 10;

export function DemosTable({ loading, demos }: DemosTableProps): ReactElement {
  const { t } = useTranslation();
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const { url } = useRouteMatch();
  const dispatch = useDispatch();

  const columns = useMemo<Column<InventoryDemo>[]>(
    () => [
      {
        // eslint-disable-next-line react/display-name
        Cell: ({ row, value }: CellProps<InventoryDemo>) => (
          <Link
            to={`${url.replace(':uid', topologyUid)}/vms`}
            onClick={() => dispatch(setSelectedDemoId(row.original.id))}
            className={styles.nameLink}
          >
            {value}
          </Link>
        ),
        Header: () => t('demos.table.headings.name'),
        accessor: 'name',
      },
      {
        Header: () => t('demos.table.headings.id'),
        accessor: 'id',
        maxWidth: 25,
      },
      {
        Cell: ({ value }) =>
          value ? format(new Date(value), PUBLISHED_DATE_FORMAT) : null,
        Header: () => t('demos.table.headings.publishedOn'),
        accessor: 'publishedOn',
        id: 'publishedOn',
        maxWidth: 50,
      },
    ],
    [dispatch, t, topologyUid, url],
  );

  return (
    <Table<InventoryDemo>
      ariaLabel="Inventory Demos Table"
      columns={columns}
      loading={loading}
      data={demos}
      initialState={{
        pageSize: DEMOS_PAGE_SIZE_LIMIT,
        sortBy: [{ desc: false, id: 'name' }],
      }}
    />
  );
}
