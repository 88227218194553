import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

interface UseURLParamsProps {
  queryParams?: string[];
}

interface UseURLParams {
  path: StringRecord;
  query: StringRecord;
}

export function useURLParams({ queryParams }: UseURLParamsProps): UseURLParams {
  const pathParamObj = useParams<StringRecord>();
  const { search } = useLocation();
  const query = React.useMemo(() => new URLSearchParams(search), [search]);
  const queryParamObj: StringRecord = Object.assign(
    {},
    ...queryParams!.map((param) => ({ [param]: query.get(param) })),
  );

  return { path: pathParamObj, query: queryParamObj };
}
