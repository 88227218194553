export const DATACENTERS_PATH = '/datacenters';
export const DEMOS_PATH = '/demos';
export const DIAGRAM_PATH = '/diagram';
export const DOCUMENTATION_PATH = '/documentation';
export const DNS_ASSETS = '/inventory-dns-assets';
export const EXTERNAL_DNS = '/external-dns-records';
export const GLOBAL_PATH = '/global';
export const HARDWARE_PATH = '/hardware-items';
export const HARDWARE_START_ORDER = '/hardware-start-order';
export const INBOUND_PROXY_RULES = '/inbound-proxy-rules';
export const INVENTORIES_PATH = '/inventories';
export const INVENTORY_DEMO_VMS_PATH = '/vms';
export const INVENTORY_HARDWARE_PATH = '/inventory-hardware-items';
export const INVENTORY_HARDWARE_SCRIPTS = '/inventory-hardware-scripts';
export const INVENTORY_HARDWARE_TEMPLATES = '/inventory-hardware-templates';
export const INVENTORY_LICENCES_PATH = '/inventory-licenses';
export const INVENTORY_NETWORKS_PATH = '/inventory-networks';
export const INVENTORY_VIRTUAL_MACHINES_DEMOS_PATH = '/inventory-demos';
export const INVENTORY_DEMOS_PATH = '/inventory-demos';
export const INVENTORY_VIRTUAL_MACHINES_TEMPLATES_PATH =
  '/inventory-template-vms';
export const LICENCES_PATH = '/licenses';
export const MAIL_SERVERS = '/mail-servers';
export const NAT_RULES = '/nat-rules';
export const NAT_RULES_IP = '/ip-nat-rules';
export const NAT_RULES_VM = '/vm-nic-nat-rules';
export const NETWORKS_PATH = '/networks';
export const NODES_PATH = '/nodes';
export const OPERATING_SYSTEMS_PATH = '/os-families';
export const REMOTE_ACCESS_PATH = '/remote-access';
export const REMOTE_ACCESSES_PATH = '/remote-access';
export const SESSIONS_PATH = '/sessions';
export const TOPOLOGIES_PATH = '/topologies';
export const VM_NETWORK_INTERFACE_TYPE = '/vm-network-interface-types';
export const VM_START_ORDER = '/vm-start-order';
export const VM_STOP_ORDER = '/vm-stop-order';
export const VM_TARGETS = '/vm-nic-targets';
export const VMS_PATH = '/vms';
