import React, { ReactElement } from 'react';
import classnames from 'classnames';
import { v4 } from 'uuid';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import {
  ControlledSelect,
  ControlledTextfield,
} from '../../../../../../../../../components';
import { PrimaryButton } from '../../../../../../../../../components/Buttons/PrimaryButton';
import { vmNetworkInterfaceFieldsSchema } from '../../../../../../../../../validation';
import { getNetworkOptions } from '../../../../../Configure/Utils';
import { findNetworkName, getNicTypeOptions } from '../../utils';
import { TemplateToastToAdd } from '../../../../../../../../../redux/toast/slice';
import styles from './NetworkingForm.module.css';

export interface NetworkingFormData {
  ipAddress?: string;
  macAddress?: string;
  networkName: string;
  type: string;
}

interface NetworkingFormProps {
  addVMNetworkInterface: ActionCreatorWithPayload<
    VmNetworkInterfaceToEdit,
    string
  >;
  addErrorToast: ActionCreatorWithPayload<TemplateToastToAdd, string>;
  networks: Network[];
  nicTypes: VmNetworkInterfaceType[];
  totalNics: number;
}

export function NetworkingForm({
  addVMNetworkInterface,
  addErrorToast,
  networks,
  nicTypes,
  totalNics,
}: NetworkingFormProps): ReactElement {
  const { t } = useTranslation();
  const formSectionClasses = classnames('col-2', styles.formSection);

  const { control, errors, handleSubmit, reset, formState } = useForm<
    NetworkingFormData
  >({
    defaultValues: {
      ipAddress: '',
      macAddress: '',
      networkName: '',
      type: '',
    },
    mode: 'all',
    resolver: yupResolver(vmNetworkInterfaceFieldsSchema),
  });

  const onAddClicked: SubmitHandler<NetworkingFormData> = async (formData) => {
    if (totalNics >= 10) {
      addErrorToast({
        message: t('virtualMachines.edit.networking.maxLimit'),
      });
      return;
    }
    const networkName = findNetworkName(networks, formData);
    if (networkName) {
      const formDataWithTempUid = {
        ...formData,
        inUse: false,
        network: {
          name: networkName,
          uid: formData.networkName,
        },
        uid: `temp-${v4()}`,
      };
      addVMNetworkInterface(formDataWithTempUid);
      reset();
    }
  };

  const { isDirty, isValid } = formState;

  return (
    <div aria-label="VM Edit Networking form" className={styles.root}>
      <div className={formSectionClasses}>
        <ControlledSelect
          control={control}
          label={t('virtualMachines.edit.networking.network')}
          horizontal={false}
          options={getNetworkOptions(networks)}
          name="networkName"
          loading={false}
          testId="edit-vm-networking-nic-network-select"
          required={true}
          error={errors?.networkName?.message}
        />
      </div>
      <div className={formSectionClasses}>
        <ControlledSelect
          control={control}
          label={t('virtualMachines.edit.networking.type')}
          horizontal={false}
          options={getNicTypeOptions(nicTypes)}
          name="type"
          loading={false}
          testId="edit-vm-networking-nic-type-select"
          required={true}
          error={errors?.type?.message}
        />
      </div>
      <div className={formSectionClasses}>
        <ControlledTextfield
          control={control}
          error={errors?.macAddress?.message}
          label={t('virtualMachines.edit.networking.macAddress')}
          name="macAddress"
          required={false}
          testId="edit-vm-networking-mac-address-input"
        />
      </div>
      <div className={formSectionClasses}>
        <ControlledTextfield
          control={control}
          error={errors?.ipAddress?.message}
          label={t('virtualMachines.edit.networking.ipAddress')}
          name="ipAddress"
          required={false}
          testId="edit-vm-networking-ip-address-input"
        />
      </div>

      <div className={formSectionClasses}>
        <PrimaryButton
          className={styles.button}
          type="button"
          disabled={!isDirty || !isValid}
          onClick={handleSubmit(onAddClicked)}
          testId="edit-vm-networking-add-button"
        >
          {t('buttons.add')}
        </PrimaryButton>
      </div>
    </div>
  );
}
