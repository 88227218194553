import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { networksSelectors } from '../../../redux/configuration/networks/slice';
import { networkFieldsSchema } from '../../../validation';
import { EditNetworkFormProps } from '../components/EditNetworkForm';
import {
  getEditNetworkAlertMessage,
  getEditNetworkCheckboxState,
  getNetworkSubnetOptions,
  handleRoutedChange,
} from '../utils';

type UseEditNetworkFormProps = Omit<
  UseNetworkFormProps<EditNetworkFormProps>,
  'addNetwork'
>;

export function useEditNetworkForm({
  availableRouted,
  availableUnrouted,
  networkNames,
  networkToEdit,
  updateNetwork,
}: UseEditNetworkFormProps): UseNetworkForm {
  const isLastRouted = useSelector(networksSelectors.getIsLastRouted);
  const [isRouted, setIsRouted] = useState<boolean>(
    networkToEdit.inventoryNetwork.type === 'ROUTED',
  );

  const isNetworkCheckboxDisabled = getEditNetworkCheckboxState({
    availableRouted,
    availableUnrouted,
    inventoryToEdit: networkToEdit.inventoryNetwork,
    isLastRouted,
  });

  const alertMessage = getEditNetworkAlertMessage({
    availableRouted,
    availableUnrouted,
    inventoryToEdit: networkToEdit.inventoryNetwork,
    isLastRouted,
    isNetworkCheckboxDisabled,
  });

  const subnetOptions = getNetworkSubnetOptions({
    availableRouted,
    availableUnrouted,
    inventoryToEdit: networkToEdit.inventoryNetwork,
    isRouted,
  });

  const nameValidationList = networkNames.filter(
    (name) => name !== networkToEdit.name,
  );

  const {
    control,
    errors,
    formState,
    getValues,
    handleSubmit,
    setValue,
    trigger,
  } = useForm<NetworkFormFields>({
    defaultValues: {
      description: networkToEdit.description,
      name: networkToEdit.name,
      subnet: networkToEdit.inventoryNetwork.id,
    },
    mode: 'all',
    resolver: yupResolver(networkFieldsSchema(nameValidationList)),
  });

  const { isDirty, isValid } = formState;

  const onSubmit: UseNetworkForm['onSubmit'] = (formData) => {
    updateNetwork({ formData, network: networkToEdit });
  };

  const onRoutedChange: UseNetworkForm['onRoutedChange'] = (e) => {
    handleRoutedChange({
      getValues,
      newRoutedValue: e.target.checked,
      setIsRouted,
      setValue,
      trigger,
    });
  };

  return {
    alertMessage,
    control,
    errors,
    handleSubmit,
    isDirty,
    isNetworkCheckboxDisabled,
    isRouted,
    isValid,
    onRoutedChange,
    onSubmit,
    subnetOptions,
  };
}
