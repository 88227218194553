import React from 'react';
import { connect } from 'react-redux';
import { getEtag } from '../../../../redux/configuration/etag/selectors';
import { RootState } from '../../../../redux/store';
import { useUpdateTopologyMutation } from '../../../../redux/configuration/general/api';
import { topologiesSelectors } from '../../../../redux/topologies/slice';
import { General } from '.';

export type PropsFromRedux = ReturnType<typeof mapStateToProps>;

function GeneralContainer({ etag, topologyToEdit }: PropsFromRedux) {
  const [updateTopology, { isLoading }] = useUpdateTopologyMutation();

  return (
    <General
      isUpdating={isLoading}
      updateTopology={updateTopology}
      etag={etag}
      topologyToEdit={topologyToEdit}
    />
  );
}

const mapStateToProps = (state: RootState) => ({
  etag: getEtag(state),
  topologyToEdit: topologiesSelectors.getTopologyToEdit(state),
});

export const ConnectedGeneral = connect(mapStateToProps)(GeneralContainer);
