import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useFetchInventoryTemplatesVMs } from '../../hooks/use-fetch-inventory-templates-vms';
import { TemplateTabContent } from './TemplateTabContent';

export function TemplateTabContentContainer(): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();

  const {
    inventoryVirtualMachinesTemplates,
    isFetching,
  } = useFetchInventoryTemplatesVMs(topologyUid);

  return (
    <TemplateTabContent
      isFetching={isFetching}
      inventoryTemplateVirtualMachines={inventoryVirtualMachinesTemplates}
    />
  );
}
