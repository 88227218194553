import React from 'react';
import { connect } from 'react-redux';
import {
  usePostDemoMutation,
  usePushToCustomContentMutation,
} from '../../../../redux/configuration/demos/api';
import { RootState } from '../../../../redux/store';
import {
  clearTopologyToSave,
  topologiesSelectors,
} from '../../../../redux/topologies/slice';
import { SaveCustomContentModal } from '.';

export type PropsFromRedux = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const mapStateToProps = (state: RootState) => ({
  topologyToSave: topologiesSelectors.getTopologyToSave(state),
  topologyToUpdate: topologiesSelectors.getTopologyToUpdate(state),
});

const mapDispatchToProps = {
  clearTopologyToSave,
};

export const ConnectedSaveCustomContentModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(function (props) {
  const [postTopology, { isLoading }] = usePostDemoMutation();
  const [
    pushToCustomContent,
    { isLoading: isUpdating },
  ] = usePushToCustomContentMutation();

  return (
    <SaveCustomContentModal
      {...props}
      saveTopology={postTopology}
      updateDemo={pushToCustomContent}
      isSaving={isLoading || isUpdating}
    />
  );
});
